import React from 'react' ;
import { Form, Row, Col, ListGroup, Card, Button, Modal, OverlayTrigger, Tooltip, Dropdown, Spinner } from 'react-bootstrap';
import { useNavigate, useParams , useLocation} from "react-router-dom";
import Swal from 'sweetalert2';

function BookConsultModel({userId , setOpenConsultModel}) {
    // const location = useLocation();
    // const authorId = location.state;
    const navigate = useNavigate();


    const handleOneTimeConsult = () =>{
        navigate('/checkout' , {state:userId})
    }

    const commingSoon = () =>{
        setOpenConsultModel();
        Swal.fire('Coming Soon !' );

    }

  return (
    <div>
        <Card className='profile-img-follow'>
            <Card.Body className="p-0">
                <div className="comment-popup d-flex align-items-center">
                    <Button className="mr-3" variant="primary" onClick= {handleOneTimeConsult}> Make one time payment </Button>
                    <Button variant="outline-primary" onClick ={commingSoon}> Book a consultation </Button>
                </div>  
            </Card.Body>
        </Card>                          
    </div>
  )
}

export default BookConsultModel ;