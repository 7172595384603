import React, { useEffect, useState, useRef } from "react";
import { Row, Col, ListGroup, Container, Card, Form } from 'react-bootstrap';
import { ListTagsCountFeed, ListTagsFeed } from '../../redux/actions/post.action';
import { useNavigate, Link,navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from 'react-loader-spinner';
import SidebarLoggedOut from './SidebarLoggedOut';
import SidebarLogin from './LoginSidebar';
import SidebarLogged from './SidebarLogged'
import SidebarFollow from './FollowSidebar'
import Footer from "./Footer";
import { AiFillCloseCircle } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi'
import SearchPostFeed from "../PostFeed/SearchPostFeed"
// import SidebarLoggedOut from './SidebarLoggedOut'

const ExploreHashtag = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [hashTagsdata, sethashTagsdata] = useState([]);
    const [loaderListing, setloaderListing] = useState(false);
    const [slugstring, setslugstring] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [textareaValue, setTextareaValue] = useState("");
    // const [errortextareaValue, seterrortextareaValue] = useState("");
    const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
    const [showHashtagSuggestions, setShowHashtagSuggestions] = useState(false);
    const tagsdata = useSelector(state => state.tags.tags);
    const auth = useSelector(state => state.auth);
    const searchbarRef = useRef(null);
    var timeout;
    const handleTagsData = async () => {
        await dispatch(ListTagsCountFeed());
    }


    useEffect(() => {
        (async () => {
            await handleTagsData()
        })();
    }, [])

    useEffect(() => {
        sethashTagsdata(tagsdata)
        if (hashTagsdata) {
            setTimeout(() => {
                setloaderListing(true)
            }, 1000)
        }
    }, [tagsdata])



    useEffect(() => {
        function generateRandomString() {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

            for (let i = 0; i < 15; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
        }
        const randomString = generateRandomString();
        setslugstring(randomString)
    }, [])

    const handleLoaderForSearch = () => {
        setloaderListing(true)
    }

    useEffect(() => {
        // Add event listener to detect clicks outside of search bar
        const handleClickOutside = (event) => {
            if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
                setShowHashtagSuggestions(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchbarRef])

    const handleHashtagSelection = (suggestion) => {
        const lastWordMatch = textareaValue.match(/(\S+)$/);
        if (lastWordMatch) {
            const lastWordIndex = textareaValue.lastIndexOf(lastWordMatch[0]);
            setTextareaValue(textareaValue.slice(0, lastWordIndex) + suggestion);
        } else {
            setTextareaValue((prevValue) => prevValue + suggestion);
        }
        setHashtagSuggestions([]);
        setShowHashtagSuggestions(false);
    };


    const handleTextareaChange = (event) => {
        const { value } = event.target;
        // console.log('value', value)
        // console.log('valtagsdataue', tagsdata)
        setTextareaValue(value);
        const lastMatch = value.match(/(^|\s)(#?[a-zA-Z0-9_]*)$/)?.[2];
        if (lastMatch || !value.includes('#')) {
            const filteredSuggestions = tagsdata?.filter((hashtag) =>
                hashtag._id?.toLowerCase().includes(value.toLowerCase())
            );
            // console.log('filteredSuggestions', filteredSuggestions)
            setHashtagSuggestions(filteredSuggestions);
            setShowHashtagSuggestions(true);
        } else {
            setHashtagSuggestions([]);
            setShowHashtagSuggestions(false);
        }
    };

    const handleclearSearchBar = async () => {
        setTextareaValue('')
        setShowHashtagSuggestions(false);
    }

    const handleSearch = async (e) => {
        e.preventDefault()
        // console.log('textareaValue',textareaValue)
        // navigate(`/post/feed/${textareaValue.replace(/[#@]/g, "")}/${slugstring}`) 
        navigate(`/post/feed/${textareaValue.replace(/[#@]/g, "")}`) 

    }



    return (
        <div className='logged--screen'>
            <Container>
                <Row className='justify-content-center'>
                    <Col sm={12} md={3} xl={3} className="menu--sidebar">
                        {auth.loggedIn ? <SidebarLogged /> : <SidebarLoggedOut />}
                    </Col>
                    <Col sm={12} md={6} xl={6} className="section--content">
                        <div className='feed--listing feed--cards pt-md-0 border-top-0 hash-post-listing'>
                            <div class="singlePost--search d-flex align-items-center">
                                <Link to='/home' class="arrow--back me-3">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path></svg>
                                </Link>
                                <div className='feed--listing'>
                                    <Form
                                        onSubmit={(e) => handleSearch(e)}
                                        className='post--search'
                                        ref={searchbarRef}
                                    >
                                        <Form.Group className='form-group'>
                                            <span className='search--icon'>
                                                <BiSearch />
                                            </span>
                                            <Form.Control
                                                value={textareaValue}
                                                onChange={handleTextareaChange}
                                                type="text"
                                                placeholder="Search"
                                            // onKeyDown={e => {
                                            //     if (e.keyCode === 13) { handleSearch(); }
                                            // }}
                                            // onKeyUp={(e) => handleSearch(e)}
                                            />
                                            {textareaValue &&
                                                <span onClick={handleclearSearchBar} className='close--icon'>
                                                    <AiFillCloseCircle />
                                                </span>
                                            }
                                        </Form.Group>
                                        <div className="hashtag-searchbar">
                                            {showHashtagSuggestions && (
                                                <div className="hashtag-suggestions">
                                                    <ul>
                                                        {hashtagSuggestions?.map((suggestion, index) => (
                                                            <li key={index}

                                                                onClick={() => handleHashtagSelection(suggestion._id)}>
                                                                <BiSearch />
                                                                <Link
                                                                    to={`/post/feed/${suggestion?._id.replace(/[#@]/g, "")}`}
                                                                    // to={`/post/feed/${suggestion?._id.replace(/[#@]/g, "")}/${slugstring}`}
                                                                    key={suggestion?._id}
                                                                >
                                                                    <span>
                                                                        {suggestion._id}
                                                                        <small>{suggestion.count} Questions</small>
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            {
                                loaderListing ?
                                    hashTagsdata?.length > 0 && (
                                        <div>
                                            {hashTagsdata.map((tags) => (
                                                <Link
                                                    to={`/post/feed/${tags?._id?.replace(/[#@]/g, "")}`}
                                                    // to={`/post/feed/${tags?._id?.replace(/[#@]/g, "")}/${slugstring}`}
                                                    key={tags?._id}
                                                >
                                                    <Card>
                                                        <Card.Body>
                                                            <Card.Title className="mb-0">
                                                                <Card.Link>{tags?._id}</Card.Link>
                                                            </Card.Title>
                                                            <ListGroup>
                                                                <ListGroup.Item>
                                                                    <strong>{tags?.count}</strong> Questions
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            ))}
                                        </div>
                                    )
                                    :
                                    <div className="loader-main-list">
                                        <ThreeDots
                                            height="50"
                                            width="50"
                                            radius="9"
                                            color="#e44300"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                            }
                        </div>
                        {auth.loggedIn ? null :
                            <Footer />
                        }
                    </Col>
                    <Col sm={12} md={3} xl={3} className="rightFeed--sidebar">
                        {auth.loggedIn ? <SidebarFollow /> : <SidebarLogin />}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ExploreHashtag