

import { 
  USER_SUGGESTION_LIST_SUCCESS,
  USER_SUGGESTION_LIST_FAILED, 
  GET_USER_PROFILE_SUCCESS, 
  GET_USER_PROFILE_FAILED,
  PUT_USER_PROFILE_SUCCESS,
  PUT_USER_PROFILE_FAILED,
  GET_SPECIALITIES_SUCCESS,
  GET_SPECIALITIES_FAILED,
} from '../actions/types';

const initialState = {
  users: null,
};

export default function commentsReducer(state = initialState, action) {
  switch (action.type) {
      case USER_SUGGESTION_LIST_SUCCESS:
        return {
            users: action.payload.user,
        }
      case USER_SUGGESTION_LIST_FAILED:
        return {
            error: action.payload
        }
      case GET_USER_PROFILE_SUCCESS: case GET_USER_PROFILE_FAILED:
        return {
          ...state,
          profile: action.payload
        }
      case PUT_USER_PROFILE_SUCCESS: case PUT_USER_PROFILE_FAILED:
        return {
          ...state,
          profile: action.payload
        }
      case GET_SPECIALITIES_SUCCESS: case GET_SPECIALITIES_FAILED:
        return {
          ...state,
          specialities: action.payload
        } 
    default: return state;
  }
}
