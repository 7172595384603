import { verifyOtp } from "../../redux/actions/auth.actions";

/**
 *  Defined Each API URL as keys to use in project
 */
const apiKeys = {
    auth:'/auth',
    login: "/auth/login",
    user:'/user',
    signup:'/auth/signup',
    emailverifyOpt:'/auth/email/verify',
    otpRequest:'/auth/otp/request',
    post:'/post',
    postfeeds:'/post/post/feeds',
    postfeedsearch:'/post/post/feeds?search=',
    postfeedloggedout:'/post/post/feedsloggedout',
    postSearchloggedOut:'/post/post/feedsloggedout?search=',
    tags:'/post/post/tags',
    tagscount:'/post/post/tagscount',
    users:'/user/',
    topideas:'/post/post/topideas',
    resetPassword:'/auth/password/reset',
    otpverify:'/auth/otp/verify',
    googlelogin:'/auth/google/login',
    stripe: '/stripe',
    stripeDetail: '/stripe/account/connect-detail'

};
  
export default apiKeys;
  