import {useEffect , useState} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Alert, Container, Card, Button, Modal, Form, Accordion, Spinner, InputGroup } from 'react-bootstrap';
import SidebarLogged from '../../PostFeed/SidebarLogged';
import SidebarFollow from '../../PostFeed/FollowSidebar';
import { checkOnBoarding } from '../../../redux/actions/stripe.actions';


function StripeOnboarding(props) {
    const [onboard, setOnBoard] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const onBoardingResult = useSelector(state => state.stripe);
    const navigate = useNavigate();
    useEffect(()=>{
        if(sessionStorage.getItem('stripe')){
            let account= sessionStorage.getItem('stripe');
            account= JSON.parse(account)
            checkOnboardAccount(account);
        }    
    },[])
    
    useEffect(() => {
        if(onBoardingResult && onBoardingResult.onBoardError){
            setError(onBoardingResult.onBoardError)
        }

        if (onBoardingResult && onBoardingResult.completeOnBoarding) {
            setOnBoard(onBoardingResult.completeOnBoarding)
        }
    }, [onBoardingResult])

    const checkOnboardAccount = async(accountId) =>{
        setIsLoading(true)
        dispatch(checkOnBoarding(accountId));
        setIsLoading(false)
    }

    const handleGoBack = () => {
        navigate('/profile/me') // Go back to the previous page
    }

    return (
        <>
            <div className='Single-posts'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col sm={12} md={3} xl={3} className='menu--sidebar'>
                            <SidebarLogged />
                        </Col>
                        { isLoading
                        ?   <Col sm={12} lg={6} className='section--content'>
                                <div className='text-center spinner--loader'><Spinner /></div>
                            </Col>
                        :  
                        <Col sm={12} md={6} xl={6} className="section--content">
                            <div className="feed--listing">
                               {error 
                                ?    <>
                                        <Button onClick={handleGoBack}>Go Back</Button>
                                        <Alert className='mt-4' variant="danger">
                                            <h3> Your account is not yet connected to Stripe </h3>
                                            <span className="highlighted-link">
                                                <Link to={error.redirectUrl} > Continue to connect your stripe account </Link>
                                            </span>
                                        </Alert>
                                    </>
                                :    <>
                                        <Button onClick={handleGoBack}>Go Back</Button>
                                        {onboard !== null ?
                                            <Alert className='mt-4' variant="success">
                                                <h3>Your Stripe account has connected successfully!</h3>
                                            </Alert>
                                        : null
                                        }
                                    </>
                            
                            }
                               
                            </div>
                        </Col>
                        }
                        <Col sm={12} md={3} xl={3} className="rightFeed--sidebar">
                            <SidebarFollow />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default StripeOnboarding ; 