import {
    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_FAILED,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    REQUEST_OTP_SUCCESS,
    REQUEST_OTP_FAILED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    VERIFY_OTP_RESET_PASSWORD_SUCCESS,
    VERIFY_OTP_RESET_PASSWORD_FAILED,
    REQUEST_OTP_RESET_PASSWORD_SUCCESS,
    REQUEST_OTP_RESET_PASSWORD_FAILED,
    LOGOUT,
    AUTH_COMMON_ERROR,
    SOCIAL_LOGIN_SUCCESS,
    SOCIAL_LOGIN_FAILED,
    GOOGLE_LOGIN_SUCCESS,
    GOOGLE_LOGIN_FAILED,
} from "../../redux/actions/types";
import * as auth from '../../helpers/auth';
const token = auth.getToken();

const initialState = {
    token: null,
    requestMessage: null,
    requestMessageResetPassWord:null,
    updateMessage:null,
    requestMessageResetPass:null,
    loggedIn: token ? true : false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_COMMON_ERROR:
            return {
                error: action.payload,
                loggedIn: token ? true : false
            }
        case LOGIN_SUCCESS:
            return {
                token: action.payload.accessToken,
                loggedIn: token ? true : false
            };
        case LOGIN_FAILED:
            return {
                error: action.payload,
                loggedIn: token ? true : false
            }
            case GOOGLE_LOGIN_SUCCESS:
                return {
                    token: action.payload.accessToken,
                    loggedIn: token ? true : false
                };
            case GOOGLE_LOGIN_FAILED:
                return {
                    error: action.payload,
                    loggedIn: token ? true : false
                }
        case SOCIAL_LOGIN_SUCCESS:
            return {
                token: action.payload.accessToken,
                loggedIn: token ? true : false
            }
        case SOCIAL_LOGIN_FAILED:
            return {
                socialLoginError: action.payload,
                loggedIn: token ? true : false
            }
        case CREATE_ACCOUNT_SUCCESS:
            return {
                created_Account: action.payload.message,
                loggedIn: false,
            }
        case CREATE_ACCOUNT_FAILED:
            return {
                error: action.payload,
                loggedIn: false,
            }
        case VERIFY_OTP_SUCCESS:
            return {
                verifyMessage: action.payload.message,
                loggedIn: false,
            }
        case VERIFY_OTP_FAILED:
            return {
                error: action.payload,
                loggedIn: false,
            }
        case REQUEST_OTP_SUCCESS:
            return {
                requestMessage: action.payload.message,
                loggedIn: false
            }
        case REQUEST_OTP_FAILED:
            return {
                error: action.payload,
                loggedIn: false,
            }
            case VERIFY_OTP_RESET_PASSWORD_SUCCESS:
                return {
                    requestMessageResetPass: action.payload.message,
                    loggedIn: false
                }
            case VERIFY_OTP_RESET_PASSWORD_FAILED:
                return {
                    error: action.payload,
                    loggedIn: false,
                }
        case RESET_PASSWORD_SUCCESS:
            return {
                updateMessage: action.payload.message,
                loggedIn: false,
            }
        case RESET_PASSWORD_FAILED:
            return {
                error: action.payload,
                loggedIn: false,
            }
            case REQUEST_OTP_RESET_PASSWORD_SUCCESS:
                return {
                    requestMessageResetPassWord: action.payload.message,
                    loggedIn: false
                }
            case REQUEST_OTP_RESET_PASSWORD_FAILED:
                return {
                    error: action.payload,
                    loggedIn: false,
                }
        case LOGOUT: return null;
        default: return state;
    }
};
