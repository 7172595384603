import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Form, Alert } from "react-bootstrap";
import ReactCodeInput from 'react-code-input';
import { verifyOtpResetPassword } from '../../../redux/actions/auth.actions'
import API from '../../../helpers/api';
import { useDispatch, useSelector } from 'react-redux';

const OtpVerifyForgetPassword = ({ email }) => {
    const dispatch = useDispatch()
    const [userOtp, setOtp] = useState('');
    const [seconds, setSeconds] = useState(15)
    const [alertMessage, setAlertMessage] = useState(null);
    const [requestLoading, setRequestLoading] = useState(false)
    // const [showResendTimer, setResendTimer] = useState(true)
    const state = useSelector(state => state.auth)


    function updateTime() {
        if (seconds == 0) {
          setSeconds(0);
          setAlertMessage(null)
        }
        else {
            setSeconds(seconds => seconds - 1);
        }
    }

    useEffect(() => {
        // use set timeout and be confident because updateTime will cause rerender
        // rerender mean re call this effect => then it will be similar to how setinterval works
        // but with easy to understand logic
        const token = setTimeout(updateTime, 1000)
    
        return function cleanUp() {
            // console.log( ' Time Cleared...' )
          clearTimeout(token);
        }
    })

    useEffect(() => {
        if (userOtp.length === 6) {
            // console.log( 'Submitted...' )
            setRequestLoading(true)
            //** --- Auto Submit --- */
            dispatch(verifyOtpResetPassword({ email: email, otp: userOtp }))
        }
    }, [userOtp])

    useEffect(() => {
        setAlertMessage(null)
        if(state.error){
            setRequestLoading(false)
            setAlertMessage({
                variant: 'danger',
                message: state.error
            })
        }
    }, [state])

    const handleRequestOTP = async () => {
        try {
            setRequestLoading(true)
            setAlertMessage(null)
            
            const response = await API.apiPost('otpRequest', {email: email});
            setRequestLoading(false)
            setSeconds(15)
            setAlertMessage({ variant: response.data.success ? 'success' : 'danger', message:response.data.message  })
        } catch (err) {
            
            let data = get(err, 'response.data', null);
            data = data || get(err, 'response');
            data = data || err;
            setRequestLoading(false)
            if (data.error) {
                setAlertMessage({ variant: 'danger', message:data.error.message || 'Unable to process your request, try later'  })
            }else{
                setAlertMessage({ variant: 'danger', message:data.message || 'Unable to process your request, try later'  })
            }
        }
    }
    
    return (
        <div className="formTop py-4">
            <div className="text-center">
                <h2>We sent you a verification code</h2>
                <p>Enter code below to verify email {email}</p>
            </div>
            <Form className='digit-group'>
                <ReactCodeInput
                    type='number'
                    fields={6}
                    onChange={v => { 
                        setOtp(v)
                        setAlertMessage(null)
                    }}
                />
            </Form>
            {/* ------- Proper Working Resend Timer ------ */}
            {/* <div style={{justifyContent: 'center',textAlign:'center' }}>
                {seconds > 0 ? <span>Time Remaining: {seconds} seconds</span> : <span>Didn't Receive Code ? {' '}</span>}
                <span style={{ cursor:(seconds === 0 && !requestLoading) ? 'pointer' : 'default', color:(seconds === 0 && !requestLoading) ? '#e44300':'grey' }} onClick={() => (seconds === 0 && !requestLoading) ? handleRequestOTP() : null}> {' '} {requestLoading ? 'Please Wait...': 'Resend'} </span>
            </div> */}
            {/* ------- Proper Working Resend Timer ------ */}
            <div style={{justifyContent: 'center',textAlign:'center' }}>
                {!requestLoading && <span>Didn't Receive Code ? {' '}</span>}
                <span style={{ cursor:!requestLoading ? 'pointer' : 'default', color:!requestLoading ? '#e44300':'grey' }} onClick={() =>!requestLoading ? handleRequestOTP() : null}> {' '} {requestLoading ? 'Please Wait...': 'Resend'} </span>
            </div>
            {alertMessage && <Alert variant={alertMessage.variant} className="text-center">{alertMessage.message}</Alert>}
        </div>
    )
}

export default OtpVerifyForgetPassword;