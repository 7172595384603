import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import SinglePost from './Post'
import SidebarLogin from '../LoginSidebar';
import PostFeedsLoggedOut from '../PostListingLoggedOut';
import SidebarLoggedOut from '../SidebarLoggedOut';
import SidebarLogged from '../SidebarLogged';
import PostFeeds from '../PostFeedsOut'
import SidebarFollow from '../FollowSidebar'
import Footer from '../Footer'

const Post = () => {
    // const dispatch = useDispatch();
    const state = useSelector(state => state.auth)
    return (
        <div className='Single-posts'>
            <Container>
                <Row className='justify-content-center'>
                    <Col sm={12} md={3} xl={3} className='menu--sidebar'>
                        {state.loggedIn ? <SidebarLogged /> : <SidebarLoggedOut />}
                    </Col>
                    <Col sm={12} md={6} xl={6} className='section--content'>
                      <SinglePost/>
                      {state.loggedIn ? null : <Footer/>}
                    </Col>
                    <Col sm={12} md={3} xl={3} className='rightFeed--sidebar'>
                        {state.loggedIn ? <SidebarFollow /> : <SidebarLogin />}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Post