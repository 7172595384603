import {
    GET_TAGS_SUCCESS,
    GET_TAGS_FAILED,
    TAGS_COUNT_SUCCESS,
    TAGS_COUNT_FAILED,
} from "../../redux/actions/types";

const initialState = {
    tags: null,
    error: null,
};


export default (state = initialState, action) => {
    switch (action.type) {
            case GET_TAGS_SUCCESS:
                return {
                    tags: action.payload.tags,
                }
            case GET_TAGS_FAILED:
                return {
                    error: action.payload
                }
                case TAGS_COUNT_SUCCESS:
                    return {
                        tags: action.payload.tags,
                    }
                case TAGS_COUNT_FAILED:
                    return {
                        error: action.payload
                    }
        default: return state;
    }
};
