import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, ListGroup, Button, Card, Accordion } from 'react-bootstrap';
// import { BiSearch, BiLike, BiDislike } from 'react-icons/bi';
import { createPostFessds, ListTagsFeed, ListPostFeedData } from '../../redux/actions/post.action';
// import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { notValid } from "../../utils/validations";
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import toast from "react-hot-toast";
import { CiImageOn } from 'react-icons/ci';
import { IoClose } from 'react-icons/io5';
// import { TbCalendarTime } from 'react-icons/tb';
import ImageCropper from "../ImageCropper";
import { get } from 'lodash';


const CreatePostFeed = (props) => {
    // const navigate = useNavigate();
    const textAreaRef = useRef(null);
    const dispatch = useDispatch();
    const [post, setPost] = useState({ title: '', description: ''});
    const [error, setError] = useState({ title: '', description: ''});
    const [loading, setLoading] = useState(false);
    // const [string, setstring] = useState('');
    const [buttonHide, setButtonHide] =  useState(true)
    const [textareaValue, setTextareaValue] = useState("");
    // const [errortextareaValue, seterrortextareaValue] = useState("");
    const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
    const [showHashtagSuggestions, setShowHashtagSuggestions] = useState(false);
    const [tagshashdata, settagshashdata] = useState([]);
    const [showToaster, setshowToaster] =  useState(false)

    /** ---- Image Cropper ---- */
    const [ isCropperOpen, setCropperOpen ] = useState(false);
    const [ imageForCrop, setImageForCrop ] = useState(null);

    const postFeedCreate = useSelector(state => state.post);
    const tagsdata = useSelector(state => state.tags.tags);
    const [tokenData, setTokenData] = useState('');


    const generateRandomString = () => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 15; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    useEffect(() => {
        (async () => {
            await handleTagsData()
            setTokenData(auth.decodedLoggedUser());
        })();
    }, [])

    const handleTagsData = async () => {
        // console.log( props?.sideBarAccess )
        if(!props?.sideBarAccess){  // Denied when Open Create Post Modal From SideBar
            dispatch(ListTagsFeed());
        }
    }

    useEffect(() => {
        setButtonHide(false)
        if(!post.title){
            setButtonHide(true)
        }

        if(!post.description){
            setButtonHide(true)
        }
    }, [post, error])


    useEffect(() => {
        toast.dismiss()
        if (postFeedCreate.PostFeeds) {
            if(showToaster){
                toast.success(postFeedCreate.PostFeeds);
            }
            setPost({ title:'', description:'', media: null })
            setTextareaValue('')
            setLoading(false)
            if(props?.sideBarAccess){
                props?.setCreatePost(false)
            }
        }

        if(postFeedCreate.PostFeedsError){
            toast.error(postFeedCreate.PostFeedsError);
            setLoading(false)
            if(props?.sideBarAccess){
                props?.setCreatePost(false)
            }
        }
    }, [postFeedCreate.PostFeeds, postFeedCreate.PostFeedsError])

    useEffect(() => {
        settagshashdata(tagsdata)
    }, [tagsdata])


    const handleChangeInput = ({ name, value }) => {

        setPost({ ...post, [name]: value });
        setError({ ...error, [name]: '' });
    }


    const isValid = () => {
        let isValid = true
        let description = "Please provide description";
        let title = "Please provide title";
        if (!post.title) {
            setError({ ...error, ['title']: title })
            isValid = false;
        }

        if(!post.description){
            setError({ ...error, ['description']: description })
            isValid = false;
        }

        if (notValid(description)) {
            // seterrortextareaValue(description)
            // setError({ ...error, ['description']: description })
            isValid = false
        }
        return isValid
    }

    function errorRequest(err) {
        let data = get(err, 'response.data', null);
        data = data || get(err, 'response');
        data = data || err;
        if (data.error) {
            return data.error.message
        } else {
          return data.message
        }
    }

    const onSubmitCreatePost = async (e) => {
        e.preventDefault();
        try {
            if (isValid()) {
                setLoading(true)
                // const response = await API.apiPost('postfeeds',{...post, slug:generateRandomString()});
                //     const postConfig = {
                //     onUploadProgress: async progressEvent => {
                //         const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        
                //     }
                //   }
                const formData =  new FormData();
                formData.append('title', post.title)
                formData.append('description', post.description);
                formData.append('slug', generateRandomString());
                if(post.media){
                    post.media.forEach(( file, fileIndex) => {
                        var filename = file.name ? file.name.split('.')[0] : Date.now() + `_${fileIndex}`;
                        formData.append(`${filename}`, file);
                    })
                }

                const response = await API.apiUploadFile('postfeeds', formData);
                setLoading( false );
                // console.log('response',response)
                if(response.data && response.data.success){
                    toast.success(response.data.message);
                    // props.handleListPost()
                    setPost({ title:'', description:'', media: null })
                    setTextareaValue('')
                    if(props?.sideBarAccess){
                        props?.setCreatePost(false)
                    }
                    dispatch(ListPostFeedData())
                    // props.handleListPost()
                }else{
                    setLoading( false );
                    toast.error(response.data.message);
                }
                // dispatch(createPostFessds({...post, slug:generateRandomString()}))
                // setshowToaster(true)
        
            }
        } catch (error) {
            
            const message = errorRequest(error)
            // console.log( 'Error ---->> ', message )
            setLoading( false );
            toast.error(message || 'something went wrong');
        }
    }

    const handleHashtagSelection = (suggestion) => {
        if (suggestion.startsWith("#")) {
            setTextareaValue((prevValue) => prevValue.replace(/#[a-zA-Z0-9_]*$/, suggestion));
        } else {
            setTextareaValue((prevValue) => prevValue + suggestion);
        }
        setHashtagSuggestions([]);
        setShowHashtagSuggestions(false);
    };


    const handleTextareaChange = (event) => {
        const { value } = event.target;
        setTextareaValue(value);
        setError({ ...error, ['description']: '' });
        setPost({ ...post, description: value })
        if (value.includes("#")) {
            const lastMatch = value.match(/#[a-zA-Z0-9_]*$/)?.[0].substring(1);
            const filteredSuggestions = tagshashdata?.filter((hashtag) =>
                hashtag?.toLowerCase().startsWith(lastMatch?.toLowerCase())
            );
            setHashtagSuggestions(filteredSuggestions);
            setShowHashtagSuggestions(true);
        } else {
            setHashtagSuggestions([]);
            setShowHashtagSuggestions(false);
        }
    };

    // const dataURItoBlob = (dataURI)  => {
    //     var binary = atob(dataURI.split(',')[1]);
    //     var array = [];
    //     for(var i = 0; i < binary.length; i++) {
    //         array.push(binary.charCodeAt(i));
    //     }
    //     return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
    // }

    const dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        
        return new Blob([ia], {type:mimeString});
    }

    const handleTextareaBlur = () => {
        const textarea = document.getElementsByName("description")[0];
        textarea.classList.remove("highlight");
    };

    const handlePictureUpload = (e) => {
        e.preventDefault();
        // console.log( e.target.files )
        if(!['image', 'video'].includes(e.target.files[0].type.split('/')[0])){
            toast.error('Only image/video is allowed');
            return;
        }

        if(e.target.files && e.target.files.length > 4){
            toast.error('Please upload upto 4 photos/videos');
            return ;
        }
        if (e.target.files && e.target.files[0]) {
            // handleMediaFiles(e.target.files);
            let copyPost = { ...post }
            if(!copyPost.media){
                copyPost.media = []
            }
            copyPost['media'].push(e.target.files[0])
            setPost({ ...copyPost});
        }
    }

    const auto_grow = (event) => {
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }

    const handleRemoveImage = (index) => {
        let copyPost = { ...post }
        copyPost['media'].splice(index, 1);

        setPost({ ...copyPost })
    }

    const handleOpenCropper = (index) => {
        setCropperOpen(true)
        let data = {
            index: index,
            src: URL.createObjectURL(post['media'][index])
        }
        setImageForCrop(data) 
    }

    const handleCloseCropper = () => {
        setImageForCrop(null)
        setCropperOpen(false)
    }

    const handeSaveCroppedImage = (dataURI) => {
        const imageFile = dataURItoBlob(dataURI);
        // console.log('File::',  imageFile )
        let copyPost = { ...post }
        copyPost['media'][imageForCrop.index] = imageFile
        setPost({ ...copyPost});
    }
    
    //////////////////hash tag \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    return (<>
        <div>
            <div className='feed--listing'>
                <Row>
                    <Col sm={12}>
                        <Form>
                            <div className={`type--feed--post profile-img-follow ${props?.className?.removeTopborder}`} id={`type--feed--post profile-img-follow ${props?.className?.removeTopborder}`}>
                                <div className='type--here'>
                                    <span className='user--avatar'>
                                        {/* <img src={require('../../assets/images/profile.png')} alt='...' /> */}
                                        <span className={`name--initial nm-${tokenData?.userName?.split('')[0]}`}>{tokenData?.userName?.split('')[0]}</span>
                                    </span>
                                    <div className="type--comment">
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter title here"
                                                name="title"
                                                value={post.title}
                                                onChange={({ target }) => handleChangeInput(target)}
                                            />
                                        </Form.Group>
                                        {error.title && <span className="error error-massege">{error.title}</span>}

                                        {/* <textarea rows='5' placeholder="What's happening"
                                            name="description"
                                            value={textareaValue}
                                            onChange={handleTextareaChange}
                                        ></textarea> */}

                                        <textarea
                                            rows="2"
                                            placeholder="Write your question"
                                            name="description"
                                            value={textareaValue}
                                            onChange={handleTextareaChange}
                                            onBlur={handleTextareaBlur}
                                            ref={textAreaRef}  
                                            onInput={auto_grow}
                                        ></textarea>
                                        
                                        {error.description && <span className="error error-massege">{error.description}</span>}                       
                                        
                                        {(post.media && post.media.length > 0) &&  
                                            <div className="post--media--grid" id="post--media--grid">
                                                {post.media.map((picture, pictureIndex) => {
                                                    return (
                                                        <div className="post--media--upload">
                                                            {picture.type.split('/')[0] === 'image'
                                                                ?    <img key={pictureIndex} src={typeof picture === 'object' ? URL.createObjectURL(picture) : picture}/>
                                                                :    <video src={typeof picture === 'object' ? URL.createObjectURL(picture) : picture} controls/>
                                                            }
                                                           
                                                            <span className="post--media--close" onClick={() => handleRemoveImage(pictureIndex)}><IoClose/></span>
                                                            {picture.type.split('/')[0] === 'image' &&
                                                                <span className="post--media--edit" onClick={() => handleOpenCropper(pictureIndex)}>Edit</span>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </div>
                                {showHashtagSuggestions && (
                                    <div className="hashtag-suggestions">
                                        <ul>
                                            {hashtagSuggestions?.map((suggestion, index) => (
                                                <li key={index} onClick={() => handleHashtagSelection(suggestion)}>
                                                    {suggestion}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {error.errortextareaValue && <span className="error error-massege">{error.errortextareaValue}</span>}
                                <div className='post--options'>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            <input type="file" id="post--image--upload" hidden onChange={(e) => handlePictureUpload(e)}/>
                                            <label for="post--image--upload">
                                                <CiImageOn/>
                                            </label>
                                        </ListGroup.Item>
                                        {/* <ListGroup.Item className="poll-img"><img src={require('../../assets/images/poll.png')} alt='...' /></ListGroup.Item> */}
                                        {/* <ListGroup.Item><TbCalendarTime /></ListGroup.Item> */}
                                    </ListGroup>
                                    {/* {!buttonHide &&
                                    } */}
                                    <Button 
                                        variant="primary" 
                                        onClick={(e) => onSubmitCreatePost(e)} 
                                        disabled={loading || buttonHide}>
                                        {loading ? 'Please Wait...' : 'Post'}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                        {/* <div>
                            <div className="post-form">
                                <textarea
                                    rows='5'
                                    value={textareaValue}
                                    onChange={handleTextareaChange}
                                    placeholder="What's happening?"
                                />
                                {showHashtagSuggestions && (
                                    <div className="hashtag-suggestions">
                                        <ul>
                                            {hashtagSuggestions?.map((suggestion, index) => (
                                                <li key={index} onClick={() => handleHashtagSelection(suggestion)}>
                                                    {suggestion}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div> */}
                    </Col>
                </Row>
                {isCropperOpen && 
                   ( <ImageCropper 
                        isOpen={isCropperOpen}
                        src={imageForCrop?.src}
                        onClose={() => handleCloseCropper()}
                        result={(val) => handeSaveCroppedImage(val)}
                    />)
                }
            </div>
        </div>
    </>)
}

export default CreatePostFeed