import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";

const ImageCropper = (props) => {
    const cropperRef = useRef(null);
    // const [ cropData, setCropData ] = useState(null)

    const saveCrop = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            props.result(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            props.onClose();
        }
    };

    
    return (
    <Modal
        className="cropper--modal"
        show={props.isOpen}
        onHide={() => props.onClose()}
        backdrop="static"
        // keyboard={false}
        // aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header
            closeButton
            style={{ height:'50px' }}
        >
            <Modal.Title>
                Crop & Save
            </Modal.Title>
        </Modal.Header>
        <Cropper
            src={props.src}
            style={{ height: 400, width: "100%" }}
            // Cropper.js options
            zoomTo={0.5}
            viewMode={1}
            initialAspectRatio={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            guides={true}
            autoCropArea={1}
            checkOrientation={false} 
            // crop={() => onCrop()}
            ref={cropperRef}
            {...props}
        />
        <Modal.Footer>
            {/* <Button className='secondary'>Close</Button> */}
            <Button onClick={() => saveCrop()}>Crop</Button>
        </Modal.Footer>
    </Modal>
    )
}

export default ImageCropper