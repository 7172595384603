import React, { useEffect, useState } from "react";
import { ListGroup, Button, Card } from 'react-bootstrap';
// import { BiSearch, BiLike, BiDislike } from 'react-icons/bi';
// import { TbCalendarTime } from 'react-icons/tb';
// import { CiImageOn } from 'react-icons/ci';
// import { BsCalendar4 } from 'react-icons/bs';
import Swal from 'sweetalert2';
import {ListTopPosts } from '../../redux/actions/post.action';
import { userSuggestionsList } from '../../redux/actions/post.action'
// import { FaRegCommentDots } from 'react-icons/fa';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from 'react-loader-spinner';


const SidebarFollow = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [randomPost, setrandomPost] = useState([]);
    const [loaderListing, setloaderListing] = useState(true);
    const [loaderListingIdea, setloaderListingIdea] = useState(true);
    const [userList, setuserList] = useState([]);
    const [postfeedList, setpostfeedList] = useState([]);
    const postFeed = useSelector(state => state.ideas.topideas);
    const usersList = useSelector(state => state.users.users);
    const handleListPost = async () => {
        dispatch(userSuggestionsList());
        dispatch(ListTopPosts());
    }

    useEffect(() => {
        (async () => {
            await handleListPost()

        })();
    }, [])

    useEffect(() => {
        const check = ['undefined', undefined, 'null', null, '']
        if (!check.includes(usersList)) {
            setuserList([ ...usersList ])
            setloaderListing(false)
            
        }
    }, [usersList])

    useEffect(() => {
        const check = ['undefined', undefined, 'null', null, '']
        if (!check.includes(postFeed)) {
            setpostfeedList([ ...postFeed ])
            setloaderListingIdea(false)
            
        }
    }, [postFeed])

    return (
        <>
            <div className='fixed--sidebar'>
                <div className='logged--sidebar sidebar--follow profile-img-follow'>
                    <h3>Who to Follow</h3>
                    {!loaderListing ?
                    userList?.length ?
                        userList.slice(0, 5).map((user) => {
                            return (<>
                                <Card className='profile-img-follow'>
                                    {/* <Card.Img variant="middle"
                                        src={require('../../assets/images/profile.png')}
                                    /> */}
                                    <span className='user--avatar me-0'>
                                        {/* <img src={require('../../assets/images/profile.png')} alt='...' /> */}
                                        <span className={`name--initial nm-${user?.userName?.split('')[0]}`}>{user?.userName?.split('')[0]}</span>
                                    </span>
                                    <Card.Body onClick={() => navigate(`/profile/${user._id}`)}>
                                        <Card.Title>{user.userName}</Card.Title>
                                        <Card.Text>@{user.userName}
                                            {/* {user.email} */}
                                        </Card.Text>
                                    </Card.Body>
                                    <Button variant='primary' onClick={() => Swal.fire('Follwing a person coming soon')}>Follow</Button>
                                </Card>
                            </>)
                        })
                        :
                        <div><h4>Users Not Found</h4></div>
                        :
                        <div className="loader-main-list">
                            <ThreeDots
                                height="50"
                                width="50"
                                radius="9"
                                color="#e44300"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    }
                </div>


                <div className='logged--sidebar sidebar--follow profile-img-follow'>
                    <h3>Top Questions</h3>
                    { !loaderListingIdea ?
                    postfeedList?.length ?
                    postfeedList.slice(0, 5).map((data) => {
                            return (<>
                                <Card className='more--ideas'>
                                    <Card.Body>
                                        <Card.Title>
                                        <Link 
                                            to={`/post/feeds/${data._id}`}
                                            // to={`/post/feeds/${data._id}/${data.slug}`}
                                        >
                                            {data.title}
                                        </Link>
                                            {/* <Card.Link href='#'>{data.title}</Card.Link> */}
                                        </Card.Title>
                                        <Card.Text>
                                            {/* {data.description} */}
                                            {data?.description?.split(" ").map((word, index) => {
                                                    if (word.startsWith("#")) {
                                                        return (
                                                            <span key={index} className="highlighted-keyword">
                                                                <Link to={`/post/feed/${word.substr(1)}`}>
                                                                    {word}{" "}
                                                                </Link>
                                                            </span>);
                                                    } else {
                                                        return <span key={index}>{word} </span>;
                                                    }
                                                })}
                                        </Card.Text>
                                        <ListGroup>
                                            <ListGroup.Item>{data.comments?.length} Comments</ListGroup.Item>
                                            <ListGroup.Item> {data.likes?.length} Upvotes</ListGroup.Item>
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </>)
                        })
                        :
                        <div><h4>Post not found</h4> </div>
                        :
                        <div className="loader-main-list">
                            <ThreeDots
                                height="50"
                                width="50"
                                radius="9"
                                color="#e44300"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    }
                </div>
                <p className="mt-3"><Link to="/terms-service">Terms of Service</Link> and <Link to="/privacy-policy">Privacy Policy</Link></p>
            </div>
            
        </>
    )
}

export default SidebarFollow