import React, { useEffect, useState } from "react";
import { Container, Row, Form, Button, Col, FloatingLabel, Modal, Spinner } from "react-bootstrap";
import GoogleLogin from 'react-google-login';
import { FaGoogle } from "react-icons/fa";
import { BsLinkedin } from 'react-icons/bs';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import toast from "react-hot-toast";
import { notValid, emailValidation } from "../../../utils/validations";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { Login } from '../../../redux/actions/auth.actions'
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { signInWithGoogle } from "./firebase"
import API from '../../../helpers/api';
import * as auth from '../../../helpers/auth';

// import { linkedInAuthentication } from '../../../redux/actions/auth.actions';
// import { BsLinkedin } from 'react-icons/bs'



const SignIn = (props) => {
    // console.log( props )
    let j = 0;
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const [user, setUser] = useState({ email: '', password: '' });
    const [error, setError] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [ErrorAPi, setErrorAPi] = useState(false);
    const [errorApi, seterrorApi] = useState(false);
    const [linkedInLoader, setLinkedInLoader] = useState(false)
    const [forgetEmail, setforgetEmail] = useState('');
    const [showSignUpModel, setshowSignUpModel] = useState(false);
    const [showLoginModel, setshowLoginModel] = useState(false);
    const [linkedInError, setLinkdeInError] = useState('')
    const [showPassword, setShowPassword] = React.useState(false);
    const apiResult = useSelector(state => state.auth);
    const apiLinkedInError = useSelector(state => state.auth.socialLoginError);
    // const apiLinkedInSuccess = useSelector(state => state.auth.token)

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    // const responseGoogle = (response) => {
    //     console.log(response);
    // }
    /** -- Redux State -- */

    const handleChangeInput = ({ name, value }) => {
        setUser({ ...user, [name]: value })
        setError({ ...error, [name]: '' })
    }

    const handleChangeForget = ({ name, vaue }) => {
        setforgetEmail(vaue)
    }

    const isValid = () => {
        let isValid = true
        let passwordErrorMessage = "Please provide password";
        let userNameErrorMessage = "Please provide Email";
        let email = "Please enter your email";
        let validEmail = "Please enter a valid email address"
        let errorMessages = {};

        if (notValid(user.email)) {
            errorMessages['email'] = email;
            isValid = false;
        } else if (!emailValidation(user.email)) {
            errorMessages['email'] = validEmail;
            isValid = false;
        }

        if (notValid(user.password)) {
            errorMessages['password'] = passwordErrorMessage;
            isValid = false;
        }

        if (!isValid) {
            setError({ ...error, ...errorMessages });
        }

        return isValid;
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        if (isValid()) {
            setLoading(true)
            await dispatch(Login(user))
            setErrorAPi(true)
            setLoading(false)
            // if (apiResult.loggedIn) {
            //     window.location.replace('/')
            // }
        }
    }
    useEffect(() => {
        if (ErrorAPi) {
            if (apiResult.error) {
                seterrorApi(apiResult.error)
            }
        }
    }, [ErrorAPi])

    // useEffect(() => {
    //     setLinkdeInError('')
    //     // console.log( 'Api Error ' , apiLinkedInError )
    //     if(apiLinkedInError){
    //         setLinkdeInError(apiLinkedInError)
    //         setLinkedInLoader(false)
    //     }

    //     // if(apiLinkedInSuccess){
    //     //     window.location.replace('/')
    //     // }
    // }, [apiLinkedInError])

    /////////////////////////////////Login with Google ////////////////////////////////

    const handleGoogleLoginSuccess = (response) => {
        // console.log('response', response)
    };

    const handleGoogleLoginFailure = (error) => {
        // console.log('error', error)
    };





    const onSuccess = async (code) => {
        // console.log('caling index')
        setLinkedInLoader(true)
        // j++
        // if(code && j===1){
        //     console.log( code  )
        //     setLinkdeInError('')
        //     // await dispatch(linkedInAuthentication(code));
        //     try {   
        //         setLinkedInLoader(true)         
        //         const response = await API.apiPostUrl('auth', `/linkedIn/login?code=${code}`)
        //         setLinkedInLoader(false)
        //         console.log( response.data )
        //         if(response.data && response.data.success){
        //             if(response.data.accessToken){
        //                 auth.login(response.data.accessToken);
        //             }
        //             window.location.replace('/')
        //         }else{
        //             setLinkdeInError(response.data.message)
        //         }
        //     } catch (error) {
        //         setLinkdeInError(error.message || 'Unable to proceed, try again')
        //     }
        // }
    }


    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_CLIENTID, // '86vhj2q7ukf83q',
        redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        scope: 'r_liteprofile r_emailaddress',
        onSuccess: onSuccess,
        onError: (error) => {
            // console.log('Linked In ---- Error', error);
        },
    });

    const onSuccessl = (response) => {
        // console.log('Authentication successful!', response)
    }

    const onFailure = (error) => {
        // console.error('Authentication failed!', error)
    }



    useEffect(() => {
        if (apiResult && apiResult.token) {
            // window.location.replace('/')
        }
    }, [apiResult])

    const handleShowforgetModel = () => setshowLoginModel(true);
    const handlecloseforgetModel = () => setshowLoginModel(false);

    return (
        <>
            <Container className="h-100">
                <Row className="justify-content-center align-items-center h-100 login-page-main-s">
                    <Col sm={12} className="login-page-main-w">
                        {/* {forgetpass ? */}
                        <div className="main-wraper-login">
                            {props.showMessageAlert &&
                                <div className="message-alert">
                                    <p>{props.displayMessage}</p>
                                </div>
                            }
                            <h2>Sign in to <span className="Ideascab">Ideas.cab</span></h2>
                            <div className="btn-google-login">
                                <div className="mb-3">
                                <button class="login-with-google-btn" onClick={() => signInWithGoogle()}>
                                  <FaGoogle/>  Login in with Google
                                </button>
                                </div>
                                <button
                                    onClick={() => linkedInLoader ? null : linkedInLogin()}
                                >
                                    <BsLinkedin /> Login with Linkedin {' '} {linkedInLoader ? <Spinner size='sm' /> : null}
                                </button>
                                {linkedInError && <span className='error error-massege'>{linkedInError}</span>}
                            </div>

                            <div className="or-login-page">
                                <hr />
                                <p>OR</p>
                                <hr />
                            </div>
                            <div>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email"
                                    className="mb-3"
                                >
                                    <Form.Control type="text"
                                        id="standard"
                                        placeholder="Email"
                                        name="email"
                                        required
                                        value={user.email || ''}
                                        onChange={({ target }) => handleChangeInput(target)}
                                    />
                                    {error.email && <span className="error error-massege">{error.email}</span>}
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Password"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        placeholder="Password"
                                        id="standard-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        value={user.password || ''}
                                        onChange={({ target }) => handleChangeInput(target)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                // onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {error.password && <span className="error error-massege">{error.password}</span>}

                                    {errorApi && <span className="error error-massege">{errorApi}</span>}

                                </FloatingLabel>
                                {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                    <Input
                                        id="standard-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        value={user.password || ''}
                                        onChange={({ target }) => handleChangeInput(target)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                // onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl> */}
                            </div>
                            <div className="checkbox-remember">
                                {/* <div key={`Remember me`}>
                                    <Form.Check
                                        type='checkbox'
                                        id='Remember me'
                                        label='Remember me'
                                    />
                                </div> */}
                                {/* <div className="Forgot-pass ms-auto">
                                    <p 
                                    onClick={() => handleShowforgetModel()}
                                    >Forgot Password ?</p>
                                </div> */}
                            </div>
                            <div className="login-btn-main">
                                <Button onClick={(e) => onSubmit(e)} disabled={loading}>
                                    {loading ? 'Please Wait...' : 'Login'}
                                </Button>
                            </div>
                            {/* <div className="sign-up-btn-login-page">
                                <p>Don’t have a n account? <span className="color-signUp-span">
                                    <Link to='/sign-up'>Sign Up</Link></span></p>
                            </div> */}
                        </div>
                        {/* //     :
                        //     <h2>Forgot Password <span className="Ideascab">Ideas.cab</span></h2>
                        // } */}
                    </Col>

                    <Modal show={showLoginModel} onHide={handlecloseforgetModel}
                        // {...props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="forget-pass-model">
                                <h2>Forgot Password <span className="Ideascab">Ideas.cab</span></h2>

                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email Address"
                                    className="mb-3"
                                >
                                    <Form.Control type="email"
                                        id="standard"
                                        name="email"
                                        placeholder="Email Address"
                                        value={forgetEmail}
                                        onChange={handleChangeForget}
                                    />
                                </FloatingLabel>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Row>
            </Container>
        </>
    )
}

export default SignIn;