import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import { authHeader } from '../../helpers/auth'
import {
  CREATE_STRIPE_ACCOUNT_ID_SUCCESS,
  CREATE_STRIPE_ACCOUNT_ID_FAILED,
  CONNECT_STRIPE_ACCOUNT_SUCCESS,
  CONNECT_STRIPE_ACCOUNT_FAILED,
  STRIPE_COMMON_ERROR,
  ONBOARDING_SUCCESS,
  ONBOARDING_FAILED,
  TRANSFER_FUNDS_SUCCESS,
  TRANSFER_FUNDS_FAILED,
  GET_STRIPE_DETAIL_SUCUESS,
  GET_STRIPE_DETAIL_FAILED,
  UPDATE_TRANSACTION_STATUS_SUCUESS,
  UPDATE_TRANSACTION_STATUS_FAILED,
  GET_TRANSACTION_HISTORY_SUCCESS,
  GET_TRANSACTION_HISTORY_FAILED

}from "./types";


const config = {
    headers: {
      'Content-Type': "application/json; charset=utf-8",
      "Authorization": authHeader()
    }
}

function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
        type: STRIPE_COMMON_ERROR,
        payload: data.error.message,
      });
    } else if (data.status === 402) {   
      auth.logout()
    } else {
      dispatch({
        type: STRIPE_COMMON_ERROR,
        payload: data.message,
      });
    }
}

export const connectStripeAccount = () =>{
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('stripe', '/create/account' , config);
            if(response.data.success){
                await dispatch({type: CONNECT_STRIPE_ACCOUNT_SUCCESS , payload: response.data.redirectUrl});
            }else{
                await dispatch({type: CONNECT_STRIPE_ACCOUNT_FAILED , payload: response.data.message  });
            }
        }catch(error){
          errorRequest(error, dispatch)
        }
    }
}

export const checkOnBoarding = (payload) =>{
  return async (dispatch) => {
    try{
        const response = await API.apiPostUrl('stripe' , '/onboarding' , payload , config);
        if(response.data.success){
          await dispatch({type:ONBOARDING_SUCCESS , payload: response.data.message})
        }else {
          await dispatch({type:ONBOARDING_FAILED  , payload: response.data})
        }
    }catch(error){
      errorRequest(error, dispatch)
    }
  }  
}


export const tranferFunds = (payload) =>{
  return async (dispatch) => {
    try{
      const response = await API.apiPostUrl('stripe' , '/transfer/funds' , payload );
      if(response.data.success){
        if(response.data.redirectUrl){
          await dispatch({type: TRANSFER_FUNDS_SUCCESS , payload: response.data.redirectUrl})
        }else{
          await dispatch({type: TRANSFER_FUNDS_FAILED, payload: response.data.message})
        }
      }else{
        await dispatch({type: TRANSFER_FUNDS_FAILED, payload: response.data.message})
      }
    }catch(error){
      errorRequest(error, dispatch)
    }
  }
}

export const getStripeDetail = (id) =>{
  return async (dispatch) => {
    try{
      const response = await API.apiGet('stripeDetail', `/${id}`);
      if(response.data.success){
        await dispatch({type: GET_STRIPE_DETAIL_SUCUESS, payload: response.data.stripeInfo})
      }else{
        await dispatch({type: GET_STRIPE_DETAIL_FAILED, payload: response.data.message})
      }
    }catch(error){
      errorRequest(error, dispatch)
    }
  } 
}

export const updateTransactionStatus = (payload) =>{
  return async (dispatch) => {
    try{
      const response = await API.apiPostUrl('stripe' , '/update/transaction/status' , payload);
      if(response.data.success){
        await dispatch({type: UPDATE_TRANSACTION_STATUS_SUCUESS, payload: response.data.transaction})
      }else{
        await dispatch({type: UPDATE_TRANSACTION_STATUS_FAILED, payload: response.data.message})
      }
    }catch(error){
      errorRequest(error, dispatch)
    }
  }
}


export const getTransactionHistory = () =>{
  return async (dispatch) => {
    try{
      const response = await API.apiGet('stripe', '/get/transaction/history');
      if(response.data.success){
        await dispatch({type: GET_TRANSACTION_HISTORY_SUCCESS , payload: response.data.transactionHistory})
      }else{
        await dispatch({type: GET_TRANSACTION_HISTORY_FAILED, payload: response.data.message})
      }
    }catch(error){
      errorRequest(error, dispatch)
    }
  }
}








