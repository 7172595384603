import React, { useEffect, useState } from "react";
import { Form, Row, Col, ListGroup, Button, Card, Modal, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import {BiLike, BiTrash } from 'react-icons/bi';
import { ListPostFeedData } from '../../redux/actions/post.action';
import { SendComment, DeleteComment, LikeComment, DisLikeComment, SendReplyComment, DeleteNestedComment, deletePost } from '../../helpers/auth'
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TbCalendarTime } from 'react-icons/tb';
// import { CiImageOn } from 'react-icons/ci';
import { BsCalendar4 } from 'react-icons/bs';
import { FaRegCommentDots } from 'react-icons/fa';
// import { notValid, emailValidation } from "../../utils/validations";
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import toast from "react-hot-toast";
/** --- Light Box --- */
import LightboxComponent from "../LightBoxComponent";
import { AiTwotoneLike } from "react-icons/ai";
// import { AiFillDislike } from "react-icons/ai";
import { ThreeDots } from 'react-loader-spinner';
// import { InputTags } from 'react-bootstrap-tagsinput';
import CreatePostFeed from './CreatePostFeed';
import 'react-bootstrap-tagsinput/dist/index.css'
import SearchPostFeed from './SearchPostFeed';
import * as auth from '../../helpers/auth';

// import InfiniteScroll from 'react-infinite-scroller';

const PostFeeds = (props) => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [searchTerm, setSearchTerm] = useState('');
    const [activeId, setActiveId] = useState(null);
    const [loaderListing, setloaderListing] = useState(true);
    const [comments, setComments] = useState([]);
    const [ delLoader, setDelLoader ] = useState(null)
    // const [loader, setLoader] = useState(true);
    const [PostFeedd, setPostFeed] = useState([]);
    const [ShowCommentModel, setShowCommentModel] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [itemsPerPage, setItemsPerPage] = useState(15);
    const [userId, setuserId] = useState('');
    // const [hasMore, setHasMore] = useState(true);
    const [dataComment, setdataComment] = useState('');

    /** --- Light Box State --- */
    const [isLightBoxOpen, setLightBox] = useState(false);
    const [ postMedia, setPostMedia ] = useState(null);

    // const apiError = useSelector(state => state.post.error);
    const postFeed = useSelector(state => state.post.getPostFeed);
    const postFeedCreate = useSelector(state => state.post);
    // const deletePostMessage = useSelector(state => state.post.deletePostMessage)
    // const commonError = useSelector(state => state.post.error);
    // const postDeleteError = useSelector(state => state.post)
    // const [posts, setPosts] = useState([]);
// console.log( postFeedCreate )
    const handleCloseCommentModel = () => setShowCommentModel(false);
    const handleShowCommentModel = (data) => {
        setdataComment(data)
        // console.log('data', data)
        setShowCommentModel(true);
    }

    const handleListPost = async () => {
        dispatch(ListPostFeedData());
    }

    
    useEffect(() => {
        (async () => {
            // console.log( 'Calling....' )
            await handleListPost()


        })();
    }, [])


    useEffect(() => {
        (async () => {
            // console.log('During Create....', postFeedCreate, postFeed)
            if (postFeedCreate.PostFeeds) {
                // toast.success(postFeedCreate.PostFeeds);
                await handleListPost()
            }

            if (postFeedCreate.PostFeedsError) {
                // toast.error(postFeedCreate.PostFeedsError);
            }

            if(postFeedCreate.deletePostMessage){
                if(delLoader){
                    toast.success('Deleted!')
                }
                setDelLoader(null)
            }

            if(postFeedCreate.deletePostError){
                setDelLoader(null)
            }

        })();
    }, [postFeedCreate.PostFeeds, postFeedCreate.PostFeedsError, postFeedCreate.deletePostMessage, postFeedCreate.deletePostError])


    useEffect(() => {
        let token = localStorage.getItem('accessToken')
        let DecodedToken = jwt_decode(token)
        setuserId(DecodedToken.aud)
    }, [])

    useEffect(() => {
        // console.log( 'Create Post....', postFeedCreate )
        const check = ['undefined', undefined, 'null', null, '']
        if (!check.includes(postFeed)) {
            setPostFeed([ ...postFeed ])
            setloaderListing(false)
            
        }
    }, [postFeed])

    const handleNewComment = (event) => {
        setComments(event.target.value);
    }

    const handleCommentSubmit = async (data) => {
        const newComment = { text: comments };
        SendComment(comments, data._id, userId);
        setComments([]);
        handleCloseCommentModel()
        toast.success("comment created successfully");
    };
    const handleReplyCommentSubmit = async (data) => {
        const newComment = { text: comments };
        SendReplyComment(comments, data.post, userId, data._id);
        setComments([]);
    };

    const handleDelteComment = async (commentId, feedId) => {
        // console.log('deleet id', commentId, feedId)
        DeleteComment(commentId, feedId)
        handleCloseCommentModel()
        await handleListPost()
    }

    const handleNestedDelteComment = async (commentId) => {
        DeleteNestedComment(commentId)
    }

    const handleLike = async (data) => {
        // setLoader(true)
        LikeComment(userId, data._id)
        // setLoader(false)
    }

    const handleDisLike = async (data) => {
        // setLoader(true)
        DisLikeComment(userId, data._id)
        // setLoader(false)
    }

    const toggleAccordion = (id) => {
        setActiveId(activeId === id ? null : id);
        setComments([]);

    }

    function getWordElement(word, index) {
        // function generateRandomString() {
        //     let result = '';
        //     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        //     for (let i = 0; i < 15; i++) {
        //         result += characters.charAt(Math.floor(Math.random() * characters.length));
        //     }
        //     return result;
        // }
        // const randomString = generateRandomString();

        if (word.startsWith("#")) {
            return (
                <span key={index} className="highlighted-keyword">
                    <Link 
                        to={`/post/feed/${word.substr(1)}`}
                        // href={`/post/feed/${word.substr(1)}/${randomString}`}
                    >
                        {word}{" "}
                    </Link>
                </span>
            );
        } else {
            return <span key={index}>{word} </span>;
        }
    }

    const handleLoaderForSearch = () => {
        setloaderListing(true)
    }

    /** --- Light Box Code  */
    const handleCloseLightBox = () => {
        setLightBox(false)
        setPostMedia(null)
    }

    const handleOpenLightBox = (media) => { 
        let images = media.filter(mImage => mImage.resource_type === 'image').map(m => { return { src: m.secure_url, alt: 'Image' } });
        setPostMedia(images)
        setLightBox(true)
    }

    const handleDeletePost = (postId) => {
        setDelLoader(postId)
        deletePost(userId, postId);
    }
   
    return (
        <>

            <div className='feed--listing'>
                <Row>
                    <Col sm={12}>
                        {isLightBoxOpen && 
                            <LightboxComponent
                                isOpen={isLightBoxOpen}
                                images={postMedia}
                                onClose={() => handleCloseLightBox()}
                            />
                        }
                        <SearchPostFeed handleLoaderForSearch={handleLoaderForSearch}/>
                        {!props.hideCreatePost && 
                        <CreatePostFeed 
                            handleListPost={()=> handleListPost()} 
                        />}
                        <div className='feed--cards'>
                            {
                                !loaderListing ?
                                PostFeedd?.length 
                                    ?   PostFeedd.map((data, ind) => {
                                            return (<>
                                                <Card>
                                                    <span className='user--avatar'>
                                                        {/* <img src={require('../../assets/images/profile.png')} alt='...' /> */}
                                                        <span className={`name--initial nm-${data?.author?.userName?.split('')[0]}`}>{data?.author?.userName.split('')[0]}</span>
                                                    </span>
                                                    <Card.Body>
                                                        <Card.Title className="title-post-feed">
                                                            <Card.Link>
                                                                <Link to={`/post/feeds/${data._id}`}>{data.title}</Link>
                                                               
                                                                {/* <Link to={`/post/feeds/${data._id}/${data.slug}`}>{data.title}</Link> */}
                                                            </Card.Link>
                                                            {(data?.author?._id === userId) && <span onClick={() => handleDeletePost(data._id)}> 
                                                                {(delLoader === data._id) ? <Spinner size="sm"/> : <BiTrash/> }
                                                            </span>}
                                                        </Card.Title>
                                                        <Card.Subtitle className="mb-3 text-muted">
                                                            <span><BsCalendar4 /> {moment(data.createdAt).fromNow()} by <a className="auther-name">{data?.author?.userName}</a></span>
                                                        </Card.Subtitle>
                                                        <div className="mb-3">
                                                            {/* <pre> */}
                                                                {data?.description?.split(" ").map(getWordElement)}
                                                            {/* </pre> */}
                                                            {(data.media && data.media.length > 0) 
                                                                ?    <div className="post--feed--grid">
                                                                        {data.media.map((media, pictureIndex) => {
                                                                            // console.log( media )
                                                                            return (
                                                                                <div className="post--feed--media">
                                                                                    {auth.checkImageExtension().includes(media.format) && (<img key={pictureIndex} src={media.secure_url} onClick={() => handleOpenLightBox(data.media)}  onL/>) }
                                                                                    {auth.checkVideoExtension().includes(media.format) && (<video key={pictureIndex} src={media.secure_url} autoPlay={false} controls/>) }
                                                                                    {(!auth.checkImageExtension().includes(media.format) && !auth.checkVideoExtension().includes(media.format)) && <img key={pictureIndex} src={require('../../assets/images/nip.png')} />}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                :   null
                                                            }
                                                        </div>
                                                        <ListGroup className="list-icons-main">
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={  
                                                                    <Tooltip id="button-tooltip-comment">
                                                                        Comment
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <ListGroup.Item onClick={() => handleShowCommentModel(data)}> <FaRegCommentDots /> {data?.comments?.length}</ListGroup.Item>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={  
                                                                    <Tooltip id="button-tooltip">
                                                                        {data?.likes?.some(like => like?.author?._id === userId) ? 'Downvote' : 'Upvote'}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <ListGroup.Item
                                                                    key={ind}
                                                                    className="likebtn"
                                                                    onClick={() => handleLike(data)}
                                                                >
                                                                    {data?.likes?.some(like => like?.author?._id === userId) ? (
                                                                        <AiTwotoneLike />) : (<BiLike />)}
                                                                    {data?.likes?.length}
                                                                </ListGroup.Item>
                                                            </OverlayTrigger>
                                                            
                                                            {/* <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={  
                                                                    <Tooltip id="button-tooltip-comment">
                                                                        Schedule Consultation
                                                                    </Tooltip>
                                                                }
                                                            >
                                                            <ListGroup.Item><TbCalendarTime /></ListGroup.Item>
                                                            </OverlayTrigger> */}
                                                            {/* <ListGroup.Item
                                                                key={ind}
                                                                className="likebtn"
                                                                onClick={() => handleDisLike(data)}>
                                                                {data?.dislikes?.some(dis => dis?.author?._id == userId) ? (
                                                                    <AiFillDislike />) : (<BiDislike />)}
                                                                {data?.dislikes?.length}
                                                            </ListGroup.Item> */}
                                                        </ListGroup>
                                                        {/* <div className="comments-postfeed"> */}
                                                            
                                                            {/* {data?.comments?.map((x) => {
                                                                return (<>
                                                                {x?.author &&
                                                                    <div
                                                                        className="comment-section-main"
                                                                    >
                                                                        <div className="comment-img">
                                                                            {
                                                                                <img src={require('../../assets/images/profile.png')} alt='...' />
                                                                            }
                                                                            <div>
                                                                                <h4 className="auther-name-commet">{x?.author?.userName}</h4>
                                                                                <p><TbCalendarTime /> {moment(x?.createdAt).fromNow()} </p>
                                                                            </div>
                                                                            {x?.author?._id == userId &&
                                                                                <div className="delet-comment"
                                                                                    onClick={() => handleDelteComment(x?._id, data?._id)}>
                                                                                    <BsTrash /></div>}
                                                                        </div>
                                                                        <div className="text-comment">
                                                                            <p className="mb-0">{x?.text}</p>

                                                                        </div>
                                                                    </div>
                                                            }
                                                                </>)
                                                            })} */}

                                                        {/* </div> */}
                                                        {/* <div className="accordion">
                                                            <div className="accordion-header-main"
                                                                onClick={() => toggleAccordion(data._id)}>
                                                                <FaRegCommentDots /> {data?.comments?.length}
                                                            </div>
                                                            {activeId === data._id &&
                                                                <div className="accordion-body-main">
                                                                    <Form.Group className="mb-3 border-commet" controlId="exampleForm.ControlTextarea1">
                                                                        <Form.Control
                                                                            value={comments}
                                                                            onChange={handleNewComment} as="textarea" placeholder="Write something hre" rows={3} />
                                                                    </Form.Group>
                                                                    <div className="submit-btn-comment">
                                                                        <Button type="submit" onClick={(() => handleCommentSubmit(data))}
                                                                        >Submit</Button>
                                                                    </div>
                                                                </div>}
                                                        </div> */}
                                                        {/* {console.log('data?.comments', data)}
                                                        {
                                                            // data?.comments &&
                                                            data?.comments?.map((comment) => {
                                                                return (
                                                                    <>
                                                                        {comment.author &&
                                                                            <div className="comment-section-main">
                                                                                <div className="comment-img">
                                                                                    {data?.comments &&
                                                                                        <img src={require('../../assets/images/profile.png')} alt='...' />
                                                                                    }
                                                                                    <div>
                                                                                        <h4 className="auther-name-commet">{data?.comments && comment?.author?.userName}</h4>
                                                                                        <p><TbCalendarTime /> {moment(comment?.createdAt).fromNow()} </p>
                                                                                    </div>
                                                                                    {data?.comments && comment?.author?._id == userId &&
                                                                                        <div className="delet-comment"
                                                                                            onClick={() => handleDelteComment(comment?._id, data?._id)}>
                                                                                            <BsTrash /></div>}
                                                                                </div>
                                                                                <div className="text-comment">
                                                                                    <p className="mb-0">{comment?.text}</p>

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )
                                                            })} */}

                                                    </Card.Body>
                                                </Card>
                                            </>)
                                        })
                                    :   <h2 className="mt-5 text-center">Post Not Found</h2>

                                    :
                                    // null
                                    <div className="loader-main-list">
                                        <ThreeDots
                                            height="50"
                                            width="50"
                                            radius="9"
                                            color="#e44300"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                            }
                        </div>
                        {/* </InfiniteScroll> */}
                    </Col>
                </Row>
            </div>
            <Modal
                className="comment--modal"
                show={ShowCommentModel}
                onHide={handleCloseCommentModel}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <Card className='profile-img-follow'>
                        <Card.Img variant="middle" src={require('../../assets/images/profile.png')} />
                        <Card.Body>
                            <Card.Title className='auther-name'>{dataComment?.author?.userName}</Card.Title>
                            <Card.Text className="mb-2 text-muted">{dataComment?.title}</Card.Text>
                            <Card.Subtitle className="text-muted">
                                <span>Replying to <a href='#'>@{dataComment?.author?.userName}</a></span>
                            </Card.Subtitle>
                        </Card.Body>
                    </Card>
                    <div className="comment-popup">
                        <Form.Group className="mb-3 d-flex align-items-start">
                            <img src={require('../../assets/images/profile.png')} />
                            <Form.Control
                                value={comments}
                                onChange={handleNewComment} as="textarea" placeholder="Write something here" rows={3} />
                        </Form.Group>
                        <div className="submit-btn-comment">
                            <Button type="submit" onClick={(() => handleCommentSubmit(dataComment))}
                            >Submit</Button>
                        </div>
                        {/* <div className="comments-postfeed">
                            {dataComment?.comments?.map((x) => {
                                return (<>
                                    <div
                                        className="comment-section-main"
                                    >
                                        <div className="comment-img">
                                            {
                                                <img src={require('../../assets/images/profile.png')} alt='...' />
                                            }
                                            <div>
                                                <h4 className="auther-name-commet">{x?.author?.userName}</h4>
                                                <p><TbCalendarTime /> {moment(x?.createdAt).fromNow()} </p>
                                            </div>
                                            {x?.author?._id == userId &&
                                                <div className="delet-comment"
                                                    onClick={() => handleDelteComment(x?._id, dataComment?._id)}>
                                                    <BsTrash /></div>}
                                        </div>
                                        <div className="text-comment">
                                            <p className="mb-0">{x?.text}</p>

                                        </div>
                                    </div>
                                </>)
                            })}
                        </div> */}

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PostFeeds