import React, { useEffect, useState } from "react";
import SignUp from '../../authentication/sign-up';
import SignIn from '../../authentication/sign-in';
import { Container, Row, Form, Button, Col, Modal, FloatingLabel } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { notValid, emailValidation } from "../../../utils/validations";
import { useSelector, useDispatch } from "react-redux";
import { CreateAccount,requestOtp } from '../../../redux/actions/auth.actions'
import { Link } from 'react-router-dom';
import toast from "react-hot-toast";
import OtpVerify from '../../authentication/reset-password/OtpVerify'
import otpVerifyForget from "../../authentication/reset-password/OtpVerifywithForget";
import { ImCross } from "react-icons/im";
import AuthModels from ".././../authentication/authModels"
import LoginModelOpen from "../../authentication/authModels/LoginModelOpen"

const Footer = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.auth)
    const [user, setUser] = useState({ userName: '', email: '', password: '', dob: '' });
    const [errors, setErrors] = useState({ userName: '', email: '', password: '', dob: '' });
    const [errorsForget, seterrorsForget] = useState({ email: '' });
    const [errorforget, seterrorforget] = useState(false);
    const [errorforgetMessage, seterrorforgetMessage] = useState('');
    const [forgetemail, stforgetemail] = useState('')
    const [showOtpModel, setshowOtpModel] = useState(true);
    const [ErrorAPi, setErrorAPi] = useState(false);
    const [showusername, setshowusername] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorApi, seterrorApi] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false) // forget otp modelshow
    const [showotpModelForgetOtp, setshowotpModelForgetOtp] = useState(false)
    const [dob, setDob] = useState({ month: '', day: '', year: '' });
    const [showSignUpModel, setshowSignUpModel] = useState(false);
    const [showLoginModel, setshowLoginModel] = useState(false);
    const [showotpModel, setshowotpModel] = useState(false)
    const [showForgetModel, setshowForgetModel] = useState(false);
    const [showLoginModelAuth, setshowLoginModelAuth] = useState(false);
    const [showLoginFooterModelAuth, setshowLoginFooterModelAuth] = useState(false);

    const handleClose = () => setshowSignUpModel(false);
    const handleShow = () => {
        setshowSignUpModel(true);
        setshowLoginModel(false);
    }

    const handleClodeOtpModel = () => {
        setshowSignUpModel(false);
        setshowotpModel(false)
    }


    const handleCloseLoginModel = () => setshowLoginModel(false);
    const handleShowLoginModel = () => {
        setshowLoginModel(true);
        setshowSignUpModel(false);
    }

    const HandleCloseForgetModel = () => {
        setshowForgetModel(false)
    }
    const HandleshowForgetModel = () => {
        setshowForgetModel(true)
        setshowLoginModel(false);
    }

    const isValid = () => {
        let isValid = true
        let passwordErrorMessage = "Please provide password";
        let userNameErrorMessage = "Please provide Username";
        let email = "Please enter your email";
        let validEmail = "Please enter a valid email address"
        let errorMessages = {};

        if (notValid(user.userName)) {
            errorMessages['userName'] = userNameErrorMessage;
            isValid = false;
        }

        if (notValid(user.password)) {
            errorMessages['password'] = passwordErrorMessage;
            isValid = false;
        }
        if (notValid(user.email)) {
            errorMessages['email'] = email;
            isValid = false;
        } else if (!emailValidation(user.email)) {
            errorMessages['email'] = validEmail;
            isValid = false;
        }

        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }

        return isValid;
    }

    const handleChange = ({ target: { name, value } }) => {
        setUser({ ...user, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const handleChangeDob = (e) => {
        const { name, value } = e.target;
        setDob({ ...dob, [name]: value });
        const dateStr = value + '/' + value + '/' + value;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isValid()) {
            setLoading(true)
            // console.log('user', user)
            await dispatch(CreateAccount(user))
            setErrorAPi(true)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (ErrorAPi) {
            if (state.error) {
                seterrorApi(state.error)
            }
        }
    }, [ErrorAPi])

    useEffect(() => {
        if (state.verifyMessage) {
            toast.success(state.verifyMessage);
        }

    }, [state])

    useEffect(() => {
        if (state.created_Account) {
            setshowotpModel(true)
            setshowSignUpModel(false);
        }
    }, [state])


    //////////////////////////////////////////////// sign -up //////////////////////////



    ////////////////////////////////////////////////end sign -up //////////////////////////


    //////////////////////////////////////////////// forget pass //////////////////////////

    const handleChangeForgetEmail = (e) => {
        stforgetemail(e.target.value)
        seterrorsForget({email : ''})
    }

    const isValidForgetEmail = () => {
        let isValid = true
        let email = "Please enter your email";
        let validEmail = "Please enter a valid email address"
        let errorMessages = {};

        if (notValid(forgetemail)) {
            errorMessages['email'] = email;
            isValid = false;

        } else if (!emailValidation(forgetemail)) {
            errorMessages['email'] = validEmail;
            isValid = false;
        }

        if (!isValid) {
            seterrorsForget({ ...errorsForget, ...errorMessages });
        }
        return isValid;
    }

    // console.log('errorApi',errorApi)
    // console.log('state',state)


    const onSubmitFoget = async () => {
        if(isValidForgetEmail()){
            setLoading(true);
            await dispatch(requestOtp({ email: forgetemail }))
            setLoading(false);
            seterrorforget(true)
        }
    }
    useEffect(() => {
        if (state.error) {
            seterrorforgetMessage(state.error)
        }
    }, [errorforget])

    const handleClodeOtpModelForget =()=>{
        setshowotpModelForgetOtp(false)
    }

    const handleLoginModel =()=>{
        // setshowLoginModelAuth(true) 
        setshowLoginFooterModelAuth(true)
    }



    ////////////////////////////////////////////////end forget pass //////////////////////////


    

    return (
        <>
            <div className='bottom--bar'>
                <Container>
                    <div className='button--group d-flex align-items-center justify-content-center'>
                        <Button variant="light" 
                         onClick={() => handleLoginModel()}
                        // onClick={handleShowLoginModel}
                        >Login</Button>
                        <Button variant="outline-light" 
                        onClick={() => setshowLoginModelAuth(true)}
                        // onClick={handleShow}
                        >Sign up</Button>
                    </div>
                </Container>
            </div>

            {showLoginModelAuth &&
                <AuthModels setshowLoginModelAuth={setshowLoginModelAuth} />
            }
             {showLoginFooterModelAuth &&
                <LoginModelOpen 
                // setshowLoginModelAuth={setshowLoginModelAuth} 
                setshowLoginFooterModelAuth={setshowLoginFooterModelAuth}
                />
            }






            {/*    
            <Modal show={showSignUpModel} onHide={handleClose}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='sign-up-model'>
                        <Row className="justify-content-center align-items-center h-100 login-page-main-s">
                            <Col sm={12} className="login-page-main-w main-wraper-login">
                                <div className="sign-up">
                                    <h2>Create New Account</h2>
                                    <p>Help us get to know you from the information you provide to get free account.</p>
                                    <div>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Username"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text"
                                                id="standard"
                                                placeholder="Username"
                                                name="userName"
                                                value={user['userName'] || ''}
                                                onChange={handleChange}
                                            />
                                            {errors.userName && <span className="error error-massege">{errors.userName}</span>}
                                        </FloatingLabel>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Email Address"
                                            className="mb-3"
                                        >
                                            <Form.Control type="email"
                                                id="standard"
                                                name="email"
                                                placeholder="Email Address"
                                                value={user['email'] || ''}
                                                onChange={handleChange}
                                            />
                                            {errors.email && <span className="error error-massege">{errors.email}</span>}
                                            {errorApi && <span className="error error-massege">{errorApi}</span>}
                                        </FloatingLabel>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Password"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                id="standard-adornment-password"
                                                placeholder="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                value={user['password'] || ''}
                                                onChange={handleChange}
                                            />
                                            {errors.password && <span className="error error-massege">{errors.password}</span>}
                                        </FloatingLabel>
                                    </div>
                                    <div className="Date-of-birth">
                                        <h4>Date of Birth</h4>
                                        <p>Enter your date of birth to verify your age and eligibility for our services.</p>
                                        <Row>
                                            <Col lg={4}>
                                                <Form.Select onChange={handleChangeDob}
                                                    name="month"
                                                >
                                                    <option>Month</option>
                                                    <option value="01">January</option>
                                                    <option value="02">February</option>
                                                    <option value="03">March</option>
                                                    <option value="04">April</option>
                                                    <option value="05">May</option>
                                                    <option value="06">June</option>
                                                    <option value="07">July</option>
                                                    <option value="08">August</option>
                                                    <option value="09">September</option>
                                                    <option value="10">October</option>
                                                    <option value="11">November</option>
                                                    <option value="12">December</option>
                                                </Form.Select>
                                            </Col>
                                            <Col lg={4}>
                                                <Form.Select onChange={handleChangeDob}
                                                    name="day"
                                                >
                                                    <option>Day</option>
                                                    <option value="01">1</option>
                                                    <option value="02">2</option>
                                                    <option value="03">3</option>
                                                    <option value="04">4</option>
                                                    <option value="05">5</option>
                                                    <option value="06">6</option>
                                                    <option value="07">7</option>
                                                    <option value="08">8</option>
                                                    <option value="09">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                    <option value="31">31</option>
                                                </Form.Select>
                                            </Col>
                                            <Col lg={4}>
                                                <Form.Select onChange={handleChangeDob}
                                                    name="year"
                                                >
                                                    <option>Year</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2021">2021</option>
                                                    <option value="2020">2020</option>
                                                    <option value="2019">2019</option>
                                                    <option value="2018">2018</option>
                                                    <option value="2017">2017</option>
                                                    <option value="2016">2016</option>
                                                    <option value="2015">2015</option>
                                                    <option value="2014">2014</option>
                                                    <option value="2013">2013</option>
                                                    <option value="2012">2012</option>
                                                    <option value="2011">2011</option>
                                                    <option value="2010">2010</option>
                                                    <option value="2009">2009</option>
                                                    <option value="2008">2008</option>
                                                    <option value="2007">2007</option>
                                                    <option value="2006">2006</option>
                                                    <option value="2005">2005</option>
                                                    <option value="2004">2004</option>
                                                    <option value="2003">2003</option>
                                                    <option value="2002">2002</option>
                                                    <option value="2001">2001</option>
                                                    <option value="2000">2000</option>
                                                    <option value="1999">1999</option>
                                                    <option value="1998">1998</option>
                                                    <option value="1997">1997</option>
                                                    <option value="1996">1996</option>
                                                    <option value="1995">1995</option>
                                                    <option value="1994">1994</option>
                                                    <option value="1993">1993</option>
                                                    <option value="1992">1992</option>
                                                    <option value="1991">1991</option>
                                                    <option value="1990">1990</option>
                                                    <option value="1989">1989</option>
                                                    <option value="1988">1988</option>
                                                    <option value="1987">1987</option>
                                                    <option value="1986">1986</option>
                                                    <option value="1985">1985</option>
                                                    <option value="1984">1984</option>
                                                    <option value="1983">1983</option>
                                                    <option value="1982">1982</option>
                                                    <option value="1981">1981</option>
                                                    <option value="1980">1980</option>
                                                    <option value="1979">1979</option>
                                                    <option value="1978">1978</option>
                                                    <option value="1977">1977</option>
                                                    <option value="1976">1976</option>
                                                    <option value="1975">1975</option>
                                                    <option value="1974">1974</option>
                                                    <option value="1973">1973</option>
                                                    <option value="1972">1972</option>
                                                    <option value="1971">1971</option>
                                                    <option value="1970">1970</option>
                                                    <option value="1969">1969</option>
                                                    <option value="1968">1968</option>
                                                    <option value="1967">1967</option>
                                                    <option value="1966">1966</option>
                                                    <option value="1965">1965</option>
                                                    <option value="1964">1964</option>
                                                    <option value="1963">1963</option>
                                                    <option value="1962">1962</option>
                                                    <option value="1961">1961</option>
                                                    <option value="1960">1960</option>
                                                    <option value="1959">1959</option>
                                                    <option value="1958">1958</option>
                                                    <option value="1957">1957</option>
                                                    <option value="1956">1956</option>
                                                    <option value="1955">1955</option>
                                                    <option value="1954">1954</option>
                                                    <option value="1953">1953</option>
                                                    <option value="1952">1952</option>
                                                    <option value="1951">1951</option>
                                                    <option value="1950">1950</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="login-btn-main">
                                        <Button onClick={(e) => handleSubmit(e)} disabled={loading}>
                                            {loading ? 'Please Wait...' : 'Submit'}
                                        </Button>
                                    </div>
                                </div>
                                {/* } */}
                            {/* </Col>
                        </Row>

                        <div className="sign-up-btn-login-page text-center">
                            <p>Already have an account? <span onClick={handleShowLoginModel} className="color-signUp-span">
                                Login here</span></p>
                        </div>
                        <div className="text-center Privacy-text-sign-up">
                            <p>By continuing, you agree to <Link to='/terms-service'>Terms of Use </Link>and confirm that you have read <Link to='/privacy-policy'>Privacy Policy</Link></p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */} 



            {/* <Modal show={showLoginModel} onHide={handleCloseLoginModel}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='sign-up-model'>
                        <SignIn />
                        <div className="sign-up-btn-login-page text-center">
                            <p>Don’t have an account? <span onClick={handleShow}
                                className="color-signUp-span">
                                Sign Up</span></p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}


            {/* <Modal show={showForgetModel}
                onHide={HandleCloseForgetModel}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="model-forget-padding">
                        <h3 className="mb-4">Forget Password</h3>
                        <Form.Control type="email"
                            id="standard"
                            name="email"
                            placeholder="Email Address"
                            value={forgetemail}
                            onChange={(e) => handleChangeForgetEmail(e)}
                        />
                        {errorsForget.email && <span className="error error-massege">{errorsForget.email}</span>}
                        {errorforgetMessage && <span className="error error-massege">{errorforgetMessage}</span>}
                    </div>
                    <div className="login-btn-main">
                        <Button onClick={(e) => onSubmitFoget(e)} disabled={loading}>
                            {loading ? 'Please Wait...' : 'Submit'}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal> */}

{/* 
            <Modal show={showotpModelForgetOtp}
                onHide={handleClodeOtpModelForget}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="otp-Section">
                        <otpVerifyForget
                            email={forgetemail}
                        />
                    </div>
                </Modal.Body>
            </Modal> */}

        </>
    )
}

export default Footer