import React, { useEffect, useState } from "react";
import { Form, Row, Col, ListGroup, Card, Button, Modal, OverlayTrigger, Tooltip, Dropdown, Spinner } from 'react-bootstrap';
import { BiTrash } from 'react-icons/bi';
import { AiFillDislike, AiFillLike } from 'react-icons/ai';
import { BsArrowLeft, BsCalendar4, BsTrash } from 'react-icons/bs';
import { FaRegCommentDots } from 'react-icons/fa';
import { TbCalendarTime } from 'react-icons/tb';
import { IoEllipsisHorizontalCircle } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getOneFeedPost } from '../../../redux/actions/post.action'
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import toast from "react-hot-toast";
/** --- Light Box --- */
import LightboxComponent from "../../LightBoxComponent";
import CheckOutCommon from "../../Checkout";
import Swal from 'sweetalert2';
import LoginModelOpen from "../../authentication/authModels/LoginModelOpen"
import { socket, DeleteComment, SendComment, LikeComment, DisLikeComment, SendReplyComment, DeleteNestedComment, deletePost } from '../../../helpers/auth'
import { ThreeDots } from 'react-loader-spinner';

import moment from 'moment';
import * as authHelper from '../../../helpers/auth';
import { MdDelete } from "react-icons/md";
import BookConsultModel from "../../BookConsultModel";

const SinglePost = (props) => {
    const { id, slug } = useParams();
    const feedId = id
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();
    const [ShowCommentModel, setShowCommentModel] = useState(false);
    const [loader, setLoader] = useState(false); // initially true
    const [activeId, setActiveId] = useState(null);
    const [comments, setComments] = useState([]);
    const [loaderListing, setloaderListing] = useState(true);
    const [ delLoader, setDelLoader ] = useState(null)
    const [userId, setuserId] = useState('');
    const [deletec, setdeletec] = useState(false);
    const [repliesData, setrepliesData] = useState('');
    const [ShowrepliesModel, setShowrepliesModel] = useState(false);
    const [showLoginModelAuth, setshowLoginModelAuth] = useState(false);
    const [userNameloggin, setuserNameloggin] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [showLoginFooterModelAuth, setshowLoginFooterModelAuth] = useState(false);
    const [showMessageAlert, setShowMessageAlert] = useState(false);
    const [openConsultModel , setOpenConsultModel] = useState(false);
    const [authorDetail , setAuthorDetail] = useState('');
  

    /** --- Light Box State --- */
    const [isLightBoxOpen, setLightBox] = useState(false);
    const [ postMedia, setPostMedia ] = useState(null);

    const postFeed = useSelector(state => state.post.getOne);
    const auth = useSelector(state => state.auth);
    // const commentsdata = useSelector(state => state.post.getOne);
    const postState = useSelector(state => state.post);


    const handleCloseRepliesModel = () => setShowrepliesModel(false);

    const handleshowRepliesModel = (data) => {
        setShowrepliesModel(true)
        setrepliesData(data)
    }

    const handleCloseCommentModel = () => setShowCommentModel(false);
    const handleShowCommentModel = () => setShowCommentModel(true);

    const handleSinglePost = async () => {
        await dispatch(getOneFeedPost(feedId));
    }

    useEffect(() => {
        (async () => {
            await handleSinglePost()
        })();
    }, [])

    useEffect(() => {
        (async () => {
            await handleSinglePost()
        })();
    }, [deletec, feedId])


    useEffect(() => {
        if (postFeed) {
            setloaderListing(false)
        }
    }, [postFeed])

    useEffect(() => {
        if (auth.loggedIn) {
            let token = localStorage?.getItem('accessToken')
            let DecodedToken = jwt_decode(token)
            setuserId(DecodedToken?.aud)
            setuserNameloggin(DecodedToken.userName)
        }
    }, [])

    useEffect(() => {
        
        if(postState.deletePostMessage){
            if(delLoader){
                toast.success('Deleted!')
            }
            setDelLoader(null)
            navigate('/')
        }

        if(postState.deletePostError){
            setDelLoader(null)
        }
    }, [postState.deletePostMessage, postState.deletePostError])

    const handleNewComment = (event) => {
        setComments(event.target.value);
    }

    const handleCommentSubmit = async (data) => {
        const newComment = { text: comments };
        SendComment(comments, data._id, userId);
        setComments('');
        await handleSinglePost()
        handleCloseCommentModel()
        toast.success("comment created successfully");
    };

    const handleReplyCommentSubmit = async (data) => {
        const newComment = { text: comments };
        SendComment(comments, data.post, userId, data._id);
        setdeletec(true)
        setComments('');
        handleCloseRepliesModel()
        toast.success("comment created successfully");
    };

    const handleDelteComment = async (commentId, feedId) => {
        DeleteComment(commentId, feedId)
        setDropdownOpen(false);
    }
    const handleNestedDelteComment = async (commentId) => {
        DeleteNestedComment(commentId)
        await handleSinglePost();
    }

    const handleLike = async (data) => {
        setLoader(true)
        LikeComment(userId, data._id)
    }

    const handleDisLike = async (data) => {
        setLoader(true)
        DisLikeComment(userId, data._id)
        // await handleSinglePost();
        // setLoader(false)
    }
    const toggleAccordion = (id) => {
        setActiveId(activeId === id ? null : id);
        setComments('');
    }

    const handleComment = (id) => {
        setDropdownOpen(dropdownOpen == id ? null : id)
    }

    const HandleShowLoginModel =()=>{
        setshowLoginFooterModelAuth(true)
        setShowMessageAlert(true)
    }

     /** --- Light Box Code  */
    const handleCloseLightBox = () => {
        setLightBox(false)
        setPostMedia(null)
    }

    const handleOpenLightBox = (media) => {
        let images = media.map(m => { return { src: m.secure_url, alt: 'Image' } });
        setPostMedia(images)
        setLightBox(true)
    }

    const handleDeletePost = (postId) => {
        setDelLoader(postId)
        deletePost(userId, postId);
    }

    const handleConsultion = (author) =>{
        setAuthorDetail(author)
        setOpenConsultModel(true)
    }

    const handleCloseConsultModel = () =>{
        setOpenConsultModel(false)
    }

    const handleOneTimeConsult = () =>{
        navigate('/checkout' , {state:authorDetail._id})
    }

    const commingSoon = () =>{
        setOpenConsultModel(false);
        Swal.fire('Coming Soon !' );

    }

    const pushToProfile = (author) =>{
        console.log("author" , author)
    }

    return (
        <>
            <div className='Single-posts'>
                <div className='feed--listing feed--listing--loggedOut'>
                    <Row>
                        {isLightBoxOpen && 
                             <LightboxComponent
                                isOpen={isLightBoxOpen}
                                images={postMedia}
                                onClose={() => handleCloseLightBox()}
                            />
                        }
                        <Col sm={12} md={12}>
                            <div className='singlePost--search d-flex align-items-center'>
                                <Link to='/postfeed' className='arrow--back'><BsArrowLeft /></Link>
                                {/* <Form className='post--search ms-auto'>
                                <Form.Group className='form-group'>
                                    <span className='search--icon'>
                                        <BiSearch />
                                    </span>
                                    <Form.Control type="text" placeholder="Search" />
                                </Form.Group>
                            </Form> */}
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className='feed--cards'>
                                {!loaderListing
                                    ? <Card>
                                        <span className='user--avatar'>
                                            {/* <img src={require('../../assets/images/profile.png')} alt='...' /> */}
                                            <span className={`name--initial nm-${postFeed?.author?.userName?.split('')[0]}`}>{postFeed?.author?.userName.split('')[0]}</span>
                                        </span>
                                        <Card.Body>
                                            <Card.Title className="single--post--content">
                                                {postFeed?.title}
                                                {(postFeed?.author?._id === userId) && <span onClick={() => handleDeletePost(postFeed._id)}> 
                                                    {(delLoader === postFeed._id) ? <Spinner size="sm"/> : <BiTrash/> }
                                                </span>}
                                            </Card.Title>
                                            <Card.Subtitle className="mb-3 text-muted">
                                                {/* <span>Analytics | Operational Reporting</span> */}
                                                <span><BsCalendar4 /> {moment(postFeed?.createdAt).fromNow()} <a>
                                                    {postFeed?.author?.userName}
                                                </a></span>
                                            </Card.Subtitle>
                                            <div className="mb-3">
                                                {postFeed?.description?.split(" ").map((word, index) => {
                                                    if (word.startsWith("#")) {
                                                        return (
                                                            <span key={index} className="highlighted-keyword">
                                                                <Link to={`/post/feed/${word.substr(1)}`}>
                                                                    {word}{" "}
                                                                </Link>
                                                            </span>);
                                                    } else {
                                                        return <span key={index}>{word} </span>
                                                            ;
                                                    }
                                                })}

                                                {(postFeed?.media && postFeed?.media.length > 0) 
                                                    ?    <div className="post--feed--grid">
                                                            {postFeed?.media.map((media, pictureIndex) => {
                                                                return (
                                                                    <div className="post--feed--media">
                                                                         {authHelper.checkImageExtension().includes(media.format) && (<img key={pictureIndex} src={media.secure_url} onClick={() => handleOpenLightBox(postFeed.media)}/>) }
                                                                        {authHelper.checkVideoExtension().includes(media.format) && (<video key={pictureIndex} src={media.secure_url} autoPlay={false} controls/>) }
                                                                        {(!authHelper.checkImageExtension().includes(media.format) && !authHelper.checkVideoExtension().includes(media.format)) && <img key={pictureIndex} src={require('../../../assets/images/nip.png')} />}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    :   null
                                                }
                                            
                                            </div>
                                            {/* <Card.Text>{postFeed?.description}</Card.Text> */}
                                            <ListGroup>
                                                <ListGroup.Item><strong>{postFeed?.comments?.length}</strong> Comments</ListGroup.Item>
                                                {auth.loggedIn ? 
                                                <ListGroup.Item ><strong>{postFeed?.likes?.length}</strong> Upvote</ListGroup.Item>
                                                :
                                                <ListGroup.Item onClick={()=>setshowLoginModelAuth(true)} ><strong>{postFeed?.likes?.length}</strong> Upvote</ListGroup.Item>
                                                }
                                                {/* <ListGroup.Item><strong>{postFeed?.dislikes?.length}</strong> Downvote</ListGroup.Item> */}
                                            </ListGroup>
                                            {auth.loggedIn ?
                                                <ListGroup className='mt-4 border-top pt-3'>
                                                    <ListGroup.Item onClick={handleShowCommentModel}><FaRegCommentDots /></ListGroup.Item>
                                                    {/* <ListGroup.Item><TbCalendarTime /></ListGroup.Item> */}
                                                    <ListGroup.Item className='ms-auto'>
                                                        <Button className="btn-upvoat" onClick={() => handleLike(postFeed)} variant={
                                                            postFeed?.likes?.some((x) => x.author._id == userId) ?
                                                                "primary" :
                                                                "outline-primary"}> <AiFillLike /> Upvote</Button>
                                                        {/* <Button onClick={() => handleDisLike(postFeed)}
                                                            variant={
                                                                postFeed?.dislikes?.some((x) => x.author._id == userId) ?
                                                                    "primary" : "outline-primary"}><AiFillDislike /> Downvote</Button> */}
                                                    </ListGroup.Item>

                                                </ListGroup>
                                                : 
                                                <div className="upvoat-main-wraper">
                                                <ListGroup.Item className='ms-auto'>
                                                <Button onClick={()=> HandleShowLoginModel()} className="btn-upvoat"
                                                 variant={
                                                    postFeed?.likes?.some((x) => x.author._id == userId) ?
                                                        "primary" :
                                                        "outline-primary"}> <AiFillLike /> Upvote</Button>
                                                   </ListGroup.Item>
                                                   </div>
                                                      }
                                        </Card.Body>
                                        {auth.loggedIn ?
                                            <>

                                                {/* <div className="accordion">
                                                <div className="accordion-header-main single-post"
                                                    onClick={() => toggleAccordion(postFeed?._id)}>
                                                    <FaRegCommentDots /> {postFeed?.comments?.length}
                                                </div>
                                                {activeId === postFeed?._id &&
                                                    <div className="accordion-body-main">
                                                        <Form.Group className="mb-3 border-commet" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Control
                                                                value={comments}
                                                                onChange={handleNewComment} as="textarea" placeholder="Write something hre" rows={3} />
                                                        </Form.Group>
                                                        <div className="submit-btn-comment">
                                                            <Button type="submit" onClick={(() => handleCommentSubmit(postFeed))}
                                                            >Submit</Button>
                                                        </div>
                                                    </div>}
                                            </div> */}
                                            </>
                                            : null}

                                        {postFeed?.comments?.map((comment, ind) => {
                                            return (
                                                <>
                                                    {
                                                        <div className="comment-section-main">
                                                            <div className="comment-img" key={ind} >
                                                                <Link to={`/profile/${comment?.author?._id}`}>
                                                                    <img src={require('../../../assets/images/profile.png')} alt='...' />
                                                                    <div >
                                                                        <h4 className="auther-name-commet">{comment?.author?.userName}</h4>
                                                                            <p><BsCalendar4 /> {moment(comment?.createdAt).fromNow()}</p>
                                                                    </div>
                                                                </Link>
                                                                {/* {comment?.author?._id == userId &&
                                                                <Dropdown>
                                                                    <Dropdown.Toggle id={`${comment?._id}`} variant="secondary">
                                                                        <IoEllipsisHorizontalCircle />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu variant="dark">
                
                                                                                <div className="delet-comment"
                                                                                    onClick={() => handleDelteComment(comment?._id, postFeed._id)}>
                                                                                    <BsTrash /> Delete
                                                                                </div>
                                                                            
                                                                    </Dropdown.Menu>
                                                                
                                                                </Dropdown>
                                                                } */}
                                                                {comment?.author?._id === userId && (
                                                                    <Dropdown show={dropdownOpen == comment?._id}
                                                                        onToggle={() => handleComment(comment._id)}>
                                                                        <Dropdown.Toggle id={`${comment?._id}`} variant="secondary">
                                                                            <IoEllipsisHorizontalCircle />
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu variant="dark">
                                                                            <div className="delete-comment" onClick={() => handleDelteComment(comment?._id, postFeed._id)}>
                                                                                <BsTrash /> Delete
                                                                            </div>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                )}

                                                            </div>
                                                            <div className="text-comment">
                                                                <p className="mb-2">{comment?.text}</p>
                                                                <div className="accordion">
                                                                    { comment?.author?._id !== userId && (
                                                                        <p className="me-3 mb-0">
                                                                            <OverlayTrigger
                                                                            placement="right"
                                                                            delay={{ show: 250, hide: 400 }}
                                                                            overlay={  
                                                                                <Tooltip id="button-tooltip-comment">
                                                                                    Schedule Consultation
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span><TbCalendarTime  onClick ={()=>handleConsultion(comment?.author)}/> </span> 
                                                                            </OverlayTrigger>
                                                                        </p>
                                                                    )}
                                                                    
                                                                    <div className="accordion-header-main me-3"
                                                                        onClick={() => handleshowRepliesModel(comment)}>
                                                                        <FaRegCommentDots /> {comment?.replies?.length}
                                                                    </div>
                                                                    <div className="accordion-header-main"
                                                                        onClick={() => toggleAccordion(comment?._id)}>
                                                                        <span className="show-replyes">Show Replies</span>
                                                                        {/* <FaRegCommentDots /> {comment?.replies?.length} */}
                                                                    </div>
                                                                    {activeId === comment?._id &&
                                                                        <div className="accordion-body-main">
                                                                            {/* {auth.loggedIn ?
                                                                                <>
                                                                                    <Form.Group className="mb-3 border-commet" controlId="exampleForm.ControlTextarea1">
                                                                                        <Form.Control
                                                                                            value={comments}
                                                                                            onChange={handleNewComment} as="textarea" placeholder="Write something hre" rows={3} />
                                                                                    </Form.Group>
                                                                                    <div className="submit-btn-comment">
                                                                                        <Button type="submit" onClick={(() => handleReplyCommentSubmit(comment))}>Submit</Button>
                                                                                    </div>
                                                                                </>
                                                                                : null} */}

                                                                            {comment?.replies?.map((x) => {
                                                                                return (<>
                                                                                    <div className="nested-comment">
                                                                                        <div className="comment-img">
                                                                                            <img src={require('../../../assets/images/profile.png')} alt='...' />
                                                                                            <div className="comment-marging">
                                                                                                <h4 className="auther-name-commet">{x?.author?.userName ? x?.author?.userName : userNameloggin}</h4>
                                                                                                {/* <p><TbCalendarTime onClick ={()=>handleConsultion(x?.author)}/> {moment(x?.createdAt).fromNow()} </p> */}
                                                                                                { x?.author?._id !== userId && (
                                                                                                    <p className="me-3 mb-0">
                                                                                                        <OverlayTrigger
                                                                                                        placement="right"
                                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                                        overlay={  
                                                                                                            <Tooltip id="button-tooltip-comment">
                                                                                                                Schedule Consultation
                                                                                                            </Tooltip>
                                                                                                        }
                                                                                                    >
                                                                                                        <span><TbCalendarTime  onClick ={()=>handleConsultion(x?.author)}/> </span> 
                                                                                                        </OverlayTrigger>
                                                                                                    </p>
                                                                                                )}
                                                                                            </div>
                                                                                            {/* {
                                                                                            x?.author?._id == userId &&
                                                                                                <div className="delet-comment"
                                                                                                    onClick={() => handleNestedDelteComment(x._id)}>
                                                                                                    <MdDelete /></div>
                                                                                            } */}
                                                                                        </div>
                                                                                        <div className="comment-marging"><p className="">{x?.text}</p></div>
                                                                                    </div>
                                                                                </>)
                                                                            })}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            )
                                        })}
                                        {/* {postFeed?.comments?.map((comment) => {
                                        return (
                                            <>
                                                <div className="comment-section-main">
                                                    <div className="comment-img">
                                                        <img src={require('../../../assets/images/profile.png')}

                                                            alt='...' />
                                                        <div>
                                                            <h4 className="auther-name-commet">{comment.author?.userName ? comment.author?.userName : "NA"}</h4>
                                                            <p><TbCalendarTime /> {moment(comment.updatedAt).fromNow()} </p>
                                                        </div>
                                                        {postFeed.author.userName == comment.author &&
                                                            <div className="delet-comment" onClick={() =>
                                                                handleDelteComment(comment._id, postFeed._id)
                                                                // socket.emit('delete_comment', { commentId: comment._id, postId: postFeed._id })
                                                            }><MdDelete /></div>
                                                        }
                                                    </div>
                                                    <div className="text-comment">
                                                        <p>{comment.text}</p>
                                                    </div>

                                                </div>
                                            </>
                                        )
                                    })} */}
                                    </Card>
                                    : <div className="loader-main-list">
                                        <ThreeDots
                                            height="50"
                                            width="50"
                                            radius="9"
                                            color="#e44300"
                                            ariaLabel="three-dots-loading"
                                            // wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            {showLoginFooterModelAuth &&
              <LoginModelOpen 
              setshowLoginFooterModelAuth={setshowLoginFooterModelAuth}
              showMessageAlert={showMessageAlert}
              />
            }

            {auth.loggedIn &&
                <>
                    <Modal
                        className="comment--modal"
                        show={ShowCommentModel}
                        onHide={handleCloseCommentModel}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>

                        </Modal.Header>
                        <Modal.Body>
                            <Card className='profile-img-follow'>
                                <Card.Img variant="middle" src={require('../../../assets/images/profile.png')} />
                                <Card.Body>
                                    <Card.Title className='auther-name'>{postFeed?.author?.userName}</Card.Title>
                                    <Card.Text className="mb-2 text-muted">
                                        {postFeed?.title}</Card.Text>
                                    <Card.Subtitle className="text-muted">
                                        <span>Replying to <a href='#'>{postFeed?.author?.userName}</a></span>
                                    </Card.Subtitle>
                                </Card.Body>
                            </Card>
                            <div className="comment-popup">
                                <Form.Group className="mb-3 d-flex align-items-start">
                                    <img src={require('../../../assets/images/profile.png')} />
                                    <Form.Control
                                        value={comments}
                                        onChange={handleNewComment} as="textarea" placeholder="Write something here" rows={3} />
                                </Form.Group>
                                <div className="submit-btn-comment">
                                    <Button type="submit" onClick={(() => handleCommentSubmit(postFeed))}
                                    >Submit</Button>
                                </div>
                            </div>
                            {/* </Form> */}
                        </Modal.Body>
                    </Modal>

                    <Modal
                        className="comment--modal"
                        show={ShowrepliesModel}
                        onHide={handleCloseRepliesModel}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>

                        </Modal.Header>
                        <Modal.Body>
                            <Card className='profile-img-follow'>
                                <Card.Img variant="middle" src={require('../../../assets/images/profile.png')} />
                                <Card.Body>
                                    <Card.Title className='auther-name'>{repliesData?.author?.userName}</Card.Title>
                                    <Card.Text className="mb-2 text-muted">
                                        {repliesData?.text}</Card.Text>
                                    <Card.Subtitle className="text-muted">
                                        <span>Replying to <a href='#'>{repliesData?.author?.userName}</a></span>
                                    </Card.Subtitle>
                                </Card.Body>
                            </Card>
                            <div className="comment-popup">
                                <Form.Group className="mb-3 d-flex align-items-start">
                                    <img src={require('../../../assets/images/profile.png')} />
                                    <Form.Control
                                        value={comments}
                                        onChange={handleNewComment} as="textarea" placeholder="Write something here" rows={3} />
                                </Form.Group>
                                <div className="submit-btn-comment">
                                    <Button type="submit" onClick={(() => handleReplyCommentSubmit(repliesData))}
                                    >Submit</Button>
                                </div>
                            </div>
                            {/* </Form> */}
                        </Modal.Body>

                    </Modal>

                    <Modal
                        className="comment--modal"
                        show={openConsultModel}
                        onHide={handleCloseConsultModel}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    
                    >
                        <Modal.Header className="px-3 pt-3" closeButton>
                            <h2>Book Consultation</h2>
                        </Modal.Header>
                        <Modal.Body>
                            <BookConsultModel 
                                userId= {authorDetail}
                                setOpenConsultModel= {handleCloseConsultModel}
                            />
                        </Modal.Body>

                    </Modal>
                </>
            }
        </>
    )
}

export default SinglePost
