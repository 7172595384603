import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import { authHeader } from '../../helpers/auth'
import {
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILED,
  GET_POST_SUCCESS,
  GET_POST_FAILED,
  GET_SINGLE_POST_SUCCESS,
  GET_SINGLE_POST_FAILED,
  GET_POST_LOGGED_OUT_SUCCESS,
  GET_POST_LOGGED_OUT_FAILED,
  GET_SEARCH_POST_SUCCESS,
  GET_SEARCH_POST_FAILED,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILED,
  GET_SEARCH_POST_LOGGED_OUT_SUCCESS,
  GET_SEARCH_POST_LOGGED_OUT_FAILED,
  TAGS_COUNT_SUCCESS,
  TAGS_COUNT_FAILED,
  USER_SUGGESTION_LIST_SUCCESS,
  USER_SUGGESTION_LIST_FAILED,
  POST_TOP_IDEAS_LIST_SUCCESS,
  POST_TOP_IDEAS_LIST_FAILED,
  AUTH_COMMON_ERROR,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILED,
  POST_COMMON_ERROR
} from "./types";


const config = {
  headers: {
    'Content-Type': "application/json; charset=utf-8",
    "Authorization": authHeader()
  }
}

function errorRequest(err, dispatch) {
  let data = get(err, 'response.data', null);
  data = data || get(err, 'response');
  data = data || err;
  if (data.error) {
    dispatch({
      type: POST_COMMON_ERROR,
      payload: data.error.message,
    });
  } else if (data.status === 402) {   // used for company -> unpaid subscription 
    auth.logout()
  } else {
    dispatch({
      type: POST_COMMON_ERROR,
      payload: data.message,
    });
  }
}


export const createPostFessds = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPost('postfeeds', payload);
      // console.log('response',response)

      if (response.data && response.data.success) {
        await dispatch({ type: CREATE_POST_SUCCESS, payload: response.data });
      } else {
        await dispatch({ type: CREATE_POST_FAILED, payload: response.data.message });
      }
    } catch (err) {
      // console.log('err',err)
      errorRequest(err, dispatch);
    }
  };
}

/** ----- Delete Posts Logged In User ----- */
// export const deletePost = (id) => {
//   return async (dispatch) => {
//     try {
//       const response = await API.apiDeleteUrl('post', `/${id}`);
//       if (response.data && response.data.success) {
//         await dispatch({ type: DELETE_POST_SUCCESS, payload: response.data });
//       } else {
//         await dispatch({ type: DELETE_POST_FAILED, payload: response.data.message });
//       }
//     } catch (err) {
//       // console.log('err',err)
//       errorRequest(err, dispatch);
//     }
//   };
// }

export const ListPostFeedData = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('postfeeds', payload, config);
      // console.log('response',response)
      if (response.data && response.data.success) {
        await dispatch({ type: GET_POST_SUCCESS, payload: response.data })
      } else {
        await dispatch({ type: GET_POST_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const ListSearchPostFeedData = (searchTerm) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet(`postfeedsearch`, `${searchTerm}`, config);
      if (response.data && response.data.success) {
        await dispatch({ type: GET_SEARCH_POST_SUCCESS, payload: response.data })
      } else {
        await dispatch({ type: GET_SEARCH_POST_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const ListPostFeedDataLoggedOut = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('postfeedloggedout', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: GET_POST_LOGGED_OUT_SUCCESS, payload: response.data })
      } else {
        await dispatch({ type: GET_POST_LOGGED_OUT_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const getOneFeedPost = (feedId) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('postfeeds', `/${feedId}`);
      // console.log('response',response)
      if (response.data && response.data.success) {
        await dispatch({ type: GET_SINGLE_POST_SUCCESS, payload: response.data });
      } else {
        await dispatch({ type: GET_SINGLE_POST_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}

export const ListTagsFeed = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('tags', payload);
      // console.log('response 123',response)
      if (response.data && response.data.response) {
        await dispatch({ type: GET_TAGS_SUCCESS, payload: response.data })
      } else {
        await dispatch({ type: GET_TAGS_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const ListTagsCountFeed = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('tagscount', payload);
      // console.log('response 12344',response)
      if (response.data && response.data.response) {
        await dispatch({ type: TAGS_COUNT_SUCCESS, payload: response.data })
      } else {
        await dispatch({ type: TAGS_COUNT_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const SearchPostLoggedOut = (searchTerm) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet(`postSearchloggedOut`, `${searchTerm}`);
      // console.log('response 151',response)
      if (response.data && response.data.success) {
        await dispatch({ type: GET_SEARCH_POST_LOGGED_OUT_SUCCESS, payload: response.data })
      } else {
        await dispatch({ type: GET_SEARCH_POST_LOGGED_OUT_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const userSuggestionsList = (payload) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('users', payload);
        // console.log('response 103',response)
        if (response.data && response.data.response) {
          await dispatch({ type: USER_SUGGESTION_LIST_SUCCESS, payload: response.data })
        } else {
          await dispatch({ type: USER_SUGGESTION_LIST_FAILED, payload: response.data.message });
        }
      } catch (error) {
        errorRequest(error, dispatch);
      }
    }
  }

export const ListTopPosts = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('topideas', payload);
      // console.log('response 103',response)
      if (response.data && response.data.response) {
        await dispatch({ type: POST_TOP_IDEAS_LIST_SUCCESS, payload: response.data })
      } else {
        await dispatch({ type: POST_TOP_IDEAS_LIST_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}