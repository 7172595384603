import React, { Component, useState } from 'react'
import { Row, Col, ListGroup, Container, Card, Button, Modal, Spinner } from 'react-bootstrap';
import { BsCamera, BsUpload, BsLinkedin, BsGithub, BsTwitter } from 'react-icons/bs';
import { Link } from 'react-router-dom'
import { AiOutlineCalendar, AiOutlineMail } from 'react-icons/ai';
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi';
import {RiFileList2Line} from 'react-icons/ri';
import { connect } from 'react-redux';
import SidebarLogged from '../PostFeed/SidebarLogged';
import SidebarFollow from '../PostFeed/FollowSidebar';
import { getUserProfile, getAllSpecialities } from '../../redux/actions/user.action';
import * as Auth from '../../helpers/auth';
import toast from 'react-hot-toast';
import { withRouter } from '../../wrapper/withRouter';
import BookConsultModel from '../BookConsultModel';

class UserProfile extends Component{
    constructor(props){
        super(props);
        this.state = {
            profile:{},
            loader: true,
            loggedUserId: null,
            userId: props.params.id,
            openConsultModel: false , 
        }
    }

    componentDidMount = async () => {
        await this.getLoggedUser();
        // await this.getSpecialities();
        await this.getUserProfile();
    }

    getLoggedUser = async () => {
        const userData = Auth.decodedLoggedUser();
        this.setState({
            loggedUserId: userData.aud
        })
    }

    // getSpecialities = async () => {
    //     await this.props.getAllSpecialities();
    //     console.log( 'special', this.props.specialities )
    //     if(this.props.specialities.success){
    //         this.setState({ specialities: this.props.specialities.specialities })
    //     }
    // }

    getUserProfile = async () => {
        try {
            await this.props.getUserProfile(this.state.userId)
            this.setState({ loader: false })
            if(this.props.profile && this.props.profile.success){
                this.setState({ profile: this.props.profile.profile })
            }
        } catch (error) {
            this.setState({ loader: false })
            toast.error(error.message);
        }
    }


    getProfileName = () => {
        let name = ''
        if(this.state.profile.firstName){
            name = this.state.profile.firstName
        }

        if(this.state.profile.lastName){
            name += ' ' + this.state.profile.lastName
        }

        return name
    }

    checkObjEmpty = (object) => {
        return !Object.values(object).some(x => (x !== null && x !== ''));
    }

    handleCloseConsultModel = () =>{
       this.setState({openConsultModel:false})
    }

    componentDidUpdate(prevProps, prevState) {
        if (
          this.props.params.id !== prevProps.params.id
        ) {
            this.setState({ userId: this.props.params.id }, () => this.getUserProfile());
        }
      }

    render(){
        const { profile, loader, loggedUserId , userId} = this.state;
        // console.log( profile )
        return (
            <>
                <div className='logged--screen profile--page'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col sm={12} lg={3} className='menu--sidebar'>
                                <SidebarLogged />
                            </Col>
                           {loader 
                           ? <Col sm={12} lg={6} className='section--content'>
                                <div className='text-center spinner--loader'><Spinner /></div>
                             </Col>
                           : <Col sm={12} lg={6} className='section--content'>
                                <div className='profile--box'>
                                    <Card>
                                        <Card.Body>
                                            <label for='upload--image'>
                                                {/* <Form.Control type="file" id='upload--image' hidden /> */}
                                                <img src={profile.profile ? profile.profile : require('../../assets/images/profile.png')} alt='Ideas.cab' />
                                                {/* <span><BsCamera /></span> */}
                                            </label>
                                            <Card.Title>{this.getProfileName()}</Card.Title>
                                            <Card.Text>{profile.userName}</Card.Text>
                                        </Card.Body>
                                        <div className='button--group'>
                                            {userId === loggedUserId && (<Link className='btn btn-light' to='/profile/me'><HiOutlineArrowNarrowLeft/> Continue Editing</Link>)}
                                            {userId !== loggedUserId && (<Button variant='primary' onClick ={()=>this.setState({openConsultModel:true})}><AiOutlineCalendar/> Book Consult</Button>)}
                                            <Button variant='outline-primary'><AiOutlineMail/> Message</Button>
                                            <Button variant='outline-primary'><BsUpload/> Share</Button>
                                        </div>
                                    </Card>
                                </div>
                                <div className='feed--listing feed--cards'>
                                   {(profile.linkedAccount?.linkedIn || profile.linkedAccount?.github || profile.linkedAccount?.twitter) &&
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>
                                                View { this.getProfileName() }
                                            </Card.Title>
                                            <ListGroup>
                                                {profile.linkedAccount?.linkedIn && <ListGroup.Item onClick={() => window.open(profile.linkedAccount.linkedIn, '_blank')}><BsLinkedin/> LinkedIn</ListGroup.Item>}
                                                {profile.linkedAccount?.github && <ListGroup.Item onClick={() => window.open(profile.linkedAccount.github, '_blank')}><BsGithub/> Github </ListGroup.Item>}
                                                {profile.linkedAccount?.twitter && <ListGroup.Item onClick={() => window.open(profile.linkedAccount.twitter, '_blank')}><BsTwitter/> Twitter </ListGroup.Item>}
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>}
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>
                                                About
                                            </Card.Title>
                                            <Card.Text>
                                                {(profile.bio && profile.bio.description) 
                                                    ?   <pre>
                                                            {profile.bio.description}
                                                        </pre>
                                                    :   <p>Bio Not Available</p>}
                                            </Card.Text>
                                            {/* <ListGroup className='mb-2'>
                                                <ListGroup.Item><RiFileList2Line/> Resume</ListGroup.Item>
                                            </ListGroup> */}
                                           { (profile.bio && profile.bio.resumeOrPortfolio)  
                                            ?   <ListGroup className='mb-2'>
                                                    <ListGroup.Item onClick={() => window.open(profile.bio.resumeOrPortfolio, '_blank')} ><RiFileList2Line/> Resume</ListGroup.Item>
                                                </ListGroup>
                                            :   null}
                                            {/* <ListGroup>
                                                <ListGroup.Item><BsGlobe/> Portfolio</ListGroup.Item>
                                            </ListGroup> */}
                                        </Card.Body>
                                    </Card>
                                    <Card className='speci--card'>
                                        <Card.Body>
                                            <Card.Title>
                                                Specialty
                                            </Card.Title>
                                           {(profile && profile.speciality.length )
                                            ?   <ListGroup>
                                                {profile.speciality.map((item, itemIndex) => {
                                                    return (
                                                        <ListGroup.Item key={itemIndex}>{item}</ListGroup.Item>
                                                    )
                                                })}
                                              
                                                {/* <ListGroup.Item>CSS</ListGroup.Item>
                                                <ListGroup.Item>Python</ListGroup.Item>
                                                <ListGroup.Item>Web Development</ListGroup.Item>
                                                <ListGroup.Item>Mobile Development</ListGroup.Item>
                                                <ListGroup.Item>React</ListGroup.Item> */}
                                            </ListGroup> :
                                            <p>Not Available</p>}
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>
                                                Experience
                                            </Card.Title>
                                            {(profile && profile.experience) ?
                                            <div>
                                                <h6>Work History</h6>
                                               {(profile?.experience && profile?.experience?.workHistory.length)
                                               ? <ul>
                                                    {profile?.experience?.workHistory.map((wh, whIndex) => {
                                                        return (
                                                            <li key={`work_history_${whIndex}`}>{wh}</li>
                                                        )
                                                    })}
                                                </ul>
                                                : <p>Not Available</p>}

                                                <h6>Education</h6>
                                                {(profile?.experience && profile?.experience.education.length )
                                                ?   <ul>
                                                        {profile?.experience?.education.map((edu, eduIndex) => {
                                                                return (
                                                                    <li key={`education_${eduIndex}`}>{edu}</li>
                                                                )
                                                        })}
                                                    </ul>
                                                : <p>Not Available</p>}

                                                <h6>Experience Level</h6>
                                                {['entry-level', 'mid-level', 'expert-level'].includes(profile?.experience.level) 
                                                ?<ul>
                                                    {profile?.experience.level === 'entry-level'  && <li><strong>Entry level</strong> 0-2 years experience in this field</li>}
                                                    {profile?.experience.level === 'mid-level' && <li><strong>Mid level</strong> 3-5 years experience in this field</li>}
                                                    {profile?.experience.level === 'expert-level' && <li><strong>Expert level</strong> 6+ years experience in this field</li>}
                                                </ul>
                                                : <p>Not Available</p>}
                                            </div> 
                                            : <h5>No Experience Record Available</h5>

                                            }
                                            {/* <ul>
                                                <li><strong>Mid level</strong> 3-5 years experience</li>
                                                <li><strong>XYZ Co.</strong> April 2020 - present</li>
                                                <li><strong>Acme College</strong> 2015 - 2019</li>
                                            </ul> */}
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className='rate--title'>
                                                Schedule + Rates
                                                {userId !== loggedUserId && (<Button type='button' variant='primary' onClick ={()=>this.setState({openConsultModel:true})}><AiOutlineCalendar/> Book Consult</Button>)}
                                            </Card.Title>
                                            {(profile && profile.scheduleRates)
                                            ?<Card.Text>
                                                    {Object.entries(profile.scheduleRates).map(([key, value]) => (
                                                        value.status ? <p>{key == 1 ? `1 Hour` : `${key} minutes`} - ${value.value}</p> : null
                                                    ))}
                                            </Card.Text>
                                            : <p>Not Available</p>}
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>}
                            <Col sm={12} lg={3} className='rightFeed--sidebar'>
                                <SidebarFollow />
                            </Col>
                        </Row>

                        <Modal
                            className="comment--modal"
                            show={this.state.openConsultModel}
                            onHide={this.handleCloseConsultModel}
                            backdrop="static"
                            keyboard={false}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header className="px-3 pt-3" closeButton>
                                <h2>Book Consultation</h2>
                            </Modal.Header>
                            <Modal.Body>
                                <BookConsultModel 
                                    userId= {this.state.profile}
                                    setOpenConsultModel= {this.handleCloseConsultModel}
                                />
                            </Modal.Body>
                        </Modal>
                    </Container>
                </div>
            </>
        )
    }
}

const maopStateToProps = (state) => {
    // console.log( state )
    return {
        specialities: state.users.specialities,
        profile:  state.users.profile,
        error: state.users.error
    }
}

const dispatchActions = { getUserProfile, getAllSpecialities }
export default connect(maopStateToProps, dispatchActions)(withRouter(UserProfile));