import { initializeApp } from "firebase/app";
// import { GoogleLogin } from "../../../redux/actions/auth.actions";
// import { useSelector, useDispatch } from "react-redux";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import toast from "react-hot-toast";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
// console.log( firebaseConfig )

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
    // console.log( ' SignIn Google' )
    signInWithPopup(auth, provider)
        .then(async (result) => {
            if (result) {
                fetch(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_VERSION}/auth/google/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: result.user.email,
                        // email: 'johndoe@example.com'
                    })
                })
                    .then(response => response.json())
                    .then((data) => {
                        // console.log('data',data)
                        localStorage.setItem('accessToken', data.accessToken);
                        window.location.replace('/')
                    })
                    .catch(error => toast.error(error.message || 'Unable to proceed your request, try later'))

            }
        })
        .catch((error) => {
            // console.log(error);
            toast.error(error.message || 'Unable to proceed your request, try later')
        });
};