import React, { useEffect, useState } from 'react';
import Lightbox from 'react-spring-lightbox';
import { Header, ArrowButton, ImageOverlay } from './components';


const LightboxComponent = (props) => {

    /** --- Light Box State --- */
    const [imageIndex, setImageIndex] = useState(0)
    const [ images, setImages ] = useState(props.images)

    const gotoPrevious = () =>  imageIndex > 0 && setImageIndex(imageIndex - 1);

    const gotoNext = () => imageIndex + 1 < images.length &&  setImageIndex(imageIndex + 1);
    
    useEffect(() => {
        setImages(props.images)
    }, [props.images])
    
    return (
        <Lightbox
            isOpen={props.isOpen}
            onPrev={gotoPrevious}
            onNext={gotoNext}
            images={images}
            currentIndex={imageIndex}
            /* Add your own UI */
            renderHeader={() => (
                <Header
                    // galleryTitle="Dark Mode: OS Level Control In Your CSS"
                    images={images}
                    currentIndex={imageIndex}
                    onClose={() => props.onClose()}
                />
            )}
            // renderFooter={() => (<CustomFooter />)}
            // renderPrevButton={() => (<CustomLeftArrowButton />)}
            // renderNextButton={() => (<CustomRightArrowButton />)}
            // renderImageOverlay={() => (<ImageOverlayComponent >)}
            renderPrevButton={({ canPrev }) => (
                <ArrowButton
                  position="left"
                  onClick={gotoPrevious}
                  disabled={!canPrev}
                />
              )}
              renderNextButton={({ canNext }) => (
                <ArrowButton position="right" onClick={gotoNext} disabled={!canNext} />
              )}
              renderImageOverlay={() => <ImageOverlay />}

            /* Add styling */
            // className="cool-class"
            style={{ background: "#000" }}

            /* Handle closing */
            onClose={() => props.onClose()}

            /* Use single or double click to zoom */
            // singleClickToZoom

            /* react-spring config for open/close animation */
            // pageTransitionConfig={{
            //   from: { transform: "scale(0.75)", opacity: 0 },
            //   enter: { transform: "scale(1)", opacity: 1 },
            //   leave: { transform: "scale(0.75)", opacity: 0 },
            //   config: { mass: 1, tension: 320, friction: 32 }
            // }}
        />
    )
}

export default LightboxComponent