
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { privateRoutes, publicRoutes } from './routes/index';
import { Routes, Route, useLocation } from "react-router-dom";
import Sidebar from "./components/sidebar";
import * as auth from './helpers/auth';
import toast from "react-hot-toast";
import {socket} from './helpers/auth'
import {CREATE_LIST_COMMENT,DELETE_COMMENT,LIKE_POST,DISLIKE_POST, DELETE_POST_SUCCESS, DELETE_POST_FAILED} from "./redux/actions/types" 
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-pro-sidebar/dist/css/styles.css";
// import 'react-image-lightbox/style.css';
import './App.css';

class App extends Component { 
  constructor(props){
    super(props)

    this.state = {

    }
  }

  componentDidMount(){  
    socket.on('comment_received', data => {
      if(data.success){
        this.props.createComment(data);
      }else{
        toast.error(data.error);
      }
    });

    socket.on('comment_deleted', (data) => {
      if (data.success) {
        // console.log('deletedata',data)
        this.props.deleteComment(data);
      } else {
        toast.dismiss();
        toast.error(data.error);
      }
    });

    socket.on('LikePost', (data) => {
        if( data.success ){
          this.props.likePost(data);
        }else{
          toast.dismiss()
          toast.error(data.error);
        }
    })

    socket.on('disLikePost', data => {
      // console.log( 'Dislike Data ---->>>',  data )
      if( data.success ){
        this.props.dislikePost(data);
      }else{
        toast.dismiss()
        toast.error(data.error);
      }
    })

    socket.on('deletePost', data => {
      // console.log('Post Deleted Response ::',  data )
      if(data.success){
        this.props.deletePost(data);
      }else{
        toast.dismiss()
        toast.error(data.error);
        this.props.deletePostError(data);
      }
    })

  }


   getRoutes = (routes, _privateRoute) =>
    routes.map((route) => {
      if (route.collapse) {
        return this.getRoutes(route.collapse, _privateRoute);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    render(){
      return (
        <div className="App">
          <Toaster />
          {/* <Sidebar  routes={publicRoutes}/> */}
          {this.props.loggedIn ? 
            <section className="dashContent">
              <Routes>
                {this.getRoutes(privateRoutes)}
              </Routes>
            </section>
            :
            <Routes>
              {this.getRoutes(publicRoutes)}
            </Routes>
          }
        </div>
      );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    createComment: async payload => { await dispatch({ type: CREATE_LIST_COMMENT, payload }) },
    // createComment: async payload => { await dispatch({ type: CREATE_POST_LIST_COMMENT, payload }) },
    deleteComment: async payload => { await dispatch({ type: DELETE_COMMENT, payload }) },
    likePost: async payload => { await dispatch({ type: LIKE_POST, payload }) },
    dislikePost: async payload => { await dispatch({ type: DISLIKE_POST, payload }) },
    deletePost: async payload => { await dispatch({ type: DELETE_POST_SUCCESS, payload}) },
    deletePostError: async payload => { await dispatch({ type: DELETE_POST_FAILED, payload }) }
  }
}

const mapStateToProps = state => {
  const { loggedIn } = state.auth;
  // console.log('loggedIn',loggedIn)
  return { loggedIn }
}
export default connect( mapStateToProps, mapDispatchToProps )( App );