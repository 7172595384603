import React, { useState, useEffect, useRef } from 'react'
import { Nav, Card, Button, Form, Modal } from 'react-bootstrap';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from "react-pro-sidebar";
import { GrClose } from "react-icons/gr";
import { BsPostcard } from 'react-icons/bs';
import { FaHashtag, FaRegUser, FaEllipsisH } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BiHomeAlt2, BiEnvelope } from 'react-icons/bi';
import { FiBell, FiLogIn } from "react-icons/fi";
import { IoEllipsisHorizontalCircle } from 'react-icons/io5';
import { logout } from '../../redux/actions/auth.actions'
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from 'jwt-decode';
import CreatePostFeed from './CreatePostFeed';

const SidebarLogged = (props) => {
    const [show, setShow] = useState(false);
    const [createPostShow, setCreatePost] = useState(false);
    const [createPostToasterShow, setcreatePostToasterShow] = useState(false);
    const target = useRef(null);
    const auth = useSelector(state => state.auth);

    const { routes } = props;
    const [menuCollapse, setMenuCollapse] = useState(false)
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    const activeRoute = (menu) => {
        let route = pathname
        const path = [route.split('/').join('')]
        const result = path.some((name) => name.match(menu))
        return result
    }

    const activeSubRoute = (route) => {
        return route === pathname

    }

    // const dispatch = useDispatch();
    const [tokenData, setTokenData] = useState('');

    useEffect(() => {
        if (auth.loggedIn) {
            let token = localStorage.getItem('accessToken')
            let DecodedToken = jwt_decode(token)
            setTokenData(DecodedToken)
        }
    }, [])

    const openCreateIdeaModal = () => {
        setCreatePost(true)
        setcreatePostToasterShow(true)
        if(props.handleDisplayCreatePost){
            props.handleDisplayCreatePost(true) // hide on Close
        }
    }

    const handleOnHideCreatePostModal = () => {
        setCreatePost(false)
        if(props.handleDisplayCreatePost){
            props.handleDisplayCreatePost(false) // Do not hide on close
        }
    }
    
    return (
        <div className='logged--sidebar'>
            <div className='ideas--logo mobile--logo'>
                <Link to='/home'>
                    {window.location.pathname === '/home'
                        ?   <img className='logo--full' src={require('../../assets/images/ideas-logo-full.png')} alt='Ideas.cab' />
                        :   <img className='logo--small' src={require('../../assets/images/ideas-logo-icon.png')} alt='Ideas.cab' />
                    } 
                </Link>
            </div>
            <ProSidebar collapsed={menuCollapse}>
                <SidebarHeader>
                    <div className='ideas--logo'>
                        <Link to='/home'>
                            {window.location.pathname === '/home'
                                ?   <img className='logo--full' src={require('../../assets/images/ideas-logo-full.png')} alt='Ideas.cab' />
                                :    <img className='logo--small' src={require('../../assets/images/ideas-logo-icon.png')} alt='Ideas.cab' />
                            }                           
                        </Link>
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu activeKey="/home">
                        <MenuItem>
                            <Link to='/home' className={window.location.pathname == "/home" ? "active" : null}>
                                <div><BiHomeAlt2 /> Home</div>
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/explore' className={window.location.pathname == "/explore" ? "active" : null}>
                                <div><FaHashtag /> Explore</div>
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/comming-soon-notifications' className={window.location.pathname == "/comming-soon-notifications" ? "active" : null}>
                                <div eventKey="Notifications"><FiBell /> Notifications</div>
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            {/* <Nav.Link eventKey="messages"><BiEnvelope /> Messages</Nav.Link> */}
                            <Link to='/comming-soon-Messages' className={window.location.pathname == "/comming-soon-Messages" ? "active" : null}>
                                <div eventKey="messages"><BiEnvelope /> Messages</div>
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/profile/me' className={window.location.pathname == "/profile/me" ? "active" : null}>
                                <div><FaRegUser /> Profile</div>
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to='/comming-soon-more' className={window.location.pathname == "/comming-soon-more" ? "active" : null}>
                                <div eventKey="more"><IoEllipsisHorizontalCircle /> More</div>
                            </Link>
                            {/* <Nav.Link eventKey="more"><IoEllipsisHorizontalCircle /> More</Nav.Link> */}
                        </MenuItem>
                        <MenuItem className='post--idea'>
                            <Nav.Link eventKey="createIdea" onClick={() => openCreateIdeaModal()}> Post a Question</Nav.Link>
                        </MenuItem>
                        {/* <MenuItem>
                            <Nav.Link onClick={() => dispatch(logout())}><FiLogIn /> Logout</Nav.Link>
                        </MenuItem> */}
                    </Menu>
                </SidebarContent>
                <SidebarFooter>
                    <Card className='profile-img-follow'>
                        <span className='user--avatar me-0'>
                            <span className={`name--initial nm-${tokenData?.userName?.split('')[0]}`}>{tokenData?.userName?.split('')[0]}</span>
                        </span>
                        {/* <Card.Img variant="middle" src={require('../../assets/images/profile.png')} /> */}
                        <Card.Body>
                            <Card.Title className='auther-name'>{tokenData.userName}</Card.Title>
                            <Card.Text>@{tokenData.userName}</Card.Text>
                        </Card.Body>
                        <span><FaEllipsisH /></span>
                    </Card>
                    <div className='hover--tooltip'>
                        <span>
                            <Nav.Link onClick={() => dispatch(logout())}>Logout @{tokenData.userName}</Nav.Link>
                        </span>
                    </div>
                </SidebarFooter>
            </ProSidebar>
            <div className="closemenu" onClick={menuIconClick}>
                {menuCollapse ? (
                    <GrClose />
                ) : (
                    <GiHamburgerMenu />
                )}
            </div>
            <Modal
                className="comment--modal"
                show={createPostShow}
                onHide={() => handleOnHideCreatePostModal()}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                style={{ width:'100%', height:'600px' }}
                // centered
            >
                <Modal.Header closeButton />
                {/* <Modal.Body> */}
                <CreatePostFeed 
                    className={{ removeTopborder: 'type--feed--post--modal' }} 
                    sideBarAccess={true} 
                    setCreatePost={setCreatePost}
                    createPostToasterShow={createPostToasterShow}
                 />
                {/* </Modal.Body> */}
            </Modal>
        </div>
    )
}

export default SidebarLogged