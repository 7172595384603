import {
    CONNECT_STRIPE_ACCOUNT_SUCCESS,
    CONNECT_STRIPE_ACCOUNT_FAILED,
    ONBOARDING_SUCCESS,
    ONBOARDING_FAILED,
    STRIPE_COMMON_ERROR,
    TRANSFER_FUNDS_SUCCESS,
    TRANSFER_FUNDS_FAILED,
    GET_STRIPE_DETAIL_SUCUESS,
    GET_STRIPE_DETAIL_FAILED,
    UPDATE_TRANSACTION_STATUS_SUCUESS,
    UPDATE_TRANSACTION_STATUS_FAILED,
    GET_TRANSACTION_HISTORY_SUCCESS,
    GET_TRANSACTION_HISTORY_FAILED
}from "../../redux/actions/types";;

const initialState = {
    accountCreated: null,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type){
        case CONNECT_STRIPE_ACCOUNT_SUCCESS:
            return {
                redirectUrl:action.payload,
                ...state
            }
        case CONNECT_STRIPE_ACCOUNT_FAILED:
            return {
                error:action.payload,
                ...state,
            }
        case ONBOARDING_SUCCESS:
            return{
                completeOnBoarding:action.payload,
                ...state,
            }    
        case  ONBOARDING_FAILED:
            return{
                onBoardError:action.payload,
                ...state,
            }
        case TRANSFER_FUNDS_SUCCESS:
            return {
                transferFundsUrl: action.payload,
                ...state,
            }     
        case TRANSFER_FUNDS_FAILED:
            return {
                error:action.payload,
                ...state,
            }         
        case STRIPE_COMMON_ERROR:
            return {
                error: action.payload,
                ...state,
            }
        case  GET_STRIPE_DETAIL_SUCUESS: 
            return{
                stripeDetails: action.payload,
                ...state,
            }
        case  GET_STRIPE_DETAIL_FAILED: 
            return{
                error: action.payload,
                ...state,
            }    
        case UPDATE_TRANSACTION_STATUS_SUCUESS:
            return{
                updateTransactionStatus: action.payload,
                ...state,
            }
        case UPDATE_TRANSACTION_STATUS_FAILED:
            return{
                error: action.payload,
                ...state,
            } 
        case GET_TRANSACTION_HISTORY_SUCCESS:
            return{
                transactionHistory: action.payload,
                ...state
            }   
        case GET_STRIPE_DETAIL_FAILED:
            return{
                error: action.payload,
                ...state,
            } 
        default: return state;
    }
}