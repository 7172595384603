import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import SidebarLogin from './LoginSidebar';
import PostFeedsLoggedOut from './PostListingLoggedOut';
import SidebarLoggedOut from './SidebarLoggedOut';
import SidebarLogged from './SidebarLogged';
import PostFeeds from './PostFeedsOut'
import SidebarFollow from './FollowSidebar'
import Footer from './Footer'

const LoggedOut = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.auth)
    const [showSignUpModel, setshowSignUpModel] = useState(false);
    const [showLoginModel, setshowLoginModel] = useState(false);
    const [hideCreatePostOnHome, setCreatePostOnHome] = useState(false);


    const handleClose = () => setshowSignUpModel(false);
    const handleShow = () => {
        setshowSignUpModel(true);
        setshowLoginModel(false);
    }

    const handleCloseLoginModel = () => setshowLoginModel(false);
    const handleShowLoginModel = () => {
        setshowLoginModel(true);
        setshowSignUpModel(false);
    }

    const handleDisplayCreatePost = (val) => {
        setCreatePostOnHome(val)
    }

    return (
        <>
            <div className='logged--screen'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col sm={12} md={3} xl={3} className='menu--sidebar'>
                            {state.loggedIn ? <SidebarLogged handleDisplayCreatePost={handleDisplayCreatePost}/> : <SidebarLoggedOut />}
                        </Col>
                        <Col sm={12} md={6} xl={6} className='section--content'>
                            {state.loggedIn ? <PostFeeds hideCreatePost={hideCreatePostOnHome} /> : <PostFeedsLoggedOut />}
                        </Col>
                        <Col sm={12} md={3} xl={3} className='rightFeed--sidebar'>
                            {state.loggedIn ? <SidebarFollow /> : <SidebarLogin />}
                        </Col>
                    </Row>
                </Container>
            </div>
            {state.loggedIn 
                ? null
                : <Footer/>
            }
        </>
    )
}

export default LoggedOut