import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup, Card, Container, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { SearchPostLoggedOut } from '../../../redux/actions/post.action';
import { SendComment, DeleteComment, LikeComment, DisLikeComment, SendReplyComment, DeleteNestedComment, deletePost } from '../../../helpers/auth'
import jwt_decode from 'jwt-decode';
import { BiLike, BiTrash } from 'react-icons/bi';
import moment from 'moment';
import toast from "react-hot-toast";
import { FaRegCommentDots } from 'react-icons/fa';
import { TbCalendarTime } from 'react-icons/tb';
// import { MdDelete } from "react-icons/md";
import { AiTwotoneLike } from "react-icons/ai";
// import { AiFillDislike } from "react-icons/ai";
import { ThreeDots } from 'react-loader-spinner';
/** --- Light Box --- */
import LightboxComponent from "../../LightBoxComponent";

import { BsCalendar4 } from 'react-icons/bs';
import SidebarLogged from '../SidebarLogged';
import SidebarLoggedOut from '../SidebarLoggedOut';
import SidebarFollow from '../FollowSidebar';
import SidebarLogin from '../LoginSidebar';
import Footer from "../Footer";
import LoginModelOpen from "../../authentication/authModels/LoginModelOpen"
import * as authHelper from '../../../helpers/auth';

const HashAllPots = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id, slug } = useParams();
    // console.log('useParams', useParams())
    // console.log('hash',hash,slug)
    const [searchTerm, setSearchTerm] = useState('');
    const [activeId, setActiveId] = useState(null);
    const [comments, setComments] = useState([]);
    const [loader, setLoader] = useState(true);
    const [loaderListing, setloaderListing] = useState(false);
    const [ delLoader, setDelLoader ] = useState(null)
    const [PostFeedd, setPostFeed] = useState([]);
    const [showLoginModelAuth, setshowLoginModelAuth] = useState(false);
    const [showLoginFooterModelAuth, setshowLoginFooterModelAuth] = useState(false);
    const [showMessageAlert, setShowMessageAlert] = useState(false);
    /** --- Light Box State --- */
    const [isLightBoxOpen, setLightBox] = useState(false);
    const [ postMedia, setPostMedia ] = useState(null);

    const [displayMessage, setDisplayMessage] = useState('');
    const [userId, setuserId] = useState('');
    const apiError = useSelector(state => state.post.error);
    const auth = useSelector(state => state.auth);

    const postState = useSelector(state => state.post);
    const postFeed = useSelector(state => state.post.getPostFeed ? state.post.getPostFeed : state.post.getPostFeedloggedOut);

    const handlehashListPost = async () => {
        await dispatch(SearchPostLoggedOut(id));
    }

    useEffect(() => {
        (async () => {
            setloaderListing(true)
            await handlehashListPost()
        })();
    }, [id])

    useEffect(() => {
        (async () => {
            await handlehashListPost()
        })();
    }, [])

    useEffect(() => {
        if (auth.loggedIn) {
            let token = localStorage?.getItem('accessToken')
            let DecodedToken = jwt_decode(token)
            setuserId(DecodedToken?.aud)
        }
    }, [])
    
    useEffect(() => {
        if(postState.deletePostMessage){
            if(delLoader){
                toast.success('Deleted!')
            }
            setDelLoader(null)
        }

        if(postState.deletePostError){
            setDelLoader(null)
        }
    }, [postState.deletePostMessage, postState.deletePostError])
    /*
    const handleNewComment = (event) => {
        setComments(event.target.value);
    }

    const handleCommentSubmit = async (data) => {
        const newComment = { text: comments };
        SendComment(comments, data._id, userId);
        setComments([]);
        await handlehashListPost();
        setPostFeed([...postFeed, newComment]);
    };
    const handleReplyCommentSubmit = async (data) => {
        const newComment = { text: comments };
        SendReplyComment(comments, data.post, userId, data._id);
        setComments([]);
        await handlehashListPost();
        setPostFeed([...postFeed, newComment]);
    };

    const handleDelteComment = async (commentId, postId) => {
        DeleteComment(commentId, postId)
        setPostFeed([...postFeed]);
        await handlehashListPost();
    }
    const handleNestedDelteComment = async (commentId) => {
        DeleteNestedComment(commentId)
        setPostFeed([...postFeed]);
        await handlehashListPost();
    }

    const handleDisLike = async (data) => {
        setLoader(true)
        DisLikeComment(userId, data._id)
        await handlehashListPost();
        setPostFeed([...postFeed]);
        setLoader(false)
    }
    */

    const handleLike = async (data) => {
        setLoader(true)
        LikeComment(userId, data._id)
        await handlehashListPost();
        setPostFeed([...postFeed]);
        setLoader(false)
    }



    // const toggleAccordion = (id) => {
    //     setActiveId(activeId === id ? null : id);
    //     setComments([]);

    // }

    function getWordElement(word, index) {
        // function generateRandomString() {
        //     let result = '';
        //     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        //     for (let i = 0; i < 15; i++) {
        //         result += characters.charAt(Math.floor(Math.random() * characters.length));
        //     }
        //     return result;
        // }
        // const randomString = generateRandomString();

        if (word.startsWith("#")) {
            return (
                <span key={index} className="highlighted-keyword">
                    <Link 
                        to={`/post/feed/${word.substr(1)}`}
                        // href={`/post/feed/${word.substr(1)}/${randomString}`}
                    >
                        {word}{" "}
                    </Link>
                </span>
            );
        } else {
            return <span key={index}>{word} </span>;
        }
    }

    const HandleShowLoginModel =(message)=>{
        setshowLoginFooterModelAuth(true)
        setShowMessageAlert(true)
        setDisplayMessage(message)
    }

    /** --- Light Box Code  */
    const handleCloseLightBox = () => {
        setLightBox(false)
        setPostMedia(null)
    }

    const handleOpenLightBox = (media) => {
        let images = media.map(m => { return { src: m.secure_url, alt: 'Image' } });
        setPostMedia(images)
        setLightBox(true)
    }

    const handleDeletePost = (postId) => {
        setDelLoader(postId)
        deletePost(userId, postId);
    }

    return (
        <div className='Single-posts logged--screen'>
            {isLightBoxOpen && 
                <LightboxComponent
                    isOpen={isLightBoxOpen}
                    images={postMedia}
                    onClose={() => handleCloseLightBox()}
                />
            }
            <Container>
                <Row className="justify-content-center">
                    <Col sm={12} md={3} xl={3} className="menu--sidebar">
                        {auth.loggedIn ? <SidebarLogged /> : <SidebarLoggedOut />}
                    </Col>
                    <Col sm={12} md={6} xl={6} className="section--content">
                        <div className='feed--listing feed--cards pt-md-0 border-top-0 main-view-hash-post'>
                            <div class="singlePost--search d-flex align-items-center">
                            <Link to='/explore' class="arrow--back">
                                {/* <a class="arrow--back" href="/explore"> */}
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path></svg>
                                </Link>
                            </div>
                            {
                                loaderListing ?
                                    postFeed?.length > 0 ?
                                        postFeed.map((data, ind) => {
                                            return (<>
                                                <Card>
                                                    <span className='user--avatar'>
                                                        {/* <img src={require('../../assets/images/profile.png')} alt='...' /> */}
                                                        <span className={`name--initial nm-${data?.author?.userName.split('')[0]}`}>{data?.author?.userName.split('')[0]}</span>
                                                    </span>
                                                    <Card.Body>
                                                        <Card.Title className="title-post-feed">
                                                            <Card.Link>
                                                                <Link to={`/post/feeds/${data._id}`}>{data.title}</Link>
                                                            </Card.Link>
                                                            {(data?.author?._id === userId) && <span onClick={() => handleDeletePost(data._id)}> 
                                                                {(delLoader === data._id) ? <Spinner size="sm"/> : <BiTrash/> }
                                                            </span>}
                                                        </Card.Title>
                                                        <Card.Subtitle className="mb-3 text-muted">
                                                            <span><BsCalendar4 /> {moment(data.createdAt).fromNow()} <a className="auther-name">{data?.author?.userName}</a></span>
                                                        </Card.Subtitle>
                                                        <div className="mb-3">
                                                            {data?.description?.split(" ").map(getWordElement)}
                                                            {(data.media && data.media.length > 0) 
                                                                ?    <div className="post--feed--grid">
                                                                        {data.media.map((media, pictureIndex) => {
                                                                            return (
                                                                                <div className="post--feed--media">
                                                                                    {authHelper.checkImageExtension().includes(media.format) && (<img key={pictureIndex} src={media.secure_url} onClick={() => handleOpenLightBox(data.media)}/>) }
                                                                                    {authHelper.checkVideoExtension().includes(media.format) && (<video key={pictureIndex} src={media.secure_url} autoPlay={false} controls/>) }
                                                                                    {(!authHelper.checkImageExtension().includes(media.format) && !authHelper.checkVideoExtension().includes(media.format)) && <img key={pictureIndex} src={require('../../../assets/images/nip.png')} />}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                :   null
                                                            }
                                                        </div>
                                                        <ListGroup className="list-icons-main">
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={  
                                                                    <Tooltip id="button-tooltip-comment">
                                                                        Comment
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <ListGroup.Item>
                                                                    {/* <Link className="link-comment-icon" to={`/post/feeds/${data._id}/${data.slug}`}> */}
                                                                        <FaRegCommentDots /> {data?.comments?.length}
                                                                    {/* </Link> */}
                                                                </ListGroup.Item>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={  
                                                                    <Tooltip id="button-tooltip-like">
                                                                        {auth.loggedIn 
                                                                        ? data?.likes?.some(like => like?.author?._id === userId) ? 'Downvote' : 'Upvote'
                                                                        : 'Upvote'
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                {auth.loggedIn ?
                                                                <ListGroup.Item
                                                                    key={ind}
                                                                    className="likebtn"
                                                                    onClick={() => handleLike(data)}>
                                                                    {data?.likes?.some(like => like?.author?._id === userId) ? (
                                                                        <AiTwotoneLike />) : (<BiLike />)}
                                                                    {data?.likes?.length}
                                                                </ListGroup.Item>
                                                                : 
                                                                <ListGroup.Item onClick={()=> HandleShowLoginModel('Login to upvote')}><BiLike /> {data?.likes?.length}</ListGroup.Item>
                                                            }
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={  
                                                                    <Tooltip id="button-tooltip-sc">
                                                                        Schedule Consultation
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <ListGroup.Item><TbCalendarTime /></ListGroup.Item>
                                                            </OverlayTrigger>
                    
                                                        </ListGroup>

                                                        {/* <div className="accordion">
                                                            <div className="accordion-header-main"
                                                                onClick={() => toggleAccordion(data._id)}>
                                                                <FaRegCommentDots /> {data?.comments?.length}
                                                            </div>
                                                            {
                                                                auth.loggedIn &&
                                                                activeId === data._id &&
                                                                <div className="accordion-body-main">
                                                                    <Form.Group className="mb-3 border-commet" controlId="exampleForm.ControlTextarea1">
                                                                        <Form.Control
                                                                            value={comments}
                                                                            onChange={handleNewComment} as="textarea" placeholder="Write something hre" rows={3} />
                                                                    </Form.Group>
                                                                    <div className="submit-btn-comment">
                                                                        <Button type="submit" onClick={(() => handleCommentSubmit(data))}
                                                                        >Submit</Button>
                                                                    </div>
                                                                </div>}
                                                        </div> */}

                                                        {/* {data?.comments?.map((comment) => {
                                                            return (
                                                                <>
                                                                    <div className="comment-section-main">
                                                                        <div className="comment-img">
                                                                            <img src={require('../../../assets/images/profile.png')} alt='...' />
                                                                            <div>
                                                                                <h4 className="auther-name-commet">{comment.author?.userName ? comment.author?.userName : "NA"}</h4>
                                                                                <p><TbCalendarTime /> {moment(comment.createdAt).fromNow()} </p>
                                                                            </div>
                                                                            {comment.author?._id == userId &&
                                                                                <div className="delet-comment"
                                                                                    onClick={() => handleDelteComment(comment._id, data._id)}>
                                                                                    <MdDelete /></div>}
                                                                        </div>
                                                                        <div className="text-comment">
                                                                            <p className="mb-4">{comment.text}</p>
                                                                            <div className="accordion">
                                                                                <div className="accordion-header-main"
                                                                                    onClick={() => toggleAccordion(comment._id)}>
                                                                                    <FaRegCommentDots /> {comment?.replies?.length}
                                                                                </div>
                                                                                {
                                                                                    activeId === comment._id &&
                                                                                    <div className="accordion-body-main">
                                                                                        {auth.loggedIn &&
                                                                                            <>
                                                                                                <Form.Group className="mb-3 border-commet" controlId="exampleForm.ControlTextarea1">
                                                                                                    <Form.Control
                                                                                                        value={comments}
                                                                                                        onChange={handleNewComment} as="textarea" placeholder="Write something hre" rows={3} />
                                                                                                </Form.Group>
                                                                                                <div className="submit-btn-comment">
                                                                                                    <Button type="submit" onClick={(() => handleReplyCommentSubmit(comment))}>Submit</Button>
                                                                                                </div>
                                                                                            </>
                                                                                        }

                                                                                        {comment?.replies?.map((x) => {
                                                                                            return (<>
                                                                                                <div className="nested-comment">
                                                                                                    <div className="comment-img">
                                                                                                        <img src={require('../../../assets/images/profile.png')} alt='...' />
                                                                                                        <div>
                                                                                                            <h4 className="auther-name-commet">{x.author?.userName ? x.author?.userName : "NA"}</h4>
                                                                                                            <p><TbCalendarTime /> {moment(x.createdAt).fromNow()} </p>
                                                                                                        </div>
                                                                                                        {x.author?._id == userId &&
                                                                                                            <div className="delet-comment"
                                                                                                                onClick={() => handleNestedDelteComment(x._id)}>
                                                                                                                <MdDelete /></div>}
                                                                                                    </div>
                                                                                                    <div><p className="mb-4">{x.text}</p></div>
                                                                                                </div>
                                                                                            </>)
                                                                                        })}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })} */}

                                                    </Card.Body>
                                                </Card>
                                            </>)
                                        })
                                        : <h2 className="mt-5 text-center">Post Not Found</h2>

                                    :
                                    <div className="loader-main-list">
                                        <ThreeDots
                                            height="60"
                                            width="60"
                                            radius="9"
                                            color="#e44300"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                            }
                        </div>
                        {auth.loggedIn ? null :  <Footer/>}
                    </Col>
                    <Col sm={12} md={3} xl={3} className="rightFeed--sidebar">
                        {auth.loggedIn ? <SidebarFollow /> : <SidebarLogin />}
                    </Col>
                </Row>
            </Container>
            {showLoginFooterModelAuth &&
              <LoginModelOpen 
                setshowLoginFooterModelAuth={setshowLoginFooterModelAuth}
                showMessageAlert={showMessageAlert}
              />
            }
        </div>
        
    )
}

export default HashAllPots