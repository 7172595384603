import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  REQUEST_OTP_SUCCESS,
  REQUEST_OTP_FAILED,
  AUTH_COMMON_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  VERIFY_OTP_RESET_PASSWORD_SUCCESS,
  VERIFY_OTP_RESET_PASSWORD_FAILED,
  REQUEST_OTP_RESET_PASSWORD_SUCCESS,
  REQUEST_OTP_RESET_PASSWORD_FAILED,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAILED,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILED,
} from "./types";


const config = {
  headers: {
    'Content-Type': "application/json; charset=utf-8"
  }
}

function errorRequest(err, dispatch) {
  let data = get(err, 'response.data', null);
  data = data || get(err, 'response');
  data = data || err;
  // console.log( ' error --->>', data.message )
  if (data.error) {
    dispatch({
      type: AUTH_COMMON_ERROR,
      payload: data.error.message,
    });
  } else if (data.status === 402) {   // used for company -> unpaid subscription 
    auth.logout()
  } else {
    dispatch({
      type: AUTH_COMMON_ERROR,
      payload: data.message,
    });
  }
}

export const Login = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPost('login', payload);
      // console.log('response',response)
      /** -- If Response Success -- */
      if (response.data && response.data.success) {
        /** -- If Response with token -- */
        if (response.data.accessToken) {
          auth.login(response.data.accessToken);
          window.location.replace('/')
          await dispatch({ type: LOGIN_SUCCESS, payload: response.data });
        } else {
          await dispatch({ type: LOGIN_FAILED, payload: response.data.message });
        }
      } else {
        await dispatch({ type: LOGIN_FAILED, payload: response.data.message });
      }
    } catch (err) {
      errorRequest(err, dispatch);
    }
  };
}

export const CreateAccount = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPost('signup', payload);
      // console.log('response',response)
      if (response.data && response.data.success) {
        await dispatch({ type: CREATE_ACCOUNT_SUCCESS, payload: response.data });
      } else {
        await dispatch({ type: CREATE_ACCOUNT_FAILED, payload: response.data.message });
      }
    } catch (error) {
      // console.log('error',error)
      errorRequest(error, dispatch);
    }
  }
}

export const linkedInAuthentication = (code) => {
  return async (dispatch) => {
    try{
      // console.log( ' Linked In Auth Calling.....' )
      const response = await API.apiPostUrl('auth', `/linkedIn/login?code=${code}`)
      //  console.log('response',response.data)
      /** -- If Response Success -- */
      if (response.data && response.data.success) {
        /** -- If Response with token -- */
        if (response.data.accessToken) {
          auth.login(response.data.accessToken);
          // await dispatch({ type: SOCIAL_LOGIN_SUCCESS, payload: response.data });
        } 
        // else {
        //   await dispatch({ type: SOCIAL_LOGIN_FAILED, payload: response.data.message });
        // }
      } 
      // else {
      //   await dispatch({ type: SOCIAL_LOGIN_FAILED, payload: response.data.message });
      // }
    } catch (error){
      // console.log( ' error'  , error)
      errorRequest(error, dispatch)
    }
  }
}

export const verifyOtp = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPost('emailverifyOpt', payload);
      // console.log('response',response)
      if (response.data && response.data.message) {
        // console.log('response.data.message',response.data.message)
        await dispatch({ type: VERIFY_OTP_SUCCESS, payload: response.data });
      } else {
        await dispatch({ type: VERIFY_OTP_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}


export const requestOtp = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPost('otpRequest', payload);
      // console.log('otpRequest',response)
      if (response.data && response.data.message) {
        // console.log('response.data.message',response.data.message)
        await dispatch({ type: REQUEST_OTP_SUCCESS, payload: response.data });
      } else {
        await dispatch({ type: REQUEST_OTP_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}

export const requestOtpResetPassword = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPost('otpRequest', payload);
      // console.log('otpRequest',response)
      if (response.data && response.data.message) {
        // console.log('response.data.message',response.data.message)
        await dispatch({ type: REQUEST_OTP_RESET_PASSWORD_SUCCESS, payload: response.data });
      } else {
        await dispatch({ type: REQUEST_OTP_RESET_PASSWORD_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}


export const verifyOtpResetPassword = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPost('otpverify', payload);
      // console.log('response',response)
      if (response.data && response.data.message) {
        await dispatch({ type: VERIFY_OTP_RESET_PASSWORD_SUCCESS, payload: response.data });
      } else {
        await dispatch({ type: VERIFY_OTP_RESET_PASSWORD_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}

export const resetPassword = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPatch('resetPassword', payload);
      // console.log('response 153',response)
      if (response.data && response.data.success) {
        await dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: RESET_PASSWORD_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}


export const GoogleLogin = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPost('googlelogin', payload);
      if (response.data && response.data.success) {
        if (response.data.accessToken) {
          auth.login(response.data.accessToken);
          await dispatch({ type: GOOGLE_LOGIN_SUCCESS, payload: response.data });
        } else {
          await dispatch({ type: GOOGLE_LOGIN_FAILED, payload: response.data.message });
        }
      } else {
        await dispatch({ type: GOOGLE_LOGIN_FAILED, payload: response.data.message });
      }
    } catch (err) {
      errorRequest(err, dispatch);
    }
  };
}

export function logout() {
  return (dispatch) => {
    try {
      auth.logout();
    } catch (err) {
      errorRequest(err, dispatch);
    }
  };
}