import React, { useEffect, useState,useRef } from "react";
import { Form, Row, Col, ListGroup, Button, Card, Accordion } from 'react-bootstrap';
import { BiSearch, BiLike, BiDislike } from 'react-icons/bi';
import { AiFillCloseCircle } from 'react-icons/ai';
import { ListTagsFeed,SearchPostLoggedOut } from '../../redux/actions/post.action';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { notValid, emailValidation } from "../../utils/validations";
import toast from "react-hot-toast";
import { CiImageOn } from 'react-icons/ci';
import { TbCalendarTime } from 'react-icons/tb';

const SearchLoggedOutPostFeed = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [string, setstring] = useState('');
    const [textareaValue, setTextareaValue] = useState("");
    const [errortextareaValue, seterrortextareaValue] = useState("");
    const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
    const [showHashtagSuggestions, setShowHashtagSuggestions] = useState(false);
    const [handleSuggestionValue, sethandleSuggestionValue] = useState("");
    const [tagshashdata, settagshashdata] = useState([]);
    const searchbarRef = useRef(null);
    const postFeedCreate = useSelector(state => state.post);
    const tagsdata = useSelector(state => state.tags.tags);
    var timeout;

    useEffect(() => {
        (async () => {
            await handleTagsData()
        })();
    }, [])

    const handleTagsData = async () => {
        await dispatch(ListTagsFeed());
    }

    useEffect(() => {
        settagshashdata(tagsdata)
    }, [tagsdata])

    // useEffect(() => {
    //     handleSearch()
    // }, [textareaValue])

    useEffect(() => {
        props.handleSearchFeedLoader();
        dispatch(SearchPostLoggedOut(`${handleSuggestionValue?.replace(/#/g, "")}`))
    }, [handleSuggestionValue])

    const handleHashtagSelection = (suggestion) => {
        const lastWordMatch = textareaValue.match(/(\S+)$/);
        if (lastWordMatch) {
          const lastWordIndex = textareaValue.lastIndexOf(lastWordMatch[0]);
          setTextareaValue(textareaValue.slice(0, lastWordIndex) + suggestion);
          sethandleSuggestionValue(textareaValue.slice(0, lastWordIndex) + suggestion);
        } else {
          setTextareaValue((prevValue) => prevValue + suggestion);
          sethandleSuggestionValue((prevValue) => prevValue + suggestion);
        }
        setHashtagSuggestions([]);
        setShowHashtagSuggestions(false);
      };

      useEffect(() => {
        // Add event listener to detect clicks outside of search bar
        const handleClickOutside = (event) => {
          if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
            setShowHashtagSuggestions(false);
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [searchbarRef])


    const handleTextareaChange = (event) => {
        const { value } = event.target;
        setTextareaValue(value);
        const lastMatch = value.match(/(^|\s)(#?[a-zA-Z0-9_]*)$/)?.[2];
        if (lastMatch || !value.includes('#')) {
          const filteredSuggestions = tagshashdata?.filter((hashtag) =>
            hashtag?.toLowerCase().includes(value.toLowerCase())
          );
          setHashtagSuggestions(filteredSuggestions);
          setShowHashtagSuggestions(true);
        } else {
          setHashtagSuggestions([]);
          setShowHashtagSuggestions(false);
        }
      };


    const handleclearSearchBar = async ()=>{
        setTextareaValue('')
        setShowHashtagSuggestions(false);
    }
    
    
    const handleSearch = async () => {
        props.handleSearchFeedLoader()
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
        //search function
            dispatch(SearchPostLoggedOut(`${textareaValue.replace(/#/g, "")}`))
        }, 300);
    }

    //////////////////hash tag \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

    return (<>
        <div>
            <div className='feed--listing'>
                <Row>
                    <Col sm={12}>
                        <Form 
                            // onSubmit={(e) => handleSearch(e)} 
                            className='post--search' ref={searchbarRef}>
                            <Form.Group className='form-group'>
                                <span className='search--icon'>
                                    <BiSearch />
                                </span>
                                <Form.Control value={textareaValue}
                                    onChange={handleTextareaChange}
                                    type="text" placeholder="Search"
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) { handleSearch(); }
                                    }}
                                />
                               {textareaValue &&
                                <span onClick={handleclearSearchBar} className='close--icon'>
                                    <AiFillCloseCircle />
                                </span>
                                }
                            </Form.Group>
                            <div className="hashtag-searchbar">
                                {showHashtagSuggestions && (
                                    <div className="hashtag-suggestions">
                                        <ul>
                                            {hashtagSuggestions?.map((suggestion, index) => (
                                                <li key={index} onClick={() => handleHashtagSelection(suggestion)}>
                                                    {suggestion}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    </>)
}

export default SearchLoggedOutPostFeed