const types = {
    /** -- AUTH -- */
    CREATE_ACCOUNT_SUCCESS:"CREATE_ACCOUNT_SUCCESS",
    CREATE_ACCOUNT_FAILED:"CREATE_ACCOUNT_FAILED",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",
    SOCIAL_LOGIN_SUCCESS: 'SOCIAL_LOGIN_SUCCESS',
    SOCIAL_LOGIN_FAILED: 'SOCIAL_LOGIN_FAILED',
    GOOGLE_LOGIN_SUCCESS: 'GOOGLE_LOGIN_SUCCESS',
    GOOGLE_LOGIN_FAILED: 'GOOGLE_LOGIN_FAILED',
    REQUEST_OTP_SUCCESS: "REQUEST_OTP_SUCCESS",
    REQUEST_OTP_FAILED: "REQUEST_OTP_FAILED",
    VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
    VERIFY_OTP_FAILED: "VERIFY_OTP_FAILED",
    VERIFY_OTP_RESET_PASSWORD_SUCCESS: "VERIFY_OTP_RESET_PASSWORD_SUCCESS",
    VERIFY_OTP_RESET_PASSWORD_FAILED: "VERIFY_OTP_RESET_PASSWORD_FAILED",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
    REQUEST_OTP_RESET_PASSWORD_SUCCESS: "REQUEST_OTP_RESET_PASSWORD_SUCCESS",
    REQUEST_OTP_RESET_PASSWORD_FAILED: "REQUEST_OTP_RESET_PASSWORD_FAILED",
    LOGOUT: "LOGOUT",
    AUTH_COMMON_ERROR:"AUTH_COMMON_ERROR",
    USER_SUGGESTION_LIST_SUCCESS:"USER_SUGGESTION_LIST_SUCCESS",
    USER_SUGGESTION_LIST_FAILED:"USER_SUGGESTION_LIST_FAILED",

    /** ------- USERS ------ */
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAILED:'GET_USER_PROFILE_FAILED',
    PUT_USER_PROFILE_SUCCESS: 'PUT_USER_PROFILE_SUCCESS',
    PUT_USER_PROFILE_FAILED: 'PUT_USER_PROFILE_FAILED',
    USER_COMMON_ERROR: 'USER_COMMON_ERROR',
   
    /** ------- SPECIALITIES ------- */
    GET_SPECIALITIES_SUCCESS: 'GET_SPECIALITIES_SUCCESS',
    GET_SPECIALITIES_FAILED: 'GET_SPECIALITIES_FAILED',

    /** -- POST -- */
    CREATE_POST_SUCCESS:"CREATE_POST_SUCCESS",
    CREATE_POST_FAILED:"CREATE_POST_FAILED",

    DELETE_POST_SUCCESS:'DELETE_POST_SUCCESS',
    DELETE_POST_FAILED:'DELETE_POST_FAILED',

    GET_POST_SUCCESS:"GET_POST_SUCCESS",
    GET_POST_FAILED:"GET_POST_FAILED",
    GET_SINGLE_POST_SUCCESS:"GET_SINGLE_POST_SUCCESS",
    GET_SINGLE_POST_FAILED:"GET_SINGLE_POST_FAILED",
    GET_POST_LOGGED_OUT_SUCCESS:"GET_POST_LOGGED_OUT_SUCCESS",
    GET_POST_LOGGED_OUT_FAILED:"GET_POST_LOGGED_OUT_FAILED",
    TAGS_COUNT_SUCCESS:"TAGS_COUNT_SUCCESS",
    TAGS_COUNT_FAILED:"TAGS_COUNT_FAILED",

    GET_SEARCH_POST_SUCCESS:"GET_SEARCH_POST_SUCCESS",
    GET_SEARCH_POST_FAILED:"GET_SEARCH_POST_FAILED",
    GET_TAGS_SUCCESS:"GET_TAGS_SUCCESS",
    GET_TAGS_FAILED:"GET_TAGS_FAILED",
    GET_SEARCH_POST_LOGGED_OUT_SUCCESS:"GET_SEARCH_POST_LOGGED_OUT_SUCCESS",
    GET_SEARCH_POST_LOGGED_OUT_FAILED:"GET_SEARCH_POST_LOGGED_OUT_FAILED",
    CREATE_LIST_COMMENT:"CREATE_LIST_COMMENT",
    CREATE_POST_LIST_COMMENT:"CREATE_POST_LIST_COMMENT",
    DELETE_COMMENT:"DELETE_COMMENT",
    LIKE_POST:"LIKE_POST",
    DISLIKE_POST: 'DISLIKE_POST',
    POST_TOP_IDEAS_LIST_SUCCESS:"POST_TOP_IDEAS_LIST_SUCCESS",
    POST_TOP_IDEAS_LIST_FAILED:"POST_TOP_IDEAS_LIST_FAILED",

    POST_COMMON_ERROR: 'POST_COMMON_ERROR',

    /**----- Stripe checkout and Connectivity *************************** */
    CONNECT_STRIPE_ACCOUNT_SUCCESS: "CONNECT_STRIPE_ACCOUNT_SUCCESS",
    CONNECT_STRIPE_ACCOUNT_FAILED: "CONNECT_STRIPE_ACCOUNT_FAILED",
    ONBOARDING_SUCCESS:"ONBOARDING_SUCCESS",
    ONBOARDING_FAILED: "ONBOARDING_FAILED",
    STRIPE_COMMON_ERROR: "STRIPE_COMMON_ERROR",
    TRANSFER_FUNDS_SUCCESS: "TRANSFER_FUNDS_SUCCESS",
    TRANSFER_FUNDS_FAILED: "TRANSFER_FUNDS_FAILED",
    GET_STRIPE_DETAIL_SUCUESS: "GET_STRIPE_DETAIL_SUCUESS",
    GET_STRIPE_DETAIL_FAILED: "GET_STRIPE_DETAIL_FAILED",
    UPDATE_TRANSACTION_STATUS_SUCUESS : "UPDATE_TRANSACTION_STATUS_SUCUESS",
    UPDATE_TRANSACTION_STATUS_FAILED: "UPDATE_TRANSACTION_STATUS_FAILED",
    GET_TRANSACTION_HISTORY_SUCCESS: "GET_TRANSACTION_HISTORY_SUCCESS",
    GET_TRANSACTION_HISTORY_FAILED: "GET_TRANSACTION_HISTORY_FAILED"

    /**------------------------------------------------------------------ */
}  

module.exports = types;
