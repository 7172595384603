

import {
    POST_TOP_IDEAS_LIST_SUCCESS,
    POST_TOP_IDEAS_LIST_FAILED,
  } from '../actions/types';
  
  const initialState = {
    topideas:null
  };
  
  export default function commentsReducer(state = initialState, action) {
    switch (action.type) {
        case POST_TOP_IDEAS_LIST_SUCCESS:
          return {
              topideas: action.payload.postTopIdeasData,
          }
      case POST_TOP_IDEAS_LIST_FAILED:
          return {
              error: action.payload
          }
      default:
        return state;
    }
  }
  