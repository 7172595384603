import React from "react";
import { Row, Col, Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import SidebarLoggedOut from '../PostFeed/SearchLoggedOut'
import SidebarLogged from '../PostFeed//SidebarLogged';
import SidebarFollow from '../PostFeed/FollowSidebar'

const CommingSoon = () => {
    return (
        <>
            <div className='Single-posts'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col sm={12} md={3} xl={3} className='menu--sidebar'>
                            <SidebarLogged />
                        </Col>
                        <Col sm={12} md={6} xl={6} className="section--content">
                            <div className="feed--listing">
                                {/* <h2>Comming Soon</h2> */}
                                <img className="img-responsive w-75 mx-auto d-block" src={require('../../assets/images/coming_soon.jpg')} alt='Coming Soon' />
                            </div>
                        </Col>
                        <Col sm={12} md={3} xl={3} className="rightFeed--sidebar">
                            <SidebarFollow />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default CommingSoon