import {
    CREATE_POST_SUCCESS,
    CREATE_POST_FAILED,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILED,
    GET_POST_SUCCESS,
    GET_POST_FAILED,
    GET_SINGLE_POST_SUCCESS,
    GET_SINGLE_POST_FAILED,
    GET_POST_LOGGED_OUT_SUCCESS,
    GET_POST_LOGGED_OUT_FAILED,
    GET_SEARCH_POST_SUCCESS,
    GET_SEARCH_POST_FAILED,
    GET_TAGS_SUCCESS,
    GET_SEARCH_POST_LOGGED_OUT_SUCCESS,
    GET_SEARCH_POST_LOGGED_OUT_FAILED,
    // CREATE_COMMENT,
    DELETE_COMMENT,
    LIKE_POST,
    DISLIKE_POST,
    GET_TAGS_FAILED,
    POST_COMMON_ERROR
} from "../../redux/actions/types";

 import {CREATE_LIST_COMMENT} from '../../redux/actions/types'
const initialState = {
    PostFeeds: null,
    tags: null,
    getPostFeed: null,
    getPostFeedloggedOut: null,
    comment: null,
    getOne: null,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_POST_SUCCESS:
            return {
                ...state,
                PostFeeds: action.payload.message,
            }
        case CREATE_POST_FAILED:
            return {
                ...state,
                PostFeedsError: action.payload,
            }
        case DELETE_POST_SUCCESS:
            if(state.getPostFeed){
                const posts = state?.getPostFeed?.filter(post => post._id !== action.payload.post);
                return {
                    deletePostMessage: action.payload.message,
                    getPostFeed: [...posts]
                }
            }else if(state.getPostFeedloggedOut){
                const posts = state?.getPostFeedloggedOut?.filter(post => post._id !== action.payload.post);
                return {
                    deletePostMessage: action.payload.message,
                    getPostFeedloggedOut: [...posts]
                }
            }else{
                return {
                    deletePostMessage: action.payload.message,
                }
            }
          
        case DELETE_POST_FAILED:
            return {
                deletePostError: action.payload.error
            }
        case GET_POST_SUCCESS:
            return {
                getPostFeed: action.payload.postData,
            }
        case GET_POST_FAILED:
            return {
                error: action.payload
            }
        case GET_SEARCH_POST_SUCCESS:
            return {
                getPostFeed: action.payload.postData,
            }
        case GET_SEARCH_POST_FAILED:
            return {
                error: action.payload
            }
        case GET_POST_LOGGED_OUT_SUCCESS:
            return {
                getPostFeedloggedOut: action.payload.postData,
            }
        case GET_POST_LOGGED_OUT_FAILED:
            return {
                error: action.payload
            }
        case GET_SINGLE_POST_SUCCESS:
            return {
                getOne: action.payload.postDataSingle
            }
        case GET_SINGLE_POST_FAILED:
            return {
                error: action.payload
            }
        case GET_TAGS_SUCCESS:
            return {
                tags: action.payload.tags,
            }
        case GET_TAGS_FAILED:
            return {
                error: action.payload
            }
        case GET_SEARCH_POST_LOGGED_OUT_SUCCESS:
            return {
                getPostFeedloggedOut: action.payload.postData,
            }
        case GET_SEARCH_POST_LOGGED_OUT_FAILED:
            return {
                error: action.payload
            }

        case CREATE_LIST_COMMENT:
            const existingComment = state?.getOne?.comments?.find(
                (comment) => comment?._id === action?.payload?.comment?._id
            );
            const updatedPosts = state?.getPostFeed?.map((post) => {
                if (post?._id === action?.payload?.comment?.post?._id) {
                  const existingComment = post.comments.find((comment) => comment._id === action.payload.comment._id);

                //   console.log('existingComment',existingComment)

                  if (existingComment) {
                    return {
                        // ...state.getPostFeed,
                         ...post,
                      };
                  } else {
                    return {
                        ...post,
                      comments: [action.payload.comment, ...post.comments],
                    };
                  }
                } else {
                  return post;
                }
              })

            if (existingComment) {
                return {
                    ...state,
                    getOne: {
                        ...state.getOne,
                        comments: state?.getOne?.comments?.map((comment) => {
                            if (comment?._id === action.payload.comment._id) {
                                return {
                                    ...comment,
                                    replies: [...action.payload?.comment?.replies],
                                };
                            } else {
                                return comment;
                            }
                        }),
                    }, 
                    // ...state,
                    // getPostFeed: updatedPosts,
                };
            } else {
                if (state.getPostFeed) {
                    return {
                        ...state,
                        getPostFeed: updatedPosts,
                    };
                } 
                else {
                    return {
                        ...state,
                        getOne: {
                            ...state.getOne,
                            comments: [action?.payload?.comment, ...state?.getOne?.comments],
                        },
                    };

                }
            }

        case DELETE_COMMENT:
            if(state?.getPostFeed){
                const deleteId = state?.getOne?.comments?.find(
                    (comment) => comment?._id === action?.payload?.commentId
                ); 
                const filteredPosts = state?.getPostFeed?.filter((post) => 
                post._id == action.payload.feedId)
            const dd = filteredPosts?.map((x)=> x?.comments?.filter((x)=> x._id !== action.payload.commentId))
            const flattenedObj = {
                ...dd[0][0],
              };  
              const deletet  = state?.getPostFeed?.map((post) => {
                if (post?._id === action?.payload?.feedId) {
                  const existingComment = post.comments.find((comment) => comment._id !== action.payload.commentId);
                  if (!existingComment) {
                    return {
                            ...post,
                      comments: [flattenedObj],
                      };
                  } else {
                    return {
                        ...post,
                      comments: [flattenedObj],
                    };
                  }
                } else {
                  return post;
                }
              })
              if (deleteId) {
                if (state.getPostFeed) {
                    return {
                        ...state,
                        getPostFeed: deletet,
                    };  
                }
              }
            }else{
                const deleteId = state?.getOne?.comments?.find(
                    (comment) => comment?._id === action?.payload?.commentId
                ); 

                if(deleteId){
                    const comments = state.getOne.comments.filter(comment => comment?._id !== action.payload.commentId);
                    return {
                        getOne: {
                            ...state.getOne,
                            comments: comments
                        },
                    };
                }
            }
            // const deleteId = state?.getOne?.comments?.find(
            //     (comment) => comment?._id === action?.payload?.commentId
            // );
            // const filteredPosts = state?.getPostFeed?.filter((post) => 
            //     post._id == action.payload.feedId)
            // const dd = filteredPosts?.map((x)=> x?.comments?.filter((x)=> x._id !== action.payload.commentId))
            // const flattenedObj = {
            //     ...dd[0][0],
            //   };
            
            // const deletet  = state?.getPostFeed?.map((post) => {
            //     if (post?._id === action?.payload?.feedId) {
            //       const existingComment = post.comments.find((comment) => comment._id !== action.payload.commentId);
            //       if (!existingComment) {
            //         return {
            //                 ...post,
            //           comments: [flattenedObj],
            //           };
            //       } else {
            //         return {
            //             ...post,
            //           comments: [flattenedObj],
            //         };
            //       }
            //     } else {
            //       return post;
            //     }
            //   })

            // if (deleteId) {
            // } else {
            //     if (state.getPostFeed) {
            //         return {
            //             ...state,
            //             getPostFeed: deletet,
            //         };  
            //     }
            //     else{
            //     const comments = state.getOne.comments.filter(comment => comment?._id !== action.payload.commentId);
            //     return {
            //         getOne: {
            //             ...state.getOne,
            //             comments: comments
            //         },
            //     };
            // }
            // }

            case LIKE_POST: case DISLIKE_POST: 
                // console.log( 'state ', state, 'action.payload', action.payload )
                if(state.getPostFeed){
                    const postIndex = state?.getPostFeed?.findIndex(post => post._id === action.payload.post._id);
                    const copyPosts = state.getPostFeed
                    copyPosts[postIndex].likes = action.payload?.post?.likes
                    copyPosts[postIndex].dislikes = action.payload?.post?.dislikes
                    const updatedPost  = [...copyPosts]
                    return {
                        getPostFeed: updatedPost
                    }
                }

                if(state.getOne){
                    const copyPost = state.getOne
                    copyPost.likes = action.payload?.post?.likes
                    copyPost.dislikes = action.payload?.post?.dislikes
                    const updatedPost = { ...copyPost };
                    return {
                        getOne: updatedPost
                    }
                }

                if(state.getPostFeedloggedOut){
                    const postIndex = state?.getPostFeedloggedOut?.findIndex(post => post._id === action.payload.post._id);
                    const copyPosts = state.getPostFeedloggedOut
                    copyPosts[postIndex].likes = action.payload?.post?.likes
                    copyPosts[postIndex].dislikes = action.payload?.post?.dislikes
                    const updatedPost  = [...copyPosts]
                    return {
                        getPostFeedloggedOut: updatedPost
                    }
                }
            case POST_COMMON_ERROR:
                return {
                    error: action.payload
                }

        default: return state;
    }
};
