import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BiSearch, BiLike, BiDislike } from 'react-icons/bi';
import { ListPostFeedDataLoggedOut } from '../../redux/actions/post.action';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TbCalendarTime } from 'react-icons/tb';
import { BsCalendar4 } from 'react-icons/bs';
import { FaRegCommentDots } from 'react-icons/fa';
import SearchLoggedOutPostFeed from "./SearchLoggedOut";
import moment from 'moment';
import { ThreeDots } from 'react-loader-spinner';
import toast from "react-hot-toast";
import * as auth from '../../helpers/auth';
/** --- Light Box --- */
import LightboxComponent from "../LightBoxComponent";

import LoginModelOpen from "../authentication/authModels/LoginModelOpen"


const PostFeedsLoggedOut = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [post, setPost] = useState({ title: '', description: '', slug: '' });
    const [error, setError] = useState({ title: '', description: '', slug: '' });
    const [activeId, setActiveId] = useState(null);
    // const [loading, setLoading] = useState(false);
    const [loaderListing, setloaderListing] = useState(true);
    const [showLoginModelAuth, setshowLoginModelAuth] = useState(false);
    const [showLoginFooterModelAuth, setshowLoginFooterModelAuth] = useState(false);
    const [showMessageAlert, setShowMessageAlert] = useState(false);
    const [displayMessage, setDisplayMessage] = useState('');
    
    /** --- Light Box State --- */
    const [isLightBoxOpen, setLightBox] = useState(false);
    const [ postMedia, setPostMedia ] = useState(null);

    // const [loader, setLoader] = useState(true);
    const [PostFeedd, setPostFeed] = useState([]);
    const apiError = useSelector(state => state.post.error);
    const postFeed = useSelector(state => state.post?.getPostFeedloggedOut);


    const handleListPost = async () => {
        await dispatch(ListPostFeedDataLoggedOut());
    }
    useEffect(() => {
        (async () => {
            // setLoader(true)
            await handleListPost()
            // setLoader(false)
            // setloaderListing(true)
        })();
    }, [])

    useEffect(() => {
        if (postFeed) {
            setPostFeed(postFeed)
            setloaderListing(false)
            // setLoader(false)
        }
    }, [postFeed])

    // useEffect(() => {
    //     (async () => {
    //         setLoader(true)
    //         await handleListPost()
    //         setLoader(true)
    //     })();
    // }, [])

    const toggleAccordion = (id) => {
        setActiveId(activeId === id ? null : id);
    }

    // function getWordElement(word, index) {
    //     function generateRandomString() {
    //         let result = '';
    //         const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    //         for (let i = 0; i < 15; i++) {
    //             result += characters.charAt(Math.floor(Math.random() * characters.length));
    //         }
    //         return result;
    //     }
    //     const randomString = generateRandomString();

    //     if (word.startsWith("#")) {
    //         return (
    //             <span key={index} className="highlighted-keyword">
    //                 <a href={`/post/feed/${word.substr(1)}/${randomString}`}>{word}{" "}</a>
    //             </span>
    //         );
    //     } else {
    //         return <span key={index}>{word} </span>;
    //     }
    // }

    const handleSearchFeedLoader = () => {
        setloaderListing(true)
    }

    const HandleShowLoginModel =(message)=>{
        setshowLoginFooterModelAuth(true)
        setShowMessageAlert(true)
        setDisplayMessage(message)
    }

    /** --- Light Box Code  */
    const handleCloseLightBox = () => {
        setLightBox(false)
        setPostMedia(null)
    }

    const handleOpenLightBox = (media) => {
        let images = media.map(m => { return { src: m.secure_url, alt: 'Image' } });
        setPostMedia(images)
        setLightBox(true)
    }

    return (
        <div className='feed--listing out--with--bottom'>
            <Row>
                <Col sm={12}>
                    <SearchLoggedOutPostFeed handleSearchFeedLoader={handleSearchFeedLoader} />
                </Col>
                <Col sm={12}>
                    {isLightBoxOpen &&
                        <LightboxComponent
                            isOpen={isLightBoxOpen}
                            images={postMedia}
                            onClose={() => handleCloseLightBox()}
                        />
                     }
                    <div className='feed--cards'>
                        {
                            !loaderListing ?
                                PostFeedd?.length > 0
                                    ?   PostFeedd.map((data) => {
                                        return (<>
                                            <Card>
                                                <span className='user--avatar'>
                                                    {/* <img src={require('../../assets/images/profile.png')} alt='...' /> */}
                                                    <span className={`name--initial nm-${data?.author?.userName?.split('')[0]}`}>{data?.author?.userName.split('')[0]}</span>
                                                </span>
                                                <Card.Body>
                                                    <Card.Title className="title-post-feed">
                                                        <Card.Link >
                                                            <Link to={`/post/feeds/${data._id}`}>{data.title}</Link>
                                                            {/* <Link to={`/post/feeds/${data._id}/${data.slug}`}>{data.title}</Link> */}
                                                        </Card.Link>
                                                    </Card.Title>
                                                    <Card.Subtitle className="mb-3 text-muted">
                                                        {/* <span>Analytics | Operational Reporting</span> */}
                                                        <span><BsCalendar4 /> {moment(data.createdAt).fromNow()} <a className="auther-name">{data?.author?.userName}</a></span>
                                                    </Card.Subtitle>
                                                    <div className="mb-3">
                                                        {data?.description?.split(" ").map((word, index) => {
                                                            if (word.startsWith("#")) {
                                                                return (
                                                                    <span key={index} className="highlighted-keyword">
                                                                        <Link 
                                                                            to={`/post/feed/${word.substr(1)}`}
                                                                        >
                                                                            {word}{" "}
                                                                        </Link>
                                                                    </span>
                                                                );
                                                            } else {
                                                                return <span key={index}>{word} </span>;
                                                            }
                                                        })}
                                                        <div className="post--feed--grid">
                                                            {(data.media && data.media.length > 0) ?   
                                                                data.media.map((media, pictureIndex) => {
                                                                    return (
                                                                        <div className="post--feed--media">
                                                                            {auth.checkImageExtension().includes(media.format) && (<img key={pictureIndex} src={media.secure_url} onClick={() => handleOpenLightBox(data.media)}/>) }
                                                                            {auth.checkVideoExtension().includes(media.format) && (<video key={pictureIndex} src={media.secure_url} autoPlay={false} controls/>) }
                                                                            {(!auth.checkImageExtension().includes(media.format) && !auth.checkVideoExtension().includes(media.format)) && <img key={pictureIndex} src={require('../../assets/images/nip.png')} />}
                                                                        </div>
                                                                    )
                                                                })
                                                                : null
                                                            }
                                                        </div>
                                                        {/* {data?.description?.split(" ").map(getWordElement)} */}
                                                    </div>
                                                    {/* <Card.Text>{data.description}</Card.Text> */}
                                                    <ListGroup>
                                                            {/* <Link to={`/post/feeds/${data._id}/${data.slug}`}> */}
                                                            <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={  
                                                                    <Tooltip id="button-tooltip-comment">
                                                                        Comment
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <ListGroup.Item onClick={()=> HandleShowLoginModel('Login to share your views')}><FaRegCommentDots /> {data?.comments?.length}</ListGroup.Item>
                                                            </OverlayTrigger>
                                                            {/* </Link> */}
                                                            <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={  
                                                                    <Tooltip id="button-tooltip-like">
                                                                        Upvote
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <ListGroup.Item onClick={()=> HandleShowLoginModel('Login to upvote')}><BiLike /> {data?.likes?.length}</ListGroup.Item>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={  
                                                                    <Tooltip id="button-tooltip-comment">
                                                                        Schedule Consultation
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <ListGroup.Item onClick={()=> HandleShowLoginModel('Login to schedule consultation')}><TbCalendarTime /></ListGroup.Item>
                                                            </OverlayTrigger>
                                                        {/* <ListGroup.Item><BiDislike /> {data?.dislikes?.length}</ListGroup.Item> */}
                                                    </ListGroup>
                                                </Card.Body>
                                                {/* {data?.comments?.map((comment) => {
                                                    return (
                                                        <>
                                                            <div className="comment-section-main">
                                                                <div className="comment-img">
                                                                <img src={require('../../assets/images/profile.png')} alt='...' />
                                                                    <div>
                                                                    <h4 className="auther-name-commet">{comment.author?.userName ? comment.author?.userName : "NA"}</h4>
                                                                    <p><TbCalendarTime /> {moment(comment.createdAt).fromNow()} </p>
                                                                    </div>
                                                                </div>
                                                                <div className="text-comment">
                                                                    <p className="mb-4">{comment.text}</p>
                                                                    <div className="accordion">
                                                                    <div className="accordion-header-main"
                                                                        onClick={() => toggleAccordion(comment._id)}>
                                                                        <FaRegCommentDots /> {comment?.replies?.length}
                                                                        </div>
                                                                        {activeId === comment._id &&
                                                                        <div className="accordion-body-main">
                                                                        {comment?.replies?.map((x) => {
                                                                        return (<>
                                                                        <div className="nested-comment">
                                                                            <div className="comment-img">
                                                                            <img src={require('../../assets/images/profile.png')} alt='...' />
                                                                            <div>
                                                                            <h4 className="auther-name-commet">{x.author?.userName ? x.author?.userName : "NA"}</h4>
                                                                            <p><TbCalendarTime /> {moment(x.createdAt).fromNow()} </p>
                                                                            </div>
                                                                            </div>
                                                                            <div><p className="mb-0">{x.text}</p></div>
                                                                            </div>
                                                                            </>)
                                                                        })}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })} */}
                                            </Card>
                                        </>)
                                        })
                                    :   <h2 className="mt-5 text-center">Post Not Found</h2>
                                :   <div className="loader-main-list">
                                        <ThreeDots
                                            height="50"
                                            width="50"
                                            radius="9"
                                            color="#e44300"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                        }
                    </div>
                </Col>
            </Row>
            {showLoginFooterModelAuth &&
              <LoginModelOpen 
                setshowLoginFooterModelAuth={setshowLoginFooterModelAuth}
                showMessageAlert={showMessageAlert}
                displayMessage={displayMessage}
              />
            }
        </div>
    )
}

export default PostFeedsLoggedOut