import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from "react-pro-sidebar";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaHashtag } from 'react-icons/fa';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const SidebarLoggedOut = (props) => {

    const { routes } = props;
    const [menuCollapse, setMenuCollapse] = useState(false)
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    
    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    const activeRoute = (menu) => {
        let route = pathname
        const path = [route.split('/').join('')]
        const result = path.some((name) => name.match(menu))
        return result
    }

    const activeSubRoute = (route) => {
        return route === pathname

    }

    return (
        <div className='logged--sidebar'>
            <div className='ideas--logo mobile--logo'>
                <Link to='/home'>
                    {window.location.pathname === '/home'
                        ? <img className='logo--full' src={require('../../assets/images/ideas-logo-full.png')} alt='Ideas.cab' />
                        : <img className='logo--small' src={require('../../assets/images/ideas-logo-icon.png')} alt='Ideas.cab' />
                    }
                </Link>
            </div>
            <ProSidebar collapsed={menuCollapse}>
                <SidebarHeader>
                    <div className='ideas--logo'>
                        <Link to='/home'>   
                            {window.location.pathname === '/home'
                            ?   <img className='logo--full' src={require('../../assets/images/ideas-logo-full.png')} alt='Ideas.cab' />
                            :   <img className='logo--small' src={require('../../assets/images/ideas-logo-icon.png')} alt='Ideas.cab' />
                            }  
                        </Link>
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu activeKey="/explore">
                        <MenuItem>
                        <Link className={window.location.pathname == "/explore" ? "active" : null } to='/explore'>
                        <div><FaHashtag /> Explore</div>
                        </Link>
                        </MenuItem>
                    </Menu>
                </SidebarContent>
            </ProSidebar> 
            <div className="closemenu" onClick={menuIconClick}>
                {menuCollapse ? (
                    <GrClose />
                ) : (
                    <GiHamburgerMenu />
                )}
            </div>
        </div>
    )
}

export default SidebarLoggedOut