
import { AiOutlineSolution,AiOutlineUserAdd } from "react-icons/ai";

// import SignIn from '../components/authentication/sign-in';
// import SignUp from '../components/authentication/sign-up';
import PostFeed from '../components/PostFeed';
import ResetPassword from '../components/authentication/reset-password'
import { Navigate } from "react-router-dom";
import SinglePost from '../components/PostFeed/SinglePost/index'
import ExploreHashtag from "../components/PostFeed/ExploreHash";
import Privacy from "../components/Pages/PrivacyPolicy";
import TermsOfService from "../components/Pages/TermsService";
import UserProfile from "../components/Profile/UserProfile";
import HashAllPots from "../components/PostFeed/HashPost/HashPosts";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import CommingSoon from "../components/Pages/CommingSoon"
import StripeOnboarding from "../components/Stripe/StripeOnboarding.js";
import MyProfile from "../components/Profile/MyProfile";
import CheckOutCommon from "../components/Checkout";
import PaymentSuccess from "../components/Checkout/PaymentSuccess";
import PaymentFailed from "../components/Checkout/PaymentFailed";
import TransactionHistory from "../components/TransactionHistory";


const all = 'all'
const owner = 'managers'

const privateRoutes = [
    {
        type: "other",
        name: 'ROOT',
        key: 'ROOT',
        component: <Navigate to="/home" />,
        route: "/",
        access: all
    },
    {
        type: 'page',
        name: 'home',
        key: 'POSTFEED',
        component: <PostFeed />,
        // icon: <FiHome />,
        route: '/home',
        access: all,
    },
    {
        type: 'other',
        name: 'Un Match',
        key: "UN_MATCH",
        component: <Navigate to="/home" />,
        route: "*",
        access: all
    },
    {
        type: 'page',
        name: 'post',
        key: 'POST',
        component: <SinglePost />,
        route: '/post/feeds/:id',
        access: all,
    },
    {
        type: 'page',
        name: 'post',
        key: 'POST',
        component: <ExploreHashtag />,
        route: '/explore',
        access: all,
    },
    {
        type: 'page',
        name: 'userProfile',
        key: 'USER_PROFILE',
        component: <UserProfile />,
        route: '/profile/:id',
        access: all,
    },
    {
        type: 'page',
        name: 'post',
        key: 'MY_PROFILE',
        component: <MyProfile />,
        route: '/profile/me',
        access: all,
    },
    {
        type: 'page',
        name: 'post',
        key: 'POST',
        component: <HashAllPots />,
        route: '/post/feed/:id',
        access: all,
    },
    {
        type: 'page',
        name: 'privacy-policy',
        key: 'PRIVACY',
        component: <Privacy />,
        route: '/privacy-policy',
        access: all,
    },
    {
        type: 'page',
        name: 'terms',
        key: 'TERMS',
        component: <TermsOfService />,
        route: '/terms-service',
        access: all,
    },
    {
        type: 'page',
        name: 'terms',
        key: 'COMMINGSOON',
        component: <CommingSoon />,
        route: '/comming-soon-notifications',
        access: all,
    },
    {
        type: 'page',
        name: 'terms',
        key: 'COMMINGSOON',
        component: <CommingSoon />,
        route: '/comming-soon-Messages',
        access: all,
    },
    {
        type: 'page',
        name: 'terms',
        key: 'COMMINGSOON',
        component: <CommingSoon />,
        route: '/comming-soon-more',
        access: all,
    },

    {
        type: 'page',
        name: 'onboard',
        key: 'ONBOARD',
        component: <StripeOnboarding/>,
        route: '/onboard-status'
    },

    {

        type: 'page',
        name: 'chekout',
        key: 'CHECKOUT',
        component: <CheckOutCommon/>,
        route: '/checkout'
        
    },

    {

        type: 'page',
        name: 'payment-sucess',
        key: 'SUCCESS',
        component: <PaymentSuccess/>,
        route: '/success'
        
    },
    {

        type: 'page',
        name: 'payment-failed',
        key: 'FAILED',
        component: <PaymentFailed/>,
        route: '/failed'
        
    },
    {
        type: 'page',
        name: 'transaction-history',
        key: 'TRANSACTIONHISTORY',
        component: <TransactionHistory/>,
        route: 'transaction-history'

    }


]

const publicRoutes = [
    // {
    //     type: 'other',
    //     name: 'ROOT',
    //     key: 'ROOT',
    //     component: <Navigate to="/sign-in" />,
    // },
    // {
    //     type: 'page',
    //     name: 'Sign In',
    //     key: 'SIGN_IN',
    //     component: <SignIn />,
    //     route: '/sign-in',
    // },
    // {
    //     type: 'page',
    //     name: 'Sign Up',
    //     key: 'SIGN_UP',
    //     component: <SignUp />,
    //     route: '/sign-up',
    // },
    {
        type: 'page',
        name:'Linked In Callback',
        key: 'Linked In Callback',
        component: <LinkedInCallback/>,
        route:'/linkedin',
        access:all,
    },
    {
        type: 'page',
        name: 'post',
        key: 'POST',
        component: <HashAllPots />,
        route: '/post/feed/:id',
        access: all,
    },
    {
        type: 'page',
        name: 'Sign Up',
        key: 'SIGN_UP',
        component: <SinglePost />,
        route: '/post/feeds/:id',
    },
    {
        type: 'page',
        name: 'Reset Password',
        key: 'RESET_PASSWORD',
        component: <ResetPassword />,
        route: '/reset-password',
    },
    {
        type: 'other',
        name: 'Un Match',
        key: 'UN_MATCH',
        component: <Navigate to="/sign-in" />,
        // route: "*",
    },
    {
        type: "other",
        name: 'ROOT',
        key: 'ROOT',
        component: <Navigate to="/home" />,
        route: "/",
        access: all
    },
    {
        type: 'page',
        name: 'home',
        key: 'home',
        component: <PostFeed />,
        // icon: <FiHome />,
        route: '/home',
        access: all,
    },
    {
        type: 'page',
        name: 'explore',
        key: 'EXPLORE',
        component: <ExploreHashtag />,
        route: '/explore',
        access: all,
    },
    {
        type: 'page',
        name: 'profile',
        key: 'USER_PROFILE',
        component: <UserProfile />,
        route: '/profile/:userId',
        access: all,
    },
    {
        type: 'page',
        name: 'privacy-policy',
        key: 'PRIVACY',
        component: <Privacy />,
        route: '/privacy-policy',
        access: all,
    },
    {
        type: 'page',
        name: 'terms',
        key: 'TERMS',
        component: <TermsOfService />,
        route: '/terms-service',
        access: all,
    },
    {
        type: 'other',
        name: 'Un Match',
        key: "UN_MATCH",
        component: <Navigate to="/home" />,
        route: "*",
        access: all
    }
]

export { privateRoutes, publicRoutes };