import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import postReducer from "./post.reducer";
import tagsReducer from "./tags.reducer";
import usersReducer from "./users.reducer";
import topideasReducer from "./topIdeas.reducer"
import stripeReducer from "./stripe.reducer";

export default combineReducers({
  auth: authReducer,
  post: postReducer,
  tags:tagsReducer,
  users:usersReducer,
  ideas:topideasReducer,
  stripe:stripeReducer
});
