import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import { BsLinkedin } from 'react-icons/bs'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SignUp from '../authentication/sign-up';
import SignIn from '../authentication/sign-in';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
// import GoogleLogin from 'react-google-login';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
// import { Button } from 'bootstrap';
import { useDispatch, useSelector } from "react-redux";
import AuthModels from '../authentication/authModels';
import { signInWithGoogle} from "../authentication/sign-in/firebase"
import { get } from 'lodash';
// import { toast } from 'react-hot-toast';
// import { linkedInAuthentication } from '../../redux/actions/auth.actions';


const SidebarLogin = (props) => {
    let i = 0
    const dispatch = useDispatch();
    const [showSignUpModel, setshowSignUpModel] = useState(false);
    const [showLoginModel, setshowLoginModel] = useState(false);
    const [linkedInLoader, setLinkedInLoader] = useState(false)
    const [showLoginModelAuth, setshowLoginModelAuth] = useState(false);
    const [linkedInError, setLinkdeInError] = useState('')
    const apiError = useSelector(state => state.auth.socialLoginError);
    const apiResult = useSelector(state => state.auth.token)

    const handleClose = () => setshowSignUpModel(false);
    const handleShow = () => {
        setshowSignUpModel(true);
        setshowLoginModel(false);
    }

    const handleCloseLoginModel = () => setshowLoginModel(false);
    const handleShowLoginModel = () => {
        setshowLoginModel(true);
        setshowSignUpModel(false);
    }

    // const responseGoogle = (response) => {
    //     console.log(response);
    // };

    // const onFailure = (error) => {
    //     console.error(error);
    // };

    const errorRequest = (err)  => {
        let data = get(err, 'response.data', null);
        data = data || get(err, 'response');
        data = data || err;
        if (data.error) {
            return data.error.message
        } else {
          return data.message
        }
    }

    const onSuccess = async (code) => {
        // console.log( 'Calling Sidebar' )
        i++
        if (code && i === 1) {
            // console.log( code  )
            setLinkdeInError('')
            // await dispatch(linkedInAuthentication(code));
            try {
                setLinkedInLoader(true)
                const response = await API.apiPostUrl('auth', `/linkedIn/login?code=${code}`)
                setLinkedInLoader(false)
                // console.log( response.data )
                if (response.data && response.data.success) {
                    if (response.data.accessToken) {
                        auth.login(response.data.accessToken);
                    }
                    window.location.replace('/')
                } else {
                    setLinkdeInError(response.data.message)
                }
            } catch (error) {
                const message = errorRequest(error)
                setLinkdeInError(message || 'Unable to proceed, try again')
            }
        }
    }


    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_CLIENTID, // '86vhj2q7ukf83q',
        redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        scope: 'r_liteprofile r_emailaddress',
        onSuccess: onSuccess,
        onError: (error) => {
            // console.log('Linked In ---- Error' , error);
        },
    });



    // useEffect(() => {
    //     setLinkdeInError('')
    //     // console.log( 'Api Error ' , apiError )
    //     if(apiError){
    //         // toast.dismiss()
    //         // toast.error(apiError)
    //         console.log( i )
    //         setLinkdeInError(apiError)
    //         setLinkedInLoader(false)
    //     }

    //     if(apiResult){
    //         window.location.replace('/')
    //     }
    // }, [apiError, apiResult])

    return (
        <>
            <div className='logged--sidebar sidebar--Login sidebar--logOut'>
                <h3>New to Ideas.cab ?</h3>
                <p className='text-muted'>Sign up now</p>

                {/* <div>
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText="Login with Google"
                        onSuccess={responseGoogle}
                        onFailure={onFailure}
                        cookiePolicy={'single_host_origin'}
                    />
                </div> */}


                <Link className='btn btn-outline-secondary mb-4 rounded-5'
                    onClick={() => signInWithGoogle()}>
                    <FaGoogle /> Login with Google</Link>

                {linkedInError && <span className='error error-massege'>{linkedInError}</span>}
                <Link className='btn btn-outline-secondary mb-4 rounded-5'
                    onClick={() => linkedInLoader ? null : linkedInLogin()}>
                    <BsLinkedin /> Login with LinkedIn {' '} {linkedInLoader ? <Spinner size='sm' /> : null}
                </Link>

                <Button className='mb-4 rounded-5' variant="primary"
                    onClick={() => setshowLoginModelAuth(true)}
                // onClick={handleShow}
                >
                    Create Account
                </Button>
                <p>By signing up, you agree to <Link to="/terms-service">Terms of Service</Link> and <Link to="/privacy-policy">Privacy Policy</Link></p>
                {/* <Link to='/sign-in'>Sign up now</Link> */}
            </div>


            {/* <Modal show={showSignUpModel} onHide={handleClose}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='sign-up-model'>
                        <SignUp setshowSignUpModel={setshowSignUpModel} />

                        <div className="sign-up-btn-login-page text-center">
                            <p>Already have an account? <span onClick={handleShowLoginModel} className="color-signUp-span">
                                Login here</span></p>
                        </div>
                        <div className="text-center Privacy-text-sign-up">
                            <p>By continuing, you agree to Term of Use and confirm that you have read Privacy Policy</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}
            {/* <div className='model-sign-up-z'>
                <Modal show={showLoginModel} onHide={handleCloseLoginModel}
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='sign-up-model'>
                            <SignIn />
                            <div className="sign-up-btn-login-page text-center">
                                <p>Don’t have a n account? <span onClick={handleShow}
                                    className="color-signUp-span">
                                    Sign Up</span></p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div> */}
            {showLoginModelAuth &&
                <AuthModels setshowLoginModelAuth={setshowLoginModelAuth} />
            }

        </>
    )
}

export default SidebarLogin