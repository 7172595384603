import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import RequestOtp from "./RequestOtp";
import OtpVerify from './OtpVerify';
import Reset from "./reset";

const ResetPassword = () => {
    const [email, setEmail] = useState('')
    const [showOtpView, setShowOtpView] = useState(false)
    const [showPasswordView, setShowPasswordView] = useState(false)

    return (
        <section className="wrapperMain h-100">
            <Container className="h-100">
                <Row className="justify-content-center align-items-center h-100">

                    {!showPasswordView 
                        ?   <Col sm={12} lg={5}>
                                {showOtpView
                                    ?   <OtpVerify 
                                            email={email}
                                            setShowPasswordView={setShowPasswordView}
                                            setShowOtpView={setShowOtpView}
                                        />
                                    :   <RequestOtp
                                            setEmail={setEmail} 
                                            email={email} 
                                            setShowOtpView={setShowOtpView}
                                        />
                                }
                            </Col>
                        :   <Col sm={12} lg={5}>
                                <Reset
                                    email={email}
                                />
                            </Col>
                        }
                </Row>
            </Container>
        </section>
    )
}

export default ResetPassword;
