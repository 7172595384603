import {useState , useEffect} from 'react';
import { Row, Col, Container, Alert, Button , Spinner } from 'react-bootstrap';
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import SidebarLoggedOut from '../PostFeed/SearchLoggedOut'
import SidebarLogged from '../PostFeed//SidebarLogged';
import SidebarFollow from '../PostFeed/FollowSidebar'
import { useNavigate , useLocation} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateTransactionStatus } from "../../redux/actions/stripe.actions";




const PaymentSuccess = () => {
    const location = useLocation();
    const [loading , setLoading] = useState(false);
    const searchParams = new URLSearchParams(location.search);
    const ideatxnid = searchParams.get('ideatxnid');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.stripe);


    useEffect(()=>{
        updateStatus()
    },[])


    useEffect(()=>{
        if(apiResult && apiResult.updateTransactionStatus){
            setLoading(false)
        }
        if(apiResult && apiResult.error){
            setLoading(false)
            toast.dismiss();
            toast.error(apiResult.error);
        }

    },[apiResult])


    const updateStatus = () =>{
        setLoading(true)
        const data = {transactionId:ideatxnid, status:"true"};
        dispatch(updateTransactionStatus(data));
       
    }

    const handleGoBack = () => {
        navigate('/home') // Go back to the previous page
    }
    return (
        <>
            <div className='Single-posts'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col sm={12} md={3} xl={3} className='menu--sidebar'>
                            <SidebarLogged />
                        </Col>
                        <Col sm={12} md={6} xl={6} className="section--content">
                            {loading 
                            ? <div className='text-center spinner--loader'><Spinner /></div>
                            :
                                <div className="feed--listing">
                                    <Button onClick = {handleGoBack} > Go Back </Button>
                                    <Alert className='mt-4' variant="success">
                                        <h3 className="m-0">Payment successful!! </h3>
                                    </Alert>
                                </div>
                            }
                        </Col>
                        <Col sm={12} md={3} xl={3} className="rightFeed--sidebar">
                            <SidebarFollow />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default PaymentSuccess
