import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
import {
    // USER_COMMON_ERROR, 
    GET_USER_PROFILE_SUCCESS, 
    GET_USER_PROFILE_FAILED,
    PUT_USER_PROFILE_SUCCESS,
    PUT_USER_PROFILE_FAILED,
    GET_SPECIALITIES_FAILED,
    GET_SPECIALITIES_SUCCESS,
} from './types'

const config = {
    headers: {
      'Content-Type': "application/json; charset=utf-8"
    }
}
  

function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    // console.log( ' error --->>', data.message )
    if (data.error) {
        dispatch({
            type: type,
            payload: {message: data.error.message},
        });
    } else {
        dispatch({
            type: type,
            payload: {message: data.message},
        });
    }
}


export const getUserProfile = (userId) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGetByKey('user', `/profile/${userId}`);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: response.data });
        } else {
            await dispatch({ type: GET_USER_PROFILE_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_USER_PROFILE_FAILED);
      }
    };
}

export const updateProfile = (payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiUpdateWithFileUplaod('user', `/profile`, payload);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: PUT_USER_PROFILE_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: PUT_USER_PROFILE_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, PUT_USER_PROFILE_FAILED);
        }
    }
}

/** --- Get All Idea Exchange Registered Specialities --- */

export const getAllSpecialities = () => {
    return async (dispatch) => {
        try{
            const response = await API.apiGet('user', `/specialities`);
            if (response.data && response.data.success) {
                await dispatch({ type: GET_SPECIALITIES_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_SPECIALITIES_FAILED, payload: response.data });
            }
        }catch (error){
            errorRequest(error, dispatch, GET_SPECIALITIES_FAILED);
        }
    }
}