import {useState , useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container, Table , Spinner} from 'react-bootstrap';
import { getTransactionHistory } from '../../redux/actions/stripe.actions';
import SidebarLogged from '../PostFeed/SidebarLogged';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { Link } from 'react-router-dom';


function TransactionHistory() {
    const [loading , setLoading] = useState(false);
    const [error , setError] = useState('')
    const [loggedUser , setLoggedUser] = useState('');
    const [userTransactionList , setUserTransactionList] = useState('')
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.stripe);
    const auth = useSelector(state => state.auth);

    useEffect(()=>{
        fetchTransationHistory();
        let token = localStorage?.getItem('accessToken')
        let DecodedToken = jwt_decode(token);
        setLoggedUser(DecodedToken?.aud)
    }, []);

    useEffect(()=>{
        if(apiResult && apiResult.transactionHistory){
            setLoading(false)
            transationList(apiResult.transactionHistory);
        }
        if(apiResult && apiResult.error){
            setError( apiResult.error)
            setLoading(false)
        }
       
    },[apiResult])

    const fetchTransationHistory = () =>{
        setLoading(true);
        dispatch(getTransactionHistory());
    }

    const transationList = (transaction) => {
        if( transaction && transaction?.length > 0){
            const  userTransation = transaction.filter((item , idx)=>{
                if(item.payeeId === loggedUser || item.payer._id === loggedUser){
                    return item 
                }
            })

            setUserTransactionList(userTransation)
        }
    }

    const isLoaded = () => {
        return (userTransactionList && userTransactionList?.length > 0);
    }
    
    const checkPaymentStatus = (item ) =>{
        if(item.status === 'true'){
            if(loggedUser !== item.payeeId){
                return 'Debit'
            }else{
                return 'Credit'
            } 
        }else {
            return 'Failed'
        }

    }

    const handlePayerAndPayeeName = (item) =>{
        if(loggedUser !== item.payeeId){
            return (
                <Link to={`/profile/${item?.payee._id}`}>
                    {item.payee.firstName ? item.payee.firstName : 'NA'}
                </Link>
                
            )
        }else{
            if(loggedUser === item.payeeId){
                return (
                    <Link to={`/profile/${item.payer._id}`}>
                        {item.payer.firstName ? item.payer.firstName : 'NA'}
                    </Link>
                )
            }    
        } 
    }
    return (
   
        <Container className='h-100'>
            <Row className='h-100'>
                <Col sm={12} md={3} xl={3} className='menu--sidebar'>
                    <SidebarLogged />
                </Col>
                <Col sm={12} md={9} lg={9}>
                    <div className='feed--listing'>
                    <h1 className="pageTitle"> Transaction List </h1>
                    {isLoaded()
                        ?   <Table striped bordered responsive size="lg">
                                <thead>
                                    <tr>
                                        <th> S.No.</th>
                                        <th> Transaction Id </th>
                                        <th> Transaction Status</th>
                                        <th> Payee </th>
                                        <th> Amount </th>
                                        <th>Date</th>
                                        <th> Payment Status </th>

                                    </tr>
                                </thead>
                                {
                                    userTransactionList.map((item , idx)=>{
                                        return (
                                            <tr key={idx}>
                                                <th>{idx+1}</th>
                                                <td>{item.transactionId}</td>
                                                <td>{item.status == 'true' ? 'Success' : 'Failed'}</td>
                                                <td>
                                                    {/* <Link to={`/profile/${item?.payeeId}`}>
                                                        {item.payeeName ? item.payeeName : 'NA'}
                                                    </Link> */}
                                                    {handlePayerAndPayeeName(item)}
                                                </td>
                                                <td>{item.amount} USD</td>
                                                <td>{moment(item.createdAt).format('ll')}</td>
                                                {/* <td> { item.status == 'true' loggedUser !== item.payeeId? 'Debit' : 'Credit'}</td> */}
                                                <td>{checkPaymentStatus(item)}</td>
                                            </tr>
                                        )
                                    })
                                }

                            </Table>
                        :   
                        <>
                            {loading 
                                ?   <div className='text-center spinner--loader'><Spinner /></div>
                                :    <h4> No Record Found </h4>
                                }
                        </>                              
                        }
                    </div>
                    <div>
                        
                    </div>
                    <div>
                        
                    </div>

                </Col>
            </Row>
        </Container>
   
  )
}

export  default TransactionHistory