import {useState , useEffect} from 'react';
import { tranferFunds , getStripeDetail } from '../../redux/actions/stripe.actions';
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useParams , useLocation} from "react-router-dom";
import { Row, Col, Alert, Container, Button, Spinner ,Form } from 'react-bootstrap';
import SidebarFollow from '../PostFeed/FollowSidebar';
import SidebarLogged from '../PostFeed/SidebarLogged';
import jwt_decode from 'jwt-decode';

function CheckOutCommon() {
    const location = useLocation();
    const author = location.state;
    const [isLoading, setIsLoading] = useState(false);
    const [amount , setAmount] = useState('');
    const [amountError , setAmountError]= useState(true);
    const [error, setError] = useState(null);
    const [stripeData , setStripeData]=useState(null);
    const [payerId , setPayerId] = useState('')
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.stripe);
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();
    console.log("error" , amountError)
 
    
    useEffect(()=>{
        if (auth.loggedIn) {
            let token = localStorage?.getItem('accessToken')
            let DecodedToken = jwt_decode(token)
            setPayerId(DecodedToken)
            fetchStripeDetails();
        }

    },[]);

    const handleAmount = (e) =>{
        const {value} = e.target ; 
        setAmount(value);
        setAmountError(/^\d+$/.test(value))
        // setAmountError(false);
    }
    
    /** get Stripe Account Details */

    const fetchStripeDetails = () =>{
        setIsLoading(true)
        dispatch(getStripeDetail(author._id))
        
    }

    useEffect(()=>{
        if(apiResult && apiResult.error){
            setError(apiResult.error)
            setIsLoading(false)
            toast.dismiss();
            toast.error(apiResult.error);
        }
        if (apiResult && apiResult.stripeDetails) {
            setStripeData(apiResult.stripeDetails.stripeDetail[0])
            setIsLoading(false)
        }
        if(apiResult && apiResult.transferFundsUrl){
            setIsLoading(false)
            window.location.replace(apiResult.transferFundsUrl.url)

        }
    },[apiResult])

 

    const handleCheckout = (e) =>{
        e.preventDefault();
        if(amountError){
            setIsLoading(true)
            const data = {amount: Number(amount), accountId: stripeData.id , payerId: payerId.aud , payeeId: author._id , payeeName: author.userName , payerName:payerId.userName }
            dispatch(tranferFunds(data))
        }    
       
       
    } 

    const handleGoBack = () => {
        navigate('/home') // Go back to the previous page
    }
    return (
        <>
            <div className='Single-posts'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col sm={12} md={3} xl={3} className='menu--sidebar'>
                            <SidebarLogged />
                        </Col>
                        
                        <Col sm={12} md={6} xl={6} className="section--content">
                            <div className="feed--listing">
                                { stripeData !==null
                                    ?   <>
                                            <Button onClick = {handleGoBack} > Go Back </Button>
                                                {/* <Alert className='mt-4' variant="info">
                                                    <h3 className="m-0">One Time Consult will Charge  : 50$</h3>
                                                </Alert> */}
                                                <Form onSubmit={handleCheckout}>
                                                    <Form.Group>
                                                        <Form.Label></Form.Label>
                                                        <Form.Control placeholder='Enter the amount' type="text" value={amount} onChange={handleAmount} required />
                                                        {!amountError && <small className="text-danger">Please enter a valid Amount</small>}
                                                    </Form.Group>
                                                    <Button className="mt-3" type='submit'>{ isLoading ? ' Please Wait...' : 'Proceed To Checkout' }</Button>
                                                </Form>
                                        </>
                                    :   <>
                                            { isLoading 
                                            
                                            ?  <div className='text-center spinner--loader'><Spinner /></div>
                                            :
                                                <>
                                                    <Button onClick = {handleGoBack} > Go Back </Button>
                                                    <Alert className='mt-4' variant="danger">
                                                        <h3 className="m-0">Consult account does not connected</h3>
                                                    </Alert>
                                                </>
                                            } 
                                        </>
                                }
                            
                            </div>
                        </Col>
                        
                        <Col sm={12} md={3} xl={3} className="rightFeed--sidebar">
                            <SidebarFollow />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
    
}
  
export default CheckOutCommon ; 