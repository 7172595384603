import React, { Component, useState } from 'react'
import { Row, Col, ListGroup, Container, Card, Button, Modal, Form, Accordion, Spinner, InputGroup } from 'react-bootstrap';
import { BsCamera, BsPlus, BsCalendar4Event } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { RiFileList2Line } from 'react-icons/ri'
import { connect } from 'react-redux';
import { GoPrimitiveDot } from 'react-icons/go';
import {GrFormClose} from 'react-icons/gr';
import {FiEdit2} from 'react-icons/fi';
import SidebarLogged from '../PostFeed/SidebarLogged';
import SidebarFollow from '../PostFeed/FollowSidebar';
import { toast } from 'react-hot-toast';
import { getUserProfile, updateProfile, getAllSpecialities } from '../../redux/actions/user.action';
import * as Auth from '../../helpers/auth';
import {connectStripeAccount } from '../../redux/actions/stripe.actions';
import StripeOnboarding from '../Stripe/StripeOnboarding.js';
import {getStripeDetail } from '../../redux/actions/stripe.actions';

class MyProfile extends Component{
    constructor(props){
        super(props)
        this.state = {
            show: false,
            profile: null,
            loader: true,
            loggedUserId: null,
            error:{},
            submitLoader:{},
            specialities:[] ,
            stripeAccountData:'',
            stripeConnected: false
           
        }
    }

    componentDidMount = async () => {
        await this.getLoggedUser();
        await this.getSpecialities();
        await this.getMyProfile();
        await this.getStripeData();
    }

    getLoggedUser = async () => {
        const userData = Auth.decodedLoggedUser();
        this.setState({
            loggedUserId: userData.aud
        }) 
    }

    getSpecialities = async () => {
        await this.props.getAllSpecialities();
        if(this.props.specialities.success){
            this.setState({ specialities: this.props.specialities.specialities })
        }
    }

    getMyProfile = async () => {
        try {
            await this.props.getUserProfile(this.state.loggedUserId)
            this.setState({ loader: false })
            if(this.props.profile && this.props.profile.success){
                this.setState({ profile: this.props.profile.profile })
            }else{
                toast.error( this.props.profile.message );
            }
        } catch (error) {
            this.setState({ loader: false })
            toast.error(error.message);
        }
    }

    getProfileName = () => {
        let name = ''
        if(this.state.profile.firstName){
            name = this.state.profile.firstName
        }

        if(this.state.profile.lastName){
            name += ' ' + this.state.profile.lastName
        }

        return name
    }

    getStripeData = async() =>{
       try {
            await this.props.getStripeDetail(this.state.loggedUserId)
            this.setState({ loader: false })
            if (this.props.stripeDetails.stripeDetail){
                this.setState({stripeConnected:true})
                this.setState({stripeAccountData: this.props.stripeDetails})  
            }else{
                this.setState({stripeConnected: false})
            }
       }catch(error){
            this.setState({stripeConnected: false})
            
       }
    }

    handleChangeLinkedAccount = (e) => {
        const { name, value } = e.target;
        let copyProfile = { ...this.state.profile }
        let copyError = { ...this.state.error }
        this.setState({ error: {} })

        if(value !== ''){
            if(name === 'linkedIn'){
                if(Auth.checkLinkedInURL(value)){
                    copyProfile.linkedAccount = { [name]: value }
                }else{
                    copyError.linkedAccount = { [name]: 'Please enter valid linkedIn profile url' }
                }
            }

            if(name === 'github'){
                if(Auth.checkGithubURL(value)){
                    copyProfile.linkedAccount = { [name]: value }
                }else{
                    copyError.linkedAccount = { [name]: 'Please enter valid github profile url' }
                }
            }

            if(name === 'twitter'){
                if(Auth.checkTwitterURL(value)){
                    copyProfile.linkedAccount = { [name]: value }
                }else{
                    copyError.linkedAccount = { [name]: 'Please enter valid twitter profile url' }
                }
            }

            if(name === 'behance'){
                if(Auth.checkBehanceURL(value)){
                    copyProfile.linkedAccount = { [name]: value }
                }else{
                    copyError.linkedAccount = { [name]: 'Please enter valid behance profile url' }
                }
            }
        }else{
            copyProfile.linkedAccount = { [name]: value }
            copyError = {}
        }

        this.setState({ profile:copyProfile, error: copyError })
    }

    handleChangeAbout = (e) => {
        const { name, value } = e.target
        const copyProfile = { ...this.state.profile }

        if(name === 'description'){
            copyProfile.bio.description = value
        }
        this.setState({ profile: copyProfile })
    }

    handleFileChange = (e, section) => {
        const { files } = e.target
        const copyProfile = { ...this.state.profile }
        if( files && files.length ){
            if(section === 'resume'){
                if(files[0].type === 'application/pdf'){
                    copyProfile.bio.resumeOrPortfolio = files[0]
                    this.setState({ profile: copyProfile });
                    this.handleUploadFile(files[0], section)
                    // this.handleProfileSubmit(files[0])
                }else{
                    toast.error(' Please upload only pdf files')
                }
            }

            if(section === 'profile'){
                const allImages = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'];
                if (allImages.indexOf(files[0].type.split('/')[1]) !== -1) {
                    copyProfile.profile = URL.createObjectURL(files[0]);
                    this.setState({ profile: copyProfile });
                    this.handleUploadFile(files[0], section)
                    // this.handleProfileSubmit(files[0])
                }else{
                    toast.error(' Please upload only jpg/png images');
                }
            }
        }
    }

    addExperience = (section) => {
        const copyProfile = { ...this.state.profile }
        if(section === 'workHistory'){
            copyProfile.experience.workHistory.push('');
        }

        if(section === 'education'){
            copyProfile.experience.education.push('');
        }

        this.setState({ profile: copyProfile })
    }

    removeExperience = (index, section) => {
        const copyProfile = { ...this.state.profile }
        if(section === 'workHistory'){
            copyProfile.experience.workHistory = copyProfile.experience.workHistory.filter((wh, whI) => whI !== index);
        }

        if(section === 'education'){
            copyProfile.experience.education = copyProfile.experience.education.filter((edu, eduI) => eduI !== index);
        }

        this.setState({ profile: copyProfile })
    }

    handleChangeExperience = (e, section) => {
        const { name, value } = e.target
        const copyProfile = { ...this.state.profile }

        if(section === 'workHistory'){
            copyProfile.experience.workHistory[name] = value
        }

        if(section === 'education'){
            copyProfile.experience.education[name] = value
        }

        if(section === 'level'){
            copyProfile.experience.level = name
        }

        this.setState({ profile: copyProfile })
    }

    handleChangeSpeciality = (e) => {
        const copyProfile = { ...this.state.profile }
        const { checked, name } = e.target
        if(checked){
            copyProfile.speciality.push(name);
        }else{
            copyProfile.speciality = copyProfile.speciality.filter( item => item !== name );
        }
        this.setState({ profile: copyProfile })
    }

    handleScheduleRates = (e, fieldType) => {
        const copyProfile = { ...this.state.profile }
        const { name, value, checked } = e.target
        if(fieldType === 'checkbox'){
            if(!copyProfile.scheduleRates){
                copyProfile.scheduleRates = { [name]: { status: true, value: '10' }  }
            }
            copyProfile.scheduleRates[name] = { ...copyProfile.scheduleRates[name], status: checked }
        }

        if(fieldType === 'select'){
            let reg = /^\d+$/;
            if(value === "" || reg.test(value)){
                if(!copyProfile.scheduleRates){
                    copyProfile.scheduleRates = { [name]: { status: true, value: '10' }  }
                }

                if(value === ''){
                    copyProfile.scheduleRates = { [name]: { status: false }  }
                }
                copyProfile.scheduleRates[name] = { ...copyProfile.scheduleRates[name], value: value }
            }else{
                return;
            }
        }
        this.setState({ profile: copyProfile });
    }

    handleProfileSubmit = async (e, section) => {
        e.preventDefault();
        const { profile, loggedUserId } = this.state
        const payload = {}
        const submitLoader = {}
        submitLoader[section] = true
        this.setState({ submitLoader });
        if(section === 'linkedAccount'){
            payload.linkedAccount = profile.linkedAccount
        }
        
        if(section ===  'description'){
            payload.bio = profile.bio
        }

        if(section === 'experience'){
            payload.experience = profile.experience
        }

        if(section === 'scheduleRates'){
            payload.scheduleRates = profile.scheduleRates
        }

        if(section === 'speciality'){
            payload.speciality = profile.speciality
        }
        try{
            await this.props.updateProfile( payload );
            submitLoader[section] = false
            this.setState({ submitLoader });
            if( this.props.profile.success ){

                toast.success( this.props.profile.message )
            }else{
                toast.error(this.props.profile.message)
            }
        }catch(error){
            submitLoader[section] = false
            this.setState({ submitLoader });
            toast.error(error.message);
        }
    }

    handleUploadFile = async (file, section) => {
        const submitLoader = {}
        try{
            submitLoader[section] = true
            this.setState({ submitLoader });
            const formData =  new FormData();
            formData.append(`${file.name}`, file);

            if(section === 'resume'){
                formData.append(`resumeOrPortfolio`, true);
            }

            if(section === 'profile'){
                formData.append(`profile`, true);
            }
            await this.props.updateProfile( formData );
            submitLoader[section] = false
            this.setState({ submitLoader });
            if( this.props.profile.success ){

                let copyProfile = { ...this.state.profile }
                if(section === 'profile'){
                    copyProfile.profile = this.props.profile.url
                }

                if(section === 'resume'){
                    copyProfile.bio.resumeOrPortfolio = this.props.profile.url
                }
                this.setState({ profile: copyProfile })
                toast.success( this.props.profile.message )
            }else{
                toast.error(this.props.profile.message)
            }
        }catch(error){
            submitLoader[section] = false
            this.setState({ submitLoader });
            toast.error(error.message);
        }
    }


    connectStripeAccount = async(e) =>{
        e.preventDefault()
        this.setState({loader:true})
        await this.props.connectStripeAccount();
        if(this.props.redirectUrl){
            const stripeAccountId = { accountId:this.props.redirectUrl.accountId}
            if(sessionStorage.getItem('stripe')){
                sessionStorage.removeItem('stripe')
            }
            sessionStorage.setItem('stripe', JSON.stringify(stripeAccountId));
            window.location.replace(this.props.redirectUrl.url)
        }else{
            toast.error(this.props.error)
        }
    }


    render(){
        const { show, loader, profile, submitLoader, specialities, loggedUserId , stripeAccountData , stripeConnected} = this.state
        return (
        <>
            <div className='logged--screen profile--page'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col sm={12} lg={3} className='menu--sidebar'>
                            <SidebarLogged />
                        </Col>
                     { loader
                     ?  <Col sm={12} lg={6} className='section--content'>
                            <div className='text-center spinner--loader'><Spinner /></div>
                        </Col>
                     :  <Col sm={12} lg={6} className='section--content'>
                            <div className='profile--box'>
                                <Card>
                                    <Card.Body>
                                        <label for='upload--image'>
                                            <Form.Control type="file" id='upload--image' hidden onChange={(e) => this.handleFileChange(e, 'profile')} accept="image/png, image/jpg, image/jpeg" />
                                            <img src={profile.profile ? profile.profile : require('../../assets/images/profile.png')} alt='Ideas.cab' />
                                           {submitLoader['profile'] && <span><Spinner/></span>}
                                        </label>
                                        <Card.Title>{this.getProfileName()}</Card.Title>
                                        <Card.Text>{profile.userName}</Card.Text>
                                    </Card.Body>
                                    <div className='button--group'>
                                        <Link to={`/profile/${loggedUserId}`} className='btn btn-primary'>See Public View</Link>
                                    </div>
                                </Card>
                            </div>
                            <div className='feed--listing feed--cards'>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            Linked Accounts
                                        </Card.Title>
                                        <Card.Text>Link your accounts to autofill Profile information</Card.Text>
                                        {/* <Form> */}
                                            <Row>
                                                <Form.Group className="mb-3 col-sm-12">
                                                    <Form.Control type="text" name="linkedIn" placeholder="LinkedIn" value={profile.linkedAccount.linkedIn || ''} onChange={(e) => this.handleChangeLinkedAccount(e)}/>
                                                </Form.Group>
                                                <Form.Group className="mb-3 col-sm-12">
                                                    <Form.Control type="text" name="github" placeholder="GitHub" value={profile.linkedAccount.github || ''} onChange={(e) => this.handleChangeLinkedAccount(e)}/>
                                                </Form.Group>
                                                <Form.Group className="mb-3 col-sm-12">
                                                    <Form.Control type="text" name="twitter" placeholder="Twitter" value={profile.linkedAccount.twitter || ''} onChange={(e) => this.handleChangeLinkedAccount(e)}/>
                                                </Form.Group>
                                                {/* <Form.Group className="mb-3 col-sm-12">
                                                    <Form.Control type="text" name="behance" placeholder="Behance" value={profile.linkedAccount.behance || ''} onChange={(e) => this.handleChangeLinkedAccount(e)}/>
                                                </Form.Group> */}
                                                {/* <Form.Group className="mb-3 col-sm-12">
                                                    <Form.Control type="text" name="dribbble" placeholder="Dribbble" value={profile.linkedAccount.dribble || ''} onChange={(e) => this.handleChangeLinkedAccount(e)}/>
                                                </Form.Group> */}
                                                {/* <Form.Group className="mb-3 col-sm-12">
                                                    <Form.Control type="text" placeholder="Other" value={profile.linkedAccount.behance || ''}/>
                                                </Form.Group> */}
                                            </Row>
                                            <Button type='submit' variant='primary' onClick={(e) => this.handleProfileSubmit(e, 'linkedAccount')} disabled={submitLoader['linkedAccount']}> {submitLoader['linkedAccount'] ? 'Saving...' : 'Save'}</Button>
                                        {/* </Form> */}
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            About
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <FiEdit2/>
                                                        <GrFormClose/>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Title>Bio</Card.Title>
                                                                <Card.Subtitle>Who are you? What’s your story?</Card.Subtitle>
                                                                <Form>
                                                                    <Form.Control as="textarea" name="description" rows={4} value={profile.bio.description || ''} onChange={(e) => this.handleChangeAbout(e)}/>
                                                                    {/* <Button type='button' variant='outline-primary'>Cancel</Button> */}
                                                                    <Button type='submit' variant='primary' onClick={(e) => this.handleProfileSubmit(e, 'description')} disabled={submitLoader['description']}> {submitLoader['description'] ? 'Saving...' : 'Save'}</Button>

                                                                </Form>
                                                            </Card.Body>
                                                        </Card>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Title>Resume/Portfolio</Card.Title>
                                                                <label for="portUpload" className='labelUpload btn btn-primary'>
                                                                    <Form.Control type="file" hidden id="portUpload" name="resumeOrPortfolio" onChange={(e) => this.handleFileChange(e, 'resume')} accept="application/pdf"/>
                                                                    <span><BsPlus/> Add</span>
                                                                </label>
                                                                {submitLoader['resume']
                                                                    ?   <ListGroup className='mt-3'>
                                                                            <ListGroup.Item> Uploading...</ListGroup.Item>
                                                                        </ListGroup>
                                                                    :   (profile && profile.bio.resumeOrPortfolio)  
                                                                            ?   <ListGroup className='mt-3'>
                                                                                    <ListGroup.Item onClick={() => profile.bio.resumeOrPortfolio instanceof File ? window.open(URL.createObjectURL(profile.bio.resumeOrPortfolio), '_blank') : window.open(profile.bio.resumeOrPortfolio, '_blank')} ><RiFileList2Line/> Resume</ListGroup.Item>
                                                                                </ListGroup>
                                                                            :   null
                                                                }
                                                            </Card.Body>
                                                        </Card>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Card.Title>
                                        <Card.Text>
                                            <ul>
                                                <li>Bio</li>
                                                <li>Resume + Portfolio</li>
                                            </ul>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='mb-0'>
                                            Specialty
                                            <Card.Text className='mb-2 mt-3'>What areas do you consult in?</Card.Text>
                                            <Card.Subtitle className='text--orange mb-0'><GoPrimitiveDot/> You must select at least one specialty.</Card.Subtitle>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <FiEdit2/>
                                                        <GrFormClose/>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Accordion>
                                                            {specialities.map((category, categoryIndex) => {
                                                                return (
                                                                    <Accordion.Item eventKey={`${categoryIndex}`} key={`speciality_${categoryIndex}`}>
                                                                        <Accordion.Header>{category.title}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <Form>
                                                                                <Form.Group className="mb0 form--check--input">
                                                                                    {category.subspecialties.map((subSpeciality, subSpecialityIndex) => {
                                                                                        return (
                                                                                            <Form.Check type="checkbox" label={subSpeciality} name={subSpeciality} key={`sub_speciality_${subSpecialityIndex}`} checked={profile.speciality.indexOf(subSpeciality) !== -1} onChange={(e) => this.handleChangeSpeciality(e)}/>
                                                                                        )
                                                                                    })}
                                                                                </Form.Group>
                                                                                {/* <Button type='button' variant='outline-primary'>Cancel</Button> */}
                                                                               
                                                                            </Form>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                )
                                                            })}
                                                        </Accordion>
                                                        <Button type='submit' variant='primary' onClick={(e) => this.handleProfileSubmit(e, 'speciality')} disabled={submitLoader['speciality']}> {submitLoader['speciality'] ? 'Saving...' : 'Save'}</Button>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                
                                            </Accordion>
                                        </Card.Title>
                                      
                                        {/* <Card.Text className='mb-2'>
                                            What areas do you consult in?
                                        </Card.Text>
                                        <Card.Subtitle className='text--orange'><GoPrimitiveDot/> You must select at least one specialty.</Card.Subtitle> */}
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            Experience
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <FiEdit2/>
                                                        <GrFormClose/>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Title className='mb-2'>Work History</Card.Title>
                                                                {profile.experience.workHistory.map((workHistory, workHistoryIndex) => {
                                                                    return (
                                                                        <Form.Group className="mb-3 add--experience">
                                                                            <ListGroup>
                                                                                <ListGroup.Item>
                                                                                    <Form.Control type="text" name={`${workHistoryIndex}`} placeholder={`eg: XYZ Co. April 2020 - present `} value={workHistory || ''} onChange={(e) => this.handleChangeExperience(e, 'workHistory')}/> 
                                                                                    <span onClick={() => this.removeExperience(workHistoryIndex, 'workHistory')}><MdOutlineCancel/></span>
                                                                                </ListGroup.Item>
                                                                            </ListGroup>
                                                                        </Form.Group>
                                                                    )
                                                                })}
                                                                <Button type='button' variant='primary' onClick={() => this.addExperience('workHistory')}><BsPlus/>Add</Button>                                                               
                                                            </Card.Body>
                                                        </Card>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Title className='mb-2'>Education</Card.Title>
                                                                {profile.experience.education.map((education, educationIndex) => {
                                                                    return (
                                                                        <Form.Group className="mb-3 add--experience">
                                                                            <ListGroup>
                                                                                <ListGroup.Item>                                                                                    
                                                                                    <Form.Control type="text" name={`${educationIndex}`} placeholder={`Eg: XYZ College 2015 - 2019`} value={education || ''} onChange={(e) => this.handleChangeExperience(e, 'education')}/> 
                                                                                    <span onClick={() => this.removeExperience(educationIndex, 'education')}><MdOutlineCancel/></span>
                                                                                </ListGroup.Item>
                                                                            </ListGroup>
                                                                        </Form.Group>
                                                                    )
                                                                })}
                                                                <Button type='button' variant='primary' onClick={() => this.addExperience('education')}><BsPlus/>Add</Button>          
                                                            </Card.Body>
                                                        </Card>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Title>Experience</Card.Title>
                                                                <ListGroup>
                                                                    <ListGroup.Item>
                                                                        <div class="form-check">
                                                                            <input name="entry-level" type="radio" id="entry-radio" class="form-check-input" checked={ profile.experience.level === 'entry-level' } onChange={(e) => this.handleChangeExperience(e, 'level')}/>
                                                                            <label for="entry-radio" class="form-check-label"><strong>Entry level</strong> 0-2 years experience in this field</label>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                    <ListGroup.Item>
                                                                        <div class="form-check">
                                                                            <input name="mid-level" type="radio" id="mid-radio" class="form-check-input" checked={ profile.experience.level === 'mid-level'} onChange={(e) => this.handleChangeExperience(e, 'level')}/>
                                                                            <label for="mid-radio" class="form-check-label"><strong>Mid level</strong> 3-5 years experience in this field</label>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                    <ListGroup.Item>
                                                                        <div class="form-check">
                                                                            <input name="expert-level" type="radio" id="expert-radio" class="form-check-input" checked={ profile.experience.level === 'expert-level'} onChange={(e) => this.handleChangeExperience(e, 'level')}/>
                                                                            <label for="expert-radio" class="form-check-label"><strong>Expert level</strong> 6+ years experience in this field</label>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                </ListGroup>         
                                                            </Card.Body>
                                                        </Card>
                                                        <Button type='submit' variant='primary' onClick={(e) => this.handleProfileSubmit(e, 'experience')} disabled={submitLoader['experience']}> {submitLoader['experience'] ? 'Saving...' : 'Save'}</Button>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Card.Title>
                                        <Card.Text>
                                            <h6>Work History</h6>
                                            <ul>
                                                {profile.experience.workHistory.map((wh, whIndex) => {
                                                    return (
                                                        <li key={`work_history_${whIndex}`}>{wh}</li>
                                                    )
                                            })}
                                            </ul>
                                            <h6>Education</h6>
                                            <ul>
                                            {profile.experience.education.map((edu, eduIndex) => {
                                                    return (
                                                        <li key={`education_${eduIndex}`}>{edu}</li>
                                                    )
                                            })}
                                            </ul>
                                            <h6>Experience Level</h6>
                                            <ul>
                                                {profile.experience.level === 'entry-level'  && <li><strong>Entry level</strong> 0-2 years experience in this field</li>}
                                                {profile.experience.level === 'mid-level' && <li><strong>Mid level</strong> 3-5 years experience in this field</li>}
                                                {profile.experience.level === 'expert-level' && <li><strong>Expert level</strong> 6+ years experience in this field</li>}
                                            </ul>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='mb-0'>
                                            Schedule + Rates
                                            <Card.Subtitle className='mt-2 mb-0'>Set your micro-consult time blocks and fees.</Card.Subtitle>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <FiEdit2/>
                                                        <GrFormClose/>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Title className='mb-3'>Schedule</Card.Title>
                                                                <Card.Text className='mb-0'>Easily share your availability and let others book micro consults with you.</Card.Text>
                                                                <Button type='button' variant='primary'><BsCalendar4Event/> Calendly</Button>                                                               
                                                            </Card.Body>
                                                        </Card>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Title className='mb-3'>Rates</Card.Title>
                                                                <Card.Text className='mb-2'>Set your micro-consult time blocks and fees.</Card.Text>
                                                                <Card.Subtitle className='mb-0 text--orange'><GoPrimitiveDot/> How do you redeem fees? <Button type="button" variant="link" onClick={() => this.setState({ show: true })}>Learn more</Button></Card.Subtitle>
                                                                <Form className='rates--form'>
                                                                    <label for="15">
                                                                        <span>
                                                                            <input type='checkbox' id='15' name="15" checked={(profile.scheduleRates && profile.scheduleRates['15']) ? profile.scheduleRates['15'].status : false} onChange={(e) => this.handleScheduleRates(e, 'checkbox')}/> 15 minutes
                                                                        </span> 
                                                                        <InputGroup>
                                                                            <InputGroup.Text>$</InputGroup.Text>
                                                                            <Form.Control aria-label="Amount (to the nearest dollar)" type="text" name="15" value={(profile.scheduleRates && profile.scheduleRates['15']) ? profile.scheduleRates['15'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')}/>
                                                                            {/* <InputGroup.Text>.00</InputGroup.Text> */}
                                                                        </InputGroup>

                                                                        {/* <Form.Control type="text" name="15" value={(profile.scheduleRates && profile.scheduleRates['15']) ? profile.scheduleRates['15'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')} /> */}
                                                                    </label>
                                                                    <label for="30">
                                                                        <span><input type='checkbox' id='30' name="30" checked={(profile.scheduleRates && profile.scheduleRates['30']) ? profile.scheduleRates['30'].status : false} onChange={(e) => this.handleScheduleRates(e, 'checkbox')}/> 30 minutes</span> 
                                                                        <InputGroup>
                                                                            <InputGroup.Text>$</InputGroup.Text>
                                                                            <Form.Control aria-label="Amount (to the nearest dollar)" type="text" name="30" value={(profile.scheduleRates && profile.scheduleRates['30']) ? profile.scheduleRates['30'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')}/>
                                                                            {/* <InputGroup.Text>.00</InputGroup.Text> */}
                                                                        </InputGroup>
                                                                        {/* <Form.Control type="text" name="30" value={(profile.scheduleRates && profile.scheduleRates['30']) ? profile.scheduleRates['30'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')} /> */}
                                                                    </label>
                                                                    <label for="45">
                                                                        <span>
                                                                            <input type='checkbox' id='45' name="45" checked={(profile.scheduleRates && profile.scheduleRates['45']) ? profile.scheduleRates['45'].status : false} onChange={(e) => this.handleScheduleRates(e, 'checkbox')}/> 45 minutes
                                                                        </span> 
                                                                        <InputGroup>
                                                                            <InputGroup.Text>$</InputGroup.Text>
                                                                            <Form.Control aria-label="Amount (to the nearest dollar)" type="text" name="45" value={(profile.scheduleRates && profile.scheduleRates['45']) ? profile.scheduleRates['45'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')}/>
                                                                            {/* <InputGroup.Text>.00</InputGroup.Text> */}
                                                                        </InputGroup>
                                                                            {/* <Form.Control type="text" name="45" value={(profile.scheduleRates && profile.scheduleRates['45']) ? profile.scheduleRates['45'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')} /> */}
                                                                    </label>
                                                                    <label for="1">
                                                                        <span><input type='checkbox' id='1' name="1" checked={(profile.scheduleRates && profile.scheduleRates['1']) ? profile.scheduleRates['1'].status : false} onChange={(e) => this.handleScheduleRates(e, 'checkbox')}/> 1 hour</span> 
                                                                        <InputGroup>
                                                                            <InputGroup.Text>$</InputGroup.Text>
                                                                            <Form.Control aria-label="Amount (to the nearest dollar)" type="text" name="1" value={(profile.scheduleRates && profile.scheduleRates['1']) ? profile.scheduleRates['1'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')}/>
                                                                            {/* <InputGroup.Text>.00</InputGroup.Text> */}
                                                                        </InputGroup>
                                                                        {/* <Form.Control type="text" name="1" value={(profile.scheduleRates && profile.scheduleRates['1']) ? profile.scheduleRates['1'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')} /> */}
                                                                    </label>
                                                                </Form>
                                                                {/* <Form className='rates--form'>
                                                                    <label for="15">
                                                                        <span><input type='checkbox' id='15' name="15" checked={(profile.scheduleRates && profile.scheduleRates['15']) ? profile.scheduleRates['15'].status : false} onChange={(e) => this.handleScheduleRates(e, 'checkbox')}/> 15 minutes</span> 
                                                                        <Form.Select name="15" value={(profile.scheduleRates && profile.scheduleRates['15']) ? profile.scheduleRates['15'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')}>
                                                                            <option value="10">$10</option>
                                                                            <option value="15">$15</option>
                                                                            <option value="20">$20</option>
                                                                            <option value="30">$30</option>
                                                                            <option value="40">$40</option>
                                                                            <option value="50">$50</option>
                                                                        </Form.Select>
                                                                    </label>
                                                                    <label for="30">
                                                                        <span><input type='checkbox' id='30' name="30" checked={(profile.scheduleRates && profile.scheduleRates['30']) ? profile.scheduleRates['30'].status : false} onChange={(e) => this.handleScheduleRates(e, 'checkbox')}/> 30 minutes</span> 
                                                                        <Form.Select name="30" value={(profile.scheduleRates && profile.scheduleRates['30']) ? profile.scheduleRates['30'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')}>
                                                                            <option value="10">$10</option>
                                                                            <option value="15">$15</option>
                                                                            <option value="20">$20</option>
                                                                            <option value="30">$30</option>
                                                                            <option value="40">$40</option>
                                                                            <option value="50">$50</option>
                                                                        </Form.Select>
                                                                    </label>
                                                                    <label for="45">
                                                                        <span><input type='checkbox' id='45' name="45" checked={(profile.scheduleRates && profile.scheduleRates['45']) ? profile.scheduleRates['45'].status : false} onChange={(e) => this.handleScheduleRates(e, 'checkbox')}/> 45 minutes</span> 
                                                                        <Form.Select name="45" value={(profile.scheduleRates && profile.scheduleRates['45']) ? profile.scheduleRates['45'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')}>
                                                                        <option value="10">$10</option>
                                                                            <option value="15">$15</option>
                                                                            <option value="20">$20</option>
                                                                            <option value="30">$30</option>
                                                                            <option value="40">$40</option>
                                                                            <option value="50">$50</option>
                                                                        </Form.Select>
                                                                    </label>
                                                                    <label for="1">
                                                                        <span><input type='checkbox' id='1' name="1" checked={(profile.scheduleRates && profile.scheduleRates['1']) ? profile.scheduleRates['1'].status : false} onChange={(e) => this.handleScheduleRates(e, 'checkbox')}/> 1 hour</span> 
                                                                        <Form.Select name="1" value={(profile.scheduleRates && profile.scheduleRates['1']) ? profile.scheduleRates['1'].value : ''} onChange={(e) => this.handleScheduleRates(e, 'select')}>
                                                                            <option value="10">$10</option>
                                                                            <option value="15">$15</option>
                                                                            <option value="20">$20</option>
                                                                            <option value="30">$30</option>
                                                                            <option value="40">$40</option>
                                                                            <option value="50">$50</option>
                                                                        </Form.Select>
                                                                    </label>
                                                                </Form> */}
                                                                {/* <Button type='button' variant='outline-primary'>Cancel</Button> */}
                                                                <Button type='submit' variant='primary' onClick={(e) => this.handleProfileSubmit(e, 'scheduleRates')} disabled={submitLoader['scheduleRates']}> {submitLoader['scheduleRates'] ? 'Saving...' : 'Save'}</Button>                                                          
                                                            </Card.Body>
                                                        </Card>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Title className='mb-3'>Get Paid</Card.Title>
                                                               
                                                                { stripeConnected && stripeAccountData.stripeDetail ?
                                                                    <>
                                                                        <Card.Text className='mb-0'>Your  stripe account is connected with <strong>{`${stripeAccountData.stripeDetail[0].email}`}</strong></Card.Text>
                                                                        <Button type='button' variant='primary' onClick={(e)=>this.connectStripeAccount(e)}>Connect Other Stripe Account</Button>
                                                                    </>
                                                                :    
                                                                    <>
                                                                        <Card.Text className='mb-0'>Connect your stripe account to get paid </Card.Text>
                                                                        <Button type='button' variant='primary' onClick={(e)=>this.connectStripeAccount(e)}>Connect To Stripe</Button>  
                                                                    </>
                                                                }                                                    
                                                            </Card.Body>
                                                        </Card>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Title className='mb-3'>transaction History</Card.Title>
                                                                <Link to= {'/transaction-history'}>
                                                                    <Card.Text className='mb-0'> View Your Transaction History </Card.Text>
                                                                </Link>
                                                            </Card.Body>
                                                        </Card>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>}
                        <Col sm={12} lg={3} className='rightFeed--sidebar'>
                            <SidebarFollow />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={show} onHide={() => this.setState({ show:false })} className='redeem--modal' aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Redeeming</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>How do I cash out the money I’ve earned for consulting?</p>
                    <p>Great question!</p>
                    <p>To redeem cash earned from micro-consulting, consultants can choose among several withdrawal options, including PayPal, direct deposit, or wire transfer.</p>
                    <p>The redemption process can be done after each consulting session or accumulated over time. When redeeming points for cash, there is a 2% fee taken out of the balance.</p> 
                    <p>*The withdrawal options vary depending on the consultant's location and the currency used in that location.</p>
                    <Button type='button' variant='primary'>Setup Payments</Button>
                </Modal.Body>
            </Modal>
        </>
    )
    }
}

const maopStateToProps = (state) => {
    console.log("satte" , state)
    return {
        specialities: state.users.specialities,
        profile:  state.users.profile,
        error: state.users.error,
        redirectUrl: state.stripe.redirectUrl,
        stripeDetails: state.stripe.stripeDetails

    }
}

const dispatchActions = { getUserProfile, updateProfile, getAllSpecialities , connectStripeAccount , getStripeDetail}
export default connect(maopStateToProps, dispatchActions)(MyProfile);